import {
  Text,
  View,
  FlatList,
  useWindowDimensions,
  ScrollView,
  RefreshControl,
} from 'react-native';
import React, { useState, useEffect, useRef } from 'react';
import FeedPost from '../../Component/FeedPost';
import { useDispatch, useSelector } from 'react-redux';
import Colors from '../../Resources/Colors';
import {
  generateVideoThumbnail,
  getFileFromBase64,
  theme,
} from '../../Util/constant';
import Emitter from '../../Util/eventEmitter';
import {
  createPost,
  fetchAllPosts,
  likePost,
  uploadImage,
  hideAllPost,
  searchUserAction,
} from '../../Redux/actions/homeAction';
import ProfileDetail from '../../Component/ProfileDetail/index.web';
import {
  fetchOtherProfile,
  getAllUserList,
  getSuggestionUserAction,
  setProfileData,
} from '../../Redux/actions/profileAction';
import SearchWebView from '../../Component/SearchWebView';
import { style } from '../Home/style';
import {
  reset,
  setLoading,
  setToken,
  setUserData,
} from '../../Redux/actions/authAction';
import toast from 'react-simple-toasts';
import { imageUplaod } from '../../Redux/services/profileService';
import Strings from '../../Resources/Strings';
import CameraModal from './Camera/Modal/index.web';
import { useNavigation } from '@react-navigation/native';
import ApplyJobView from '../JobEventWeb/ApplyJobView';
import { RouteName } from '../../Navigation/routeName';
import Type from '../../Constants/Type/type';
import ReportSuccessComponent from '../../Component/ReportSuccess';
import Suggestion from '../Suggestion';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ImagePickerModal from '../../Component/Picker/index.web';
import EndPoint from '../../Redux/constants/EndPoint';
import CustomTagView from '../../Component/CustomTagView';
import { getFirebaseChatOrderByUserList, getFirebaseUserData, setFirebaseChatList, setFirebaseUserData } from '../../Constants/FireStoremanager/FireStoremanager.web';
import { getNewFirebaseChatListUserData } from '../../Constants/FireStoremanager/FireStoremanager.web';
import PostViewComponent from './HomeComponent/PostViewComponent';

var textLength = 0;
const MainHome = ({ onViewImage }) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.home.posts);
  const [viewComments, setViewComments] = useState(false);
  const [comments, setComments] = useState({});
  const [username, setUsername] = useState('');
  const [postImage, setPostImage] = useState([]);
  const [postVideo, setPostVideo] = useState(null);
  const [postDescription, setPostDescription] = useState('');
  const [item, setItem] = useState([]);
  const window = useWindowDimensions();
  const [showProfileModal, setProfileModal] = useState(false);
  const searchedUser = useSelector((state) => state.home.searchedUser);
  const userData = useSelector((state) => state.auth.userData);
  const [open, setOpen] = useState(false);
  const jobs = useSelector((state) => state.jobAndEvent.jobs);
  const events = useSelector((state) => state.jobAndEvent.events);
  const [isapplyJob, setApplyJob] = useState(false);
  const [applyJobData, setApplyJobData] = useState('');
  const [isReportSuccess, setReportSuccess] = useState(false);
  const [isSuggestionView, setSuggestionView] = useState(false);
  const type = userData?.data?.role
    ? userData?.data?.role
    : Strings.PROFESSIONAL;
  const [refreshing, setRefreshing] = useState(false);
  const [page, setPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const [postAllData, setPostAllData] = useState([]);
  const [postCountData, setPostCountData] = useState(0);
  const userInfo = useSelector((state) => state?.profile?.myProfile);
  const [inViewPort, setInViewPort] = useState({ index: 3, isPlay: true });
  const flatlistRef = useRef();
  const [isPicker, setPicker] = useState(false);
  const [imageType, setImageType] = useState('image');
  const [postVideoImage, setPostVideoImage] = useState(null);
  const [newPostImage, setNewPostImage] = useState([]);
  const [tagValue, setTagValue] = useState([]);
  const [istag, setTag] = useState(false);
  const [postText, setPostText] = useState('');
  const allUsers = useSelector((state) => state.profile.allUser);
  const [tagUser, setTagUser] = useState([]);
  const [lastValue, setLastValue] = useState('');
  const [isPlayVideo, setIsPlayVideo] = useState({isPlay: false, index: 0, isVideo: false});
  const [pageNo, setPageNo] = useState(1);
  const [totalPostCount, setTotalPostCount] = useState(0)

  useEffect(() => {
    fetchAllData(1);
    fetchAllUser();
    fetchSuggestionUser();
    Emitter.on(Type.EMITTER.STORE_CREATE, () => {
      fetchAllData(1);
      fetchAllUser();
    });
    return () => {
      Emitter.off(Type.EMITTER.STORE_CREATE);
    };
  }, []);

  // get new connection user suggestion list
  const fetchSuggestionUser = () => {
    dispatch(
      getSuggestionUserAction({
        onSuccess: (result) => {},
        onError: (error) => {
          sessionExpiry(error);
        },
      })
    );
  };

  const fetchAllUser = () => {
    try {
      dispatch(setLoading(true));
      dispatch(
        getAllUserList({
          onSuccess: (result) => {
            dispatch(setLoading(false));
          },
          onError: (error) => {
            sessionExpiry(error);
          },
        })
      );
    } catch (err) {
      dispatch(setLoading(false));
    }
  };

  // seacrh user api call
  const onSearchText = (keyword) => {
    if (keyword?.length < 1 || keyword?.length > 2) {
      dispatch(
        searchUserAction({
          keyword,
          onSuccess: (result) => {},
          onError: (error) => {
            sessionExpiry(error);
          },
        })
      );
    }
  };

  const uploadMediaData = async (data) => {
    var getImageURI = await imageUplaod({
      file: data,
      token: userData?.access_token,
    });
    return getImageURI;
  };

  const fetchAllData = (data) => {
    try {
      var allPost = false;
      var allStories = false;
      dispatch(
        fetchAllPosts({
          data,
          onSuccess: (result) => {
            setTotalPostCount(result?.post_count)
            if (data === 1) {
              setPostAllData([{}, {}, {}, ...result?.data]);
            } else {
              setPostAllData((perviousData) => [
                ...perviousData,
                ...result?.data,
              ]);
            }
            setPostCountData(result?.post_count);
            allPost = true;
            loadingOff()
          },
          onError: (error) => {
            sessionExpiry(error);
          },
        })
      );
      if (allPost && allStories) {
        loadingOff()
      } else {
        loadingOff()
      }
    } catch (error) {
      loadingOff()
    }
  };

  

  const sessionExpiry =  (error) => {
    console.log("error", error)
    loadingOff()
    if (error?.status == 401) {
      Emitter.emit(Type.LOGOUT);
      AsyncStorage.removeItem(Strings.ACCESS_TOKEN_KEY);
      AsyncStorage.removeItem(Strings.LOGIN_DATA);

      dispatch(setToken(''));
      dispatch(setUserData(''));
      dispatch(setProfileData([]));
      dispatch(reset([]));
      navigation.reset({
        index: 0,
        routes: [{ name: RouteName.AUTH }],
      });
    }
  }

  const loadingOff = () =>{
    setPageLoading(false);
        dispatch(setLoading(false));
        setRefreshing(false);
  }

  const viewApplyJob = ({ id, data }) => {
    setApplyJob(true);
    setApplyJobData(data);
  };

  const onChangeText = (text) => {
    if (text.length == 1 && text == '@') {
      setTag(true);
      textLength = text.length;
      setTagValue(allUsers || []);
    } else if (
      text.charAt(text.length - 1) == '@' &&
      text.charAt(text.length - 2) == ' '
    ) {
      textLength = text.length;
      setTag(true);
      const before_ = text.substring(0, text.length - 1);
      setLastValue(before_);
      setTagValue(allUsers || []);
    } else if (istag) {
      var afterChar = text.slice(textLength);
      var tagData = allUsers || [];
      const filteredArr = tagData.filter((val) =>
        val?.name?.toLowerCase().includes(afterChar)
      );
      setTagValue(filteredArr);
    }
    if (text.length == 0) {
      setTag(false);
    }

    if (
      text.charAt(text.length - 1) !== '@' ||
      text.charAt(text.length - 2) !== ' '
    ) {
      var newCommentText = `${postText}${text.charAt(text.length - 1)}`;
      if (!istag) {
        setPostText(newCommentText);
      }
    }
    setPostDescription(text);
  };

  const renderItemFeed = ({ item, index }) => {
    if (index === 0) {
      // Create Post feed
      return renderCreatePostFeed();
    } else if (index === 1) {
    } else if (index === 2) {
      // Render Banner Add
      return <View style={style.webBenerVw}/>
    } else {
      return(
        <PostViewComponent type={type} jobs={jobs}
        index={index}
        viewApplyJob={viewApplyJob}
        events={events}
        navigation={navigation}
        userData={userData}
        onLike={onLike}
        onComment={onComment}
        hidePost={hidePost}
        onSubmitComment={onSubmitComment}
        setUsername={setUsername}
        setReportSuccess={setReportSuccess}
        fetchAllData={fetchAllData}
        userInfo={userInfo}
        setItem={setItem}
        fetchOtherUserProfile={fetchOtherUserProfile}
        setProfileModal={setProfileModal}
        window={window}
        inViewPort={inViewPort}
        onViewImage={onViewImage}
        isPlayVideo={isPlayVideo}
        setIsPlayVideo={setIsPlayVideo}
        postAllData={postAllData}
        totalPostCount={totalPostCount}
        pageNo={pageNo}
        setPageNo={setPageNo}
        item={item} />
      )
    }
  };

  const hidePost = (type, userId, postId) => {
    let params = {
      post_id: postId,
      post_user_id: userId,
      type: type,
    };
    dispatch(
      hideAllPost({
        params,
        onSuccess: (result) => {
          fetchAllData(1);
        },
        onError: (error) => {
          sessionExpiry(error);
        },
      })
    );
  };

  const onLike = (post_id) => {
    dispatch(
      likePost({
        post_id,
        onSuccess: (result) => {
          fetchAllData(1);
        },
        onError: (error) => {
          sessionExpiry(error);
        },
      })
    );
  };
  const onComment = (comment, postId) => {
    setComments({ comment: comment, postId: postId });
    setViewComments(true);
  };
  const validation = () => {
    createPostService(imageType === 'video' ? postVideo : newPostImage);
  };

  const createPostService = (imagePath) => {
    try {
      if (postDescription === '') {
        toast(Strings.INVALID_POST_MESSAGE);
      } else {
        dispatch(setLoading(true));
        var params = {};
        if (imagePath != '') {
          params = {
            image: imagePath,
            description: postDescription,
            tagged_users: tagUser,
          };
        } else {
          params = {
            description: postDescription,
            tagged_users: tagUser,
          };
        }
        dispatch(
          createPost({
            params,
            onSuccess: (result) => {
              dispatch(setLoading(false));
              fetchAllData(1);
              setPostImage([]);
              setNewPostImage([]);
              setPostVideo('');
              setPostDescription('');
              setPostVideoImage('');
              setTagUser([]);
            },
            onError: (error) => {
              sessionExpiry(error);
            },
          })
        );
      }
    } catch (err) {
      dispatch(setLoading(false));
      toast(err?.message);
    }


  };

  const onSubmitComment = ({ postId, textDescription, tagUser }) => {
  };

  const onUploadVideo = async (videoData) => {
    const thumbnal = await generateVideoThumbnail(videoData);
    var data = 'data:image/png;base64,';
    var removeData = thumbnal.replace(data, '');
    const fileName = 'post_' + new Date().getTime() + '.png';
    const dataFile = getFileFromBase64(removeData, fileName);
    var getVideoImageURI = await uploadMediaData(dataFile);
    setPostVideoImage(getVideoImageURI.data[0]);
    var uploadVideoData = await uploadMediaData(videoData);
    let files = {
      uri: uploadVideoData.data[0],
      type: 'video',
      thumbnil: getVideoImageURI.data[0],
    };
    setPostImage([]);
    setNewPostImage([]);
    setPostVideo([files]);
  };

  // upload image data create post
  const uploadCreateImageData = (imagedata) => {
    return new Promise((resolve, reject) => {
      let data = {
        imagePath: imagedata,
        mediaType: imageType,
        isCreate: true,
      };

      dispatch(
        uploadImage({
          data,
          onSuccess: (result) => {
            if (result.data) {
              const initialArr = [];
              result.data.forEach(myFunction);
              function myFunction(item) {
                let files = {
                  uri: item,
                  type: imageType,
                  thumbnil: postVideoImage,
                };
                initialArr.push(files);
              }
              resolve(initialArr);
            }
          },
          onError: (error) => {
            reject(error);
            sessionExpiry(error);
          },
        })
      );
    });
  };

  const removeImage = (data) => {
    var updateData = newPostImage;
    updateData.splice(data, 1);
    setNewPostImage([...updateData]);
  };

  const addTagData = ({ item }) => {
    // Keyboard.dismiss();
    setPostDescription('');
    let newText =
      postDescription == '@' ? `@${item.name} ` : `${lastValue} @${item.name} `;
    let newCommenttagText = `${postText} [${item.name}](${item._id}) `;
    setPostDescription(newText);
    setPostText(newCommenttagText);
    setTag(false);

    var oldTagValue = tagUser;
    var checkIndex = oldTagValue.findIndex((element) => element == item._id);
    if (checkIndex == -1) {
      oldTagValue.push({ user_id: item._id, name: item.name });
      setTagUser(oldTagValue);
    }
  };

  const renderCreatePostFeed = () => {
    return (
      <View
        style={{
          backgroundColor: Colors.White,
          borderRadius: 22,
          marginBottom: 16,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: 12,
            paddingHorizontal: 12,
          }}
        >
          <Text
            style={{ fontSize: 16, color: Colors.Black, ...theme.fontMedium }}
          >
            Create Post
          </Text>
        </View>
        <View style={{ paddingVertical: 12, paddingHorizontal: 12 }}>
          <FeedPost
            imagePickPress={() => {
              setImageType('image');
              setPicker(true);
            }}
            removeImage={removeImage}
            commentTextInputValue={postDescription}
            onCreatePost={()=>validation()}
            onTextChange={(text) => onChangeText(text)}
            selectedImage={newPostImage}
            onPressGallery={(data) => {
              setImageType('video');
              onUploadVideo(data[0]);  
            }}
            postVideoImage={EndPoint.baseAssestURL + postVideoImage}
            mediaType={imageType}
            window={window}
            tagValue={tagValue}
            istag={istag}
          />
       
        </View>
        
      </View>
    );
  };

  const fetchOtherUserProfile = (item) => {
    const userId = item._id;
    dispatch(
      fetchOtherProfile({
        userId,
        onSuccess: (result) => {},
        onError: (error) => {
          sessionExpiry(error);
        },
      })
    );
  };

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    setPage(1);
    fetchAllData(page);
  }, [refreshing]);

  const onViewableItemsChanged = useRef((data) => {
    try {
      const viewableItems = data?.viewableItems || [];
      if (viewableItems && viewableItems.length > 0) {
        if (viewableItems[0].index)
          setTimeout(() => {
            setInViewPort({
              ...inViewPort,
              index: viewableItems[0].index,
              isPlay:
                viewableItems[0]?.item.media &&
                viewableItems[0]?.item.media.length > 0
                  ? viewableItems[0]?.item.media[0].type == 'video'
                    ? true
                    : false
                  : false,
            });
          }, 500);
      }
      //do anything
    } catch (error) {
      console.log(error);
    }
  }).current;


  const createChat = async (otherUserProfile) => {
    const otherUserId = otherUserProfile?._id;
    const userExist = await getFirebaseChatOrderByUserList({
      id: userData?.data?.user_id || userData?.data?._id,
      userId: otherUserId,
    });
    const newID = userData?.data?.user_id || userData?.data?._id;
    var chatId =
      newID > otherUserId ? `${newID}${otherUserId}` : `${otherUserId}${newID}`;
      
    var getUserChatLists = await getNewFirebaseChatListUserData({
      id: userData?.data?.user_id || userData?.data?._id,
      chatId: chatId,
    });
    var getOtherUserChatLists = await getNewFirebaseChatListUserData({
      id: otherUserId,
      chatId: chatId,
    });
    if (userExist.length == 0) {
      const usergetExist = await getFirebaseUserData({
        id: otherUserId,
      });
      if (!usergetExist) {
        const userStoreData = {
          id: otherUserId,
          email: otherUserProfile?.email || '',
          device_token: otherUserProfile?.device_token || '',
          image:
            otherUserProfile?.image || otherUserProfile?.userinfo?.image || '',
          name: otherUserProfile?.name || '',
          role: otherUserProfile?.role || '',
          phone_number: otherUserProfile?.phone_number || '',
          status: 'offline',
          dateTime: new Date(),
        };
        const storeFirbaseData = await setFirebaseUserData({
          id: otherUserId,
          userData: userStoreData,
        });
      }
      var otherUserData = {
        id: otherUserId,
        name: otherUserProfile?.name || '',
        role: otherUserProfile?.role || '',
        image:
          otherUserProfile?.image || otherUserProfile?.userinfo?.image || '',
        status: '',
        chatId: chatId,
        message: '',
        maessageType: 'text',
        dateTime: new Date(),
      };
      var cureentUserData = {
        id: userData?.data?.user_id || userData?.data?._id,
        name: userData?.data?.name || '',
        role: userData?.data?.role || '',
        image: userData?.data?.image || '',
        status: '',
        chatId: chatId,
        message: '',
        maessageType: 'text',
        dateTime: new Date(),
      };
      if (!getUserChatLists) {
        
        await setFirebaseChatList({
          id: userData?.data?.user_id || userData?.data?._id,
          userData: otherUserData,
          chatUserId: otherUserId,
        });
      }
      if (!getOtherUserChatLists) {
        await setFirebaseChatList({
          id: otherUserId,
          userData: cureentUserData,
        });
      }
      navigationChat({chatId: chatId,receiverId: otherUserId, isOther: true, otherUserProfile});
    } else {
      navigationChat({chatId: chatId,receiverId: otherUserId, isOther: true, otherUserProfile});
  }
  };

  const navigationChat = ({chatId,receiverId, isOther, otherUserProfile }) =>{
    global.isChat = true;
      navigation.navigate(RouteName.CHAT_USER,{
        chatId: chatId,
          receiverId: receiverId,
          isOther: isOther,
          receiverData: {
            id: receiverId,
            name: otherUserProfile?.name || '',
            role: otherUserProfile?.role || '',
            image:
              otherUserProfile?.image ||
              otherUserProfile?.userinfo?.image ||
              '',
            status: '',
            chatId: chatId,
            message: '',
            maessageType: 'text',
            dateTime: new Date(),
          },
    })
  }
  

  return (
    <>
      {isSuggestionView ? (
        <>
          <Suggestion
            backPress={() => {
              setSuggestionView(false);
            }}
            fetchOtherUserProfile={fetchOtherUserProfile}
            setItem={setItem}
            setProfileModal={setProfileModal}
            onViewImage={onViewImage}
          />
          <ProfileDetail
            item={item}
            showProfileModal={showProfileModal}
            suggestionPress={() => {
              fetchSuggestionUser();
            }}
            setProfileModal={setProfileModal}
            onViewImage={onViewImage}
            chatPress={(chatData)=>{
              setProfileModal(false)
              createChat(chatData);}}
          />
        </>
      ) : (
        <View>
          {isapplyJob ? (
            <ApplyJobView
              type={type}
              setApplyJob={setApplyJob}
              applyJobData={applyJobData}
            />
          ) : (
            <View>
              <SearchWebView
                window={window}
                onSearchText={onSearchText}
                searchedUser={searchedUser}
                fetchOtherUserProfile={fetchOtherUserProfile}
                setItem={setItem}
                setProfileModal={setProfileModal}
                suggestionPress={() => {
                  setSuggestionView(true);
                }}
              />

              {isReportSuccess && (
                <ReportSuccessComponent
                  userName={username}
                  setShow={(value) => {
                    setReportSuccess(value);
                  }}
                />
              )}

              {istag && (
        <View
          style={{
            width: window.width * 0.46,
            height: window.height * 0.6,
            top: window.height * 0.6,
            left: window.width * 0.008,
            backgroundColor: Colors.White,
            flex: 1,
            position: 'absolute',
            zIndex: 1,
            borderRadius:(window.width * 0.005)+(window.height * 0.005),
            borderWidth : (window.width * 0.0001)+(window.height * 0.0001),
            padding: (window.width * 0.003)+(window.height * 0.003),
            borderColor: Colors.lightGray
          }}
        >
          <ScrollView>
            {tagValue.map((item, index) => {
              if (item != null) {
                return (
                  <CustomTagView
                    window={window}
                    item={item}
                    index={index}
                    addTagData={() => addTagData({ item })}
                  />
                );
              }
            })}
          </ScrollView>
        </View>
      )}

              <FlatList
                ref={flatlistRef}
                refreshControl={
                  <RefreshControl
                    refreshing={refreshing}
                    tintColor={Colors.Blueberry}
                    onRefresh={onRefresh}
                  />
                }
                showsVerticalScrollIndicator={false}
                data={postAllData}
                renderItem={renderItemFeed}
                style={{ marginTop: window.height * 0.05 }}
                onEndReachedThreshold={0.1}
                onViewableItemsChanged={onViewableItemsChanged}
              />
            </View>
          )}

          <ProfileDetail
            item={item}
            showProfileModal={showProfileModal}
            setProfileModal={setProfileModal}
            suggestionPress={() => {
              fetchSuggestionUser();
            }}
            onViewImage={onViewImage}
            chatPress={(chatData)=>{
              setProfileModal(false)
              createChat(chatData);}}
          />
          {open && (
            <CameraModal
              imageType={imageType}
              showCameraModal={open}
              setCameraModal={(value) => {
                setOpen(value);
              }}
              setCameraData={async (value) => {
                setOpen(false);
                var uploadCameraData = await uploadMediaData(value);

                let files = {
                  uri: uploadCameraData.data[0],
                  type: imageType,
                  thumbnil: '',
                };
                setNewPostImage([...newPostImage, files]);
                if (postImage == !null) {
                  setPostImage([...postImage, files]);
                } else {
                  setPostImage([files]);
                }
              }}
            />
          )}

          {isPicker && (
            <ImagePickerModal
              isPost={true}
              imageType={imageType}
              showPickerModal={isPicker}
              setPickerModal={() => {
                setPicker(false);
              }}
              setSelection={async (value) => {
                if (value === Type.IMAGE_TYPE.CAMERA) {
                  setOpen(true);
                } else {
                  var uploadGalaryImageData = await uploadCreateImageData(
                    value
                  );
                  setNewPostImage([...newPostImage, ...uploadGalaryImageData]);
                }
                setPicker(false);
              }}
              token={userData?.access_token}
            />
          )}
        </View>
      )}
    </>
  );
};

export default MainHome;
