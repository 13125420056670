import { Dimensions, Platform, StyleSheet } from 'react-native';
import React from 'react';
import { mxHeight, mxWidth } from '../../../Util';
import Colors from '../../../Resources/Colors';
import { fontResize } from '../../../Util/font';
import { theme } from '../../../Util/constant';
const webWidth = Dimensions.get('window').width;
const webHeight = Dimensions.get('window').height;

const style = StyleSheet.create({
  container: {
    backgroundColor: Colors.White,
    width: mxWidth * 0.9,
    paddingHorizontal: mxWidth * 0.05,
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    bottom: 10,
  },
  mainContainer: {
    backgroundColor: Colors.White,
    width: mxWidth * 0.9,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  AddButton: {
    backgroundColor: Colors.White,
    width: mxWidth * 0.4,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 12,
    borderRadius: 5,
  },
  addText: {
    ...theme.fontBold,
    fontSize: fontResize(15),
    color: Colors.lightGray,
    marginLeft: 5,
  },
  addImage: {
    height: 18,
    width: 18,
  },
  updateButton: {
    width: mxWidth * 0.9,
    // marginTop: 10,
    backgroundColor: Colors.Blueberry,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 12,
    borderRadius: 5,
  },
  updateText: {
    ...theme.fontBold,
    fontSize: fontResize(15),
    color: Colors.White,
  },
});

export default style;
