import { RESET } from '../actions/authAction';
import {
  LOADING,
  GET_CREATE_STORY,
  GET_STORY_BY_ID,
  CREATE_POST,
  LIKE_POST,
  GET_ALL_POSTS,
  COMMENT_POST,
  HIDE_ALL_POST,
  HIDE_SINGLE_POST,
  SEARCH_USER,
  SHARE_POST,
  GET_POST_DETAILS,
  BLOCK_USER,
  CREATE_POST_LOADING,
  SUBSCRIPTION_MODAL,
  UPDATE_POST,
  GET_LIKE_POST,
  POST_COMMENT_LIKE,
  COMMENT_LIKE,
  PAGE_COUNT,
  NOTIFICATION_COUNT,
  UNREAD_CHAT_LIST,
  UNREAD_CHAT_COUNT,
  COMMENT_LIST
} from '../actions/homeAction';

const initialState = {
  isLoading: false,
  stories: [{}],
  storyData: undefined,
  posts: [{}, {}, {}],
  searchedUser: [],
  shareData: {},
  postDetails: {},
  postLoading: false,
  isSubscriptionModal: false,
  likePost: [],
  commentLikePost: {},
  pageCount: 0,
  commentLikes: [],
  notificationCount: 0,
  allChatList: [],
  messageCount: 0,
  commentList: [],
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING.SUCCESS:
      return {
        ...state,
        isLoading: action.payload,
      };
    case GET_CREATE_STORY.SUCCESS:
      return {
        ...state,
        stories: [{}, ...action.payload.data],
      };
    case GET_STORY_BY_ID.SUCCESS:
      return {
        ...state,
        storyData: action.payload.data,
      };
    case GET_ALL_POSTS.SUCCESS:
      return {
        ...state,
        posts: action.payload.data,
        pageCount: action.payload.post_count,
      };
    case SEARCH_USER.SUCCESS:
      return {
        ...state,
        searchedUser: action.payload.data,
      };
    case SHARE_POST.SUCCESS:
      return {
        ...state,
        shareData: action.payload.data,
      };
    case GET_POST_DETAILS.SUCCESS:
      return {
        ...state,
        postDetails: action.payload.data[0],
      };
    case CREATE_POST_LOADING.SUCCESS:
      return {
        ...state,
        postLoading: action.payload,
      };
    case SUBSCRIPTION_MODAL.SUCCESS:
      return {
        ...state,
        isSubscriptionModal: action.payload,
      };
    case UPDATE_POST.SUCCESS:
      return {
        ...state,
        postDetails: action.payload.data,
      };
    case GET_LIKE_POST.SUCCESS:
      return {
        ...state,
        likePost: action.payload.data,
      };
    case POST_COMMENT_LIKE.SUCCESS:
      return {
        ...state,
        commentLikePost: action.payload.data,
      };
    case COMMENT_LIKE.SUCCESS:
      return {
        ...state,
        commentLikes: action.payload.data,
      };
    case PAGE_COUNT.SUCCESS:
      return {
        ...state,
        pageCount: action.payload.post_count,
      };
      case COMMENT_LIST.SUCCESS:
      return {
        ...state,
        commentList: action.payload,
      };
    case NOTIFICATION_COUNT.SUCCESS:
      return {
        ...state,
        notificationCount: action.payload,
      };
    case UNREAD_CHAT_LIST.SUCCESS:
      return {
        ...state,
        allChatList: action.payload,
      };
    case UNREAD_CHAT_COUNT.SUCCESS:
      return {
        ...state,
        messageCount: action.payload,
      };
    case RESET.SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default homeReducer;
