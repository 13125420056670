import React, { useEffect, useState } from 'react';
import {
  View,
  StyleSheet,
  FlatList,
  ActivityIndicator,
  useWindowDimensions,
  Modal,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import CustomHeader from '../../Component/CustomHeader';
import { setLoading } from '../../Redux/actions/authAction';
import Strings from '../../Resources/Strings';
import { CustomText } from '../../Component/CustomComponent';
import Colors from '../../Resources/Colors';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchFollowList,
  fetchOtherProfile,
} from '../../Redux/actions/profileAction';
import {
  getFirebaseChatOrderByUserList,
  getFirebaseUserData,
  getNewFirebaseChatListUserData,
  setFirebaseChatList,
  setFirebaseUserData,
} from '../../Constants/FireStoremanager/FireStoremanager.web';
import { RouteName } from '../../Navigation/routeName';
import CustomConnectComponent from './CustomConnectComponent.web';
import ProfileDetail from '../../Component/ProfileDetail/index.web';
import CustomImageVideoDownload from '../../Component/CustomImageVideoDownload';

// create a component
const Connection = ({ navigation }) => {
  const window = useWindowDimensions();
  const followerList = useSelector((state) => state.profile.followList);
  const [page, setPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const [followerData, setFollowerData] = useState([]);
  const [newPageLoading, setNewPageLoading] = useState(true);
  const [profileItem, setProfileItem] = useState([]);
  const [showProfileModal, setProfileModal] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const [profileImageIndex, setProfileImageIndex] = useState(0);
  const checkTypeOf = typeof followerList;

  const userData = useSelector((state) => state.auth.userData);
  const [activeTab, setActiveTab] = useState('');
  const { width, height } = window;
  const combinSize =
    width > height
      ? width * 0.05 + height * 0.1
      : width == height
      ? width + height
      : height * 0.05 + width * 0.1;
  const type = userData?.data?.role
    ? userData?.data?.role
    : Strings.PROFESSIONAL;
  const dispatch = useDispatch();
  useEffect(() => {
    fetchmyFollwers(1);
  }, []);

  const fetchOtherUserProfile = ({userId, isChat}) => {
    dispatch(
      fetchOtherProfile({
        userId,
        onSuccess: (result) => {
          if(isChat){
          createChat(result.data);
          }
          else {
            setProfileModal(true);
          }
        },
        onError: (error) => {},
      })
    );
  };

  const fetchmyFollwers = (data) => {
    let params = `?follow=by_me&page=${data}`;
    dispatch(
      fetchFollowList({
        params,
        onSuccess: async (result) => {
          if(data == 1){
            setFollowerData((perviousData) => [...result.data]);
          }
          else{
            setFollowerData((perviousData) => [...perviousData, ...result.data]);
          }
          
          setPageLoading(false);
          setNewPageLoading(result?.data?.length == 10 ? true : false);
          dispatch(setLoading(false));
        },
        onError: (error) => {
          console.log('error', error);
        },
      })
    );
  };

  const createChat = async (otherUserProfile) => {
    const otherUserId = otherUserProfile?._id;
    const userExist = await getFirebaseChatOrderByUserList({
      id: userData?.data?.user_id || userData?.data?._id,
      userId: otherUserId,
    });
    const newID = userData?.data?.user_id || userData?.data?._id;
    var chatId =
      newID > otherUserId ? `${newID}${otherUserId}` : `${otherUserId}${newID}`;
    var getUserChatLists = await getNewFirebaseChatListUserData({
      id: userData?.data?.user_id || userData?.data?._id,
      chatId: chatId,
    });
    var getOtherUserChatLists = await getNewFirebaseChatListUserData({
      id: otherUserId,
      chatId: chatId,
    });
    if (userExist.length == 0) {
      const usergetExist = await getFirebaseUserData({
        id: otherUserId,
      });
      if (!usergetExist) {
        const userStoreData = {
          id: otherUserId,
          email: otherUserProfile?.email || '',
          device_token: otherUserProfile?.device_token || '',
          image:
            otherUserProfile?.image || otherUserProfile?.userinfo?.image || '',
          name: otherUserProfile?.name || '',
          role: otherUserProfile?.role || '',
          phone_number: otherUserProfile?.phone_number || '',
          status: 'offline',
          dateTime: new Date(),
        };
        const storeFirbaseData = await setFirebaseUserData({
          id: otherUserId,
          userData: userStoreData,
        });
      }
      var otherUserData = {
        id: otherUserId,
        name: otherUserProfile?.name || '',
        role: otherUserProfile?.role || '',
        image:
          otherUserProfile?.image || otherUserProfile?.userinfo?.image || '',
        status: '',
        chatId: chatId,
        message: '',
        maessageType: 'text',
        dateTime: new Date(),
      };
      var cureentUserData = {
        id: userData?.data?.user_id || userData?.data?._id,
        name: userData?.data?.name || '',
        role: userData?.data?.role || '',
        image: userData?.data?.image || '',
        status: '',
        chatId: chatId,
        message: '',
        maessageType: 'text',
        dateTime: new Date(),
      };
      if (!getUserChatLists) {
        await setFirebaseChatList({
          id: userData?.data?.user_id || userData?.data?._id,
          userData: otherUserData,
          chatUserId: otherUserId,
        });
      }
      if (!getOtherUserChatLists) {
        await setFirebaseChatList({
          id: otherUserId,
          userData: cureentUserData,
        });
      }
      global.isChat = true;
      navigation.navigate(RouteName.CHAT_USER,{
        chatId: chatId,
            receiverId: otherUserId,
            isOther: true,
            receiverData: {
              id: otherUserId,
              name: otherUserProfile?.name || '',
              role: otherUserProfile?.role || '',
              image:
                otherUserProfile?.image ||
                otherUserProfile?.userinfo?.image ||
                '',
              status: '',
              chatId: chatId,
              message: '',
              maessageType: 'text',
              dateTime: new Date(),
      }});
    } else {
      global.isChat = true;
      navigation.navigate(RouteName.CHAT_USER,{
        chatId: chatId,
          receiverId: otherUserId,
          isOther: true,
          receiverData: {
            id: otherUserId,
            name: otherUserProfile?.name || '',
            role: otherUserProfile?.role || '',
            image:
              otherUserProfile?.image ||
              otherUserProfile?.userinfo?.image ||
              '',
            status: '',
            chatId: chatId,
            message: '',
            maessageType: 'text',
            dateTime: new Date(),
          },
    })
  }
  };

  const renderItem = ({ item, index }) => {
    var isLastIndex =
    followerData.length == index + 1
        ? followerData.length % 10 == 0
          ? true
          : false
        : false;
    return (
     <CustomConnectComponent item={item} index={index} window={window} fetchOtherUserProfile={(id)=>fetchOtherUserProfile({userId: id, isChat: true})} otherPress={()=>{
      fetchOtherUserProfile({userId: item._id, isChat: false})
      setProfileItem(item);
     }} onViewImage={onViewImage} isLastIndex={isLastIndex} viewMoreFollowers={viewMoreFollowers}/>
    );
  };

  const viewMoreFollowers = () => {
    setPage(page + 1);
        fetchmyFollwers(page + 1);
  };

  const onViewImage=(data)=>{
    
    setIsProfile(true);
    setProfileImage(data?.data);
    setProfileImageIndex(data?.index);
  }

  const onPageRefresh = React.useCallback(() => {
    if (!pageLoading) {
      if (newPageLoading) {
        setPage(page + 1);
        setPageLoading(true);
        fetchmyFollwers(page + 1);
      }
    }
  }, [pageLoading]);

  return (
    <SafeAreaView style={styles.container}>
      <CustomHeader
        window={window}
        activeTab={activeTab}
        isProfile={true}
        backPress={() => navigation.goBack()}
        title={Strings.CONNECTIONS}
      />

      <CustomText
        style={{
          color: 'gray',
          borderTopWidth: 1,
          borderColor: Colors.gray2,
          paddingTop: window.width * 0.01,
        }}
      />

        <FlatList
          contentContainerStyle={{
            justifyContent: 'space-between',
            width: window.width,
          }}
          bounces={false}
          data={followerData}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          numColumns={3}

        />
        
        <ProfileDetail
            item={profileItem}
            showProfileModal={showProfileModal}
            setProfileModal={setProfileModal}
            suggestionPress={() => {
              // fetchSuggestionUser();
            }}
            chatPress={(chatData)=>{
              setProfileModal(false);
              createChat(chatData);}}
            onViewImage={onViewImage}
          />
      {pageLoading && (
        <Modal
          animated={true}
          animationType={'fade'}
          transparent={true}
          visible={pageLoading}
        >
          <View
            style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
          >
            <ActivityIndicator size="large" color={Colors.Blueberry} />
          </View>
        </Modal>
      )}

      {isProfile && (
        <CustomImageVideoDownload
          isVisible={isProfile}
          setVisible={setIsProfile}
          selectedFile={profileImage}
          index={profileImageIndex}
        />
      )}
    </SafeAreaView>
  );
};

// define your styles
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.White,
  },
});

//make this component available to the app
export default Connection;
