import { ScrollView } from 'react-native';
import React from 'react';
import MainHome from './MainHome';

const HomeView = ({ onViewImage }) => {
  return (
    <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
      <MainHome onViewImage={onViewImage} />
    </ScrollView>
  );
};

export default HomeView;
