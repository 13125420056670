/* eslint-disable react-native/no-inline-styles */
import { View, Text, ScrollView, Dimensions } from 'react-native';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import JobPostItem from '../../Component/JobPostItem';
import { onJobSearch } from '../../Redux/actions/jobAndEventAction';
import Strings from '../../Resources/Strings';
import { theme } from '../../Util/constant';
import { fontResize } from '../../Util/font';

const JobWeb = ({ jobs, searchedJobs, onjobPress, jobDeleted, userData, onViewImage, window, viewMoreJob, isView }) => {

  const {width, height} = window ? window : Dimensions.get("window");
  const combinSize =
    width > height
      ? width * 0.05 + height * 0.1
      : width == height
      ? width + height
      : height * 0.05 + width * 0.1;
  const type = userData?.data?.role
    ? userData?.data?.role
    : Strings.PROFESSIONAL;
  const jobsData =
    searchedJobs?.length > 0
      ? searchedJobs
      : jobs?.Featured_Jobs
      ? jobs?.Featured_Jobs
      : [];
  const RecommendedData =
    searchedJobs?.length > 0
      ? searchedJobs
      : jobs?.Recommended_Jobs
      ? jobs?.Recommended_Jobs
      : [];

  const renderItem = ({ item, index }) => {
    var isLastIndex =jobsData.length == index + 1
        ? jobsData.length % 10 == 0
          ? true
          : false
        : false;

    return (
      <JobPostItem
        type={type}
        item={item}
        jobDeleted={(value) => {
          jobDeleted(true);
        }}
        toDetails={(id) => {
          if (type == Strings.PROFESSIONAL) {
            onjobPress({ id: id, data: item });
          }
        }}
        onViewImage={onViewImage}
        isLastIndex={isLastIndex}
        combinSize={combinSize}
        window={window}
        viewMoreJob={viewMoreJob}
        isView={isView}
      />
    );
  };
  return (
    <View style={{ marginBottom: height * 0.05 }}>
      {searchedJobs && searchedJobs?.length == 0 && (
        <Text
          style={{
            ...theme.fontMedium,
            marginLeft: '3%',
            marginTop: '5%',
            color: '#777E90',
            fontSize: fontResize(height * 0.021),
          }}
        >
          {jobsData.length == 0 ? '' : Strings.FEATURED_JOB}
        </Text>
      )}
      
        <ScrollView
          bounces={false}
          style={{ paddingHorizontal: 16 }}
          horizontal={type == Strings.PROFESSIONAL}
        >
          {jobsData.map((item, index) => renderItem({ item, index }))}
        </ScrollView>
     
      {type == Strings.PROFESSIONAL && (
        <>
          {searchedJobs && searchedJobs?.length == 0 && (
            <>
              <Text
                style={{
                  ...theme.fontMedium,
                  marginLeft: '3%',
                  marginTop: '10%',
                  color: '#777E90',
                  fontSize: fontResize(height * 0.021),
                }}
              >
                {RecommendedData.length == 0 ? '' : Strings.RECOMMENDED}
              </Text>
              <ScrollView
                bounces={false}
                style={{ paddingHorizontal: 16 }}
                horizontal
              >
                {RecommendedData.map((item, index) => renderItem({ item, index }))}
              </ScrollView>
            </>
          )}
        </>
      )}
    </View>
  );
};

export default JobWeb;
