import { View, ScrollView } from 'react-native'
import React from 'react'
import CustomButton from '../../Component/CustomButton';
import Colors from '../../Resources/Colors';
import Strings from '../../Resources/Strings';
import JobWeb from '../JobWeb/index.web';
import { RouteName } from '../../Navigation/routeName';


const HomeJobView = ({window, navigation, onViewImage, userData, jobDeleted, searchedJobs, jobs}) => {
  return (
    <ScrollView
                  bounces={false}
                  showsVerticalScrollIndicator={false}
                >
                  <View>
                    <CustomButton
                      width={'50%'}
                      marginLeft={'50%'}
                      height={window.height * 0.06}
                      backgroundColor={Colors.Blueberry}
                      marginTop={window.height * 0.03}
                      borderRadius={'10px'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      fontFamily={'Gilroy-SemiBold'}
                      fontSize={'18px'}
                      textColor={Colors.White}
                      onPress={() => {
                        navigation.navigate(RouteName.CREATE_JOB_POST, {
                          isEdited: false,
                        });
                      }}
                      text={Strings.CREATE_JOB_POST}
                    />
                  </View>
                  <JobWeb
                    jobs={jobs}
                    searchedJobs={searchedJobs}
                    jobDeleted={() => {
                        jobDeleted();
                    }}
                    userData={userData}
                    onViewImage={onViewImage}
                    window={window}
                    isView={false}
                    viewMoreFollowers={()=>{}}
                  />
                </ScrollView>
  )
}

export default HomeJobView