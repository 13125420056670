import { View, useWindowDimensions } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Emitter from '../../Util/eventEmitter';
import Strings from '../../Resources/Strings';
import AllHomeHeader from '../../Component/AllHomeTabHeader';
import { fetchEvents, fetchJobs, setEventsData, setJobData } from '../../Redux/actions/jobAndEventAction';
import { setLoading } from '../../Redux/actions/authAction';
import { fetchPromotionPost } from '../../Redux/actions/promotionAction';
import CustomImageVideoDownload from '../../Component/CustomImageVideoDownload';
import CalenderChat from './CalenderChat';
import HomeProfileView from './HomeProfileView';
import HomeView from './HomeView';
import HomeEventView from './HomeEventView';
import HomeJobView from './HomeJobView';
import HomePromotionView from './HomePromotionView';

const HomeWeb = ({ route, navigation }) => {
  const dispatch = useDispatch();
  const window = useWindowDimensions();
  const userData = useSelector((state) => state.auth.userData);
  const type = userData?.data?.role
    ? userData?.data?.role
    : Strings.PROFESSIONAL;
  const jobs = useSelector((state) => state.jobAndEvent.jobs);
  const events = useSelector((state) => state.jobAndEvent.events);
  const promotion = useSelector((state) => state.promotion.promotions);
  const searchedJobs = useSelector((state) => state.jobAndEvent.searchedJobs);
  const searchedEvents = useSelector(
    (state) => state.jobAndEvent.searchedEvents
  );
  const [active, setActivity] = useState(Strings.ALL);
  const [isProfile, setIsProfile] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const [profileImageIndex, setProfileImageIndex] = useState(0);

  useEffect(() => {
    fetchAllJobs();
    Emitter.on('JobCreated', () => {
      fetchAllJobs();
    });
    Emitter.on('EventCreated', () => {
      fetchAllEvents();
    });
    Emitter.on('PostCreated', () => {
      fetchAllPromotions();
    });
    return () => {
      Emitter.off('JobCreated');
      Emitter.off('EventCreated');
      Emitter.off('PostCreated');
    };
  }, []);

  const fetchAllJobs = () => {
    const data = 1
    dispatch(setLoading(true));
    dispatch(
      fetchJobs({
        data,
        onSuccess: (result) => {
          dispatch(setJobData(result))
          dispatch(setLoading(false));
          fetchAllEvents();
        },
        onError: (error) => {
          dispatch(setLoading(false));
        },
      })
    );
  };

  const fetchAllEvents = () => {
    dispatch(setLoading(true));
    var data = 1;
    dispatch(
      fetchEvents({
        data,
        onSuccess: (result) => {
          dispatch(setEventsData(result))
          dispatch(setLoading(false));
          if (type === Strings.ORGANIZATION) {
            fetchAllPromotions();
          }
        },
        onError: (error) => {
          dispatch(setLoading(false));
        },
      })
    );
  };

  const fetchAllPromotions = () => {
    try {
      dispatch(setLoading(true));
      dispatch(
        fetchPromotionPost({
          onSuccess: (result) => {
            dispatch(setLoading(false));
          },
          onError: (error) => {
            dispatch(setLoading(false));
          },
        })
      );
    } catch (err) {
      console.log('Error', err);
    }
  };

  const onViewImage = (data) => {
    setIsProfile(true);
    setProfileImage(data?.data);
    setProfileImageIndex(data?.index);
  };

  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          flex: 1,
        }}
      >
        {/* // Render Profile */}
        <HomeProfileView onViewImage={onViewImage} />

        {/* Render Feeds */}
        <View
          style={{
            width: '48%',
            flex: 1,
            borderRadius: 22,
            marginTop: 18,
            marginLeft: 18,
            marginRight: 18,
          }}
        >
          {type === Strings.PROFESSIONAL ? (
            <HomeView onViewImage={onViewImage} />
          ) : (
            <>
              <AllHomeHeader
                window={window}
                activeTab={active}
                onTabPress={setActivity}
                type={type}
              />
              {active == Strings.ALL ? (
                <HomeView onViewImage={onViewImage} />
              ) : active == Strings.JOB_POST_WEB ? (
                <HomeJobView
                  window={window}
                  navigation={navigation}
                  jobs={jobs}
                  jobDeleted={() => {
                    fetchAllJobs();
                  }}
                  searchedJobs={searchedJobs}
                  onViewImage={onViewImage}
                  userData={userData}
                />
              ) : active == Strings.PROMOTIONS ? (
                <HomePromotionView
                  window={window}
                  navigation={navigation}
                  onViewImage={onViewImage}
                  promotion={promotion}
                  promotionDeleted={() => fetchAllPromotions()}
                />
              ) : (
                <HomeEventView
                  onViewImage={onViewImage}
                  navigation={navigation}
                  window={window}
                  events={events}
                  searchedEvents={searchedEvents}
                  eventDeleted={() => {
                    fetchAllEvents();
                  }}
                />
              )}
            </>
          )}
        </View>
        {/* render Chat and Calender */}
        <CalenderChat />
      </View>
      {isProfile && (
        <CustomImageVideoDownload
          isVisible={isProfile}
          setVisible={setIsProfile}
          selectedFile={profileImage}
          index={profileImageIndex}
        />
      )}
    </View>
  );
};

export default HomeWeb;
