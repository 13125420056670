import axios from 'axios';
import EndPoint from './EndPoint';
import Config from 'react-native-config';
import { Platform } from 'react-native';

console.log('Config.BASE_URL>>>', Config.BASE_URL);

const Network = axios.create({
  baseURL:
    Platform.OS !== 'web'
      ? Config.BASE_URL
      : process.env.NODE_ENV == 'production'
      ? EndPoint.baseURL
      : EndPoint.developmentBaseUrl,
});

export default Network;
