import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  Image,
  Linking,
  FlatList,
} from 'react-native';
import React, { useState, useCallback } from 'react';
import Colors from '../../Resources/Colors';
import { BLACK_CROSS_ICON, PAUSE_ICON, PLAY_ICON, PostPlaceholder } from '../../Resources/assets';
import EndPoint from '../../Redux/constants/EndPoint';
import Strings from '../../Resources/Strings';
import style from './style';
// import Carousel, { Pagination } from 'react-native-snap-carousel';
import { mxWidth } from '../../Util';
import { theme } from '../../Util/constant';
import { fontResize } from '../../Util/font';
import Hyperlink from 'react-native-hyperlink';
// import { Carousel, CarouselItem } from './Carousel.web';
import ReactPlayer from 'react-player';
import CustomCrossButton from '../CustomCrossButton/index.web';

const PostDescription = ({
  userData,
  item,
  isEdit = false,
  deleteImage,
  deleteVideo,
  videoPlaceHolder = [],
  onUserProfile,
  onViewImage,
  window,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [numberOfLine, setNumberOfLine] = useState(0);
  const [numberLine, setNumberLine] = useState(0);
  var videoPlayer = React.useRef(null);
  var carouselRef = React.useRef(null);
  const [isBuffering, setBuffering] = useState(true);
  const [showNumber, setShowNumber] = useState(22);
  const { width, height } = window;
  const combinSize =
    width > height
      ? width * 0.05 + height * 0.1
      : width == height
      ? width + height
      : height * 0.05 + width * 0.1;
  const [isPlay, setPlay] = useState(false);
  const [isVideo, setIsVideo] = useState(false);

  const type = userData?.data?.role
    ? userData?.data?.role
    : Strings.PROFESSIONAL;

  const onTextLayout = useCallback((e) => {
    setNumberOfLine(e.nativeEvent.lines.length);
  }, []);

  function empty(str) {
    if (str == '') {
      return true;
    } else {
      return false;
    }
  }

  const createImageData = (dataIndex) => {
    var newImageData = [];
    for (let i = 0; i < item?.media.length; i++) {
      var newObject = EndPoint.baseAssestURL + item?.media[i].uri;
      newImageData.push(newObject);
    }
    onViewImage({ data: newImageData, index: dataIndex });
  };

  const renderImage = (data) => {
    const it = data.item;
    return (
      <TouchableOpacity
        disabled={isEdit}
        style={{
          height: combinSize * 2,
          width: combinSize * 2,
          marginVertical: combinSize * 0.1,
          backgroundColor: Colors.White,
          marginRight: combinSize * 0.05,
          shadowColor: Colors.PrimaryGray1,
          shadowOffset: {
            width: 0,
            height: combinSize * 0.02,
          },

          shadowOpacity: combinSize * 0.025,
          shadowRadius: combinSize * 0.01,
          elevation: combinSize * 0.2,
        }}
        onPress={() => {
          createImageData(data.index);
        }}
      >
        <Image
          resizeMode={'cover'}
          style={{ height: combinSize * 2, width: combinSize * 2 }}
          defaultSource={PostPlaceholder}
          source={
            it
              ? it?.type
                ? it?.type == 'image'
                  ? {
                      uri: it?.file
                        ? EndPoint.baseAssestURL + it?.file
                        : EndPoint.baseAssestURL + it?.uri,
                    }
                  : it?.type == 'image/jpg' ||
                    it?.type == 'image/png' ||
                    it?.type == 'image/jpeg'
                  ? {
                      uri: it?.uri,
                    }
                  : PostPlaceholder
                : {
                    uri: EndPoint.baseAssestURL + it,
                  }
              : PostPlaceholder
          }
        />
        {isEdit && (
          <TouchableOpacity
            style={{
              position: 'absolute',
              top: combinSize * 0.08,
              right: combinSize * 0.08,
            }}
            onPress={() => {
              deleteImage(data);
            }}
          >
            <Image
              source={BLACK_CROSS_ICON}
              style={{ width: combinSize * 0.2, height: combinSize * 0.2 }}
            />
          </TouchableOpacity>
        )}
      </TouchableOpacity>
    );
  };

  const checkChar = ({ firstname, lastname }) => {
    if (item?.tagged_users) {
      var firstLetter = firstname.split('');
      var lastLetter = lastname.split('');
      if (firstLetter[0] !== '@') {
        return false;
      } else {
        if (lastLetter[0] == '@') {
          return false;
        } else {
          if (item?.tagged_users[0]?.name) {
            var afterChar = firstname.slice(1);
            var margeChar = `${afterChar} ${lastname}`;
            const filteredArr = item?.tagged_users?.filter((val) =>
              val?.name?.toLowerCase().includes(margeChar.toLowerCase())
            );
            if (filteredArr.length == 0) {
              return false;
            } else {
              return true;
            }
          } else {
            return false;
          }
        }
      }
    } else {
      return false;
    }
  };

  const viewUserProfile = (data) => {
    if (item.tagged_users[0]?.name) {
      var newLetter = data.lastname.split('');
      var firstLetter = data.firstname.split('');
      var preNameLetter = data?.preName == '' ? '' : data?.preName?.split('');
      if (newLetter[0] == '@') {
        var afterChar = data.lastname.slice(1);
        var filteredArr = item?.tagged_users.filter((val) =>
          val?.name?.toLowerCase().includes(afterChar.toLowerCase())
        );
        if (filteredArr.length !== 0) {
          if (filteredArr.length == 1) {
            onUserProfile(filteredArr[0]?.user_id || filteredArr[0]?.id);
          } else {
            var filteredNewArr = filteredArr.filter((val) =>
              (val?.name == val?.name.split(' ').length) == 1
                ? data?.lastname
                : data?.isFirst
                ? data?.isLast
                  ? `${
                      firstLetter[0] == '@'
                        ? data?.firstname.slice(1)
                        : data?.firstname
                    } ${
                      newLetter[0] == '@'
                        ? data?.lastname.slice(1)
                        : data?.lastname
                    } ${
                      preNameLetter[0] == '@'
                        ? data?.preName.slice(1)
                        : data?.preName
                    }`
                  : `${
                      firstLetter[0] == '@'
                        ? data?.firstname.slice(1)
                        : data?.firstname
                    } ${
                      newLetter[0] == '@'
                        ? data?.lastname.slice(1)
                        : data?.lastname
                    }`
                : `${
                    newLetter[0] == '@'
                      ? data?.lastname.slice(1)
                      : data?.lastname
                  }`
            );
            onUserProfile(filteredNewArr[0]?.user_id || filteredNewArr[0]?.id);
          }
        }
      } else {
        var filteredCharArr = item.tagged_users.filter((val) =>
          val?.name?.toLowerCase().includes(data.lastname.toLowerCase())
        );
        if (filteredCharArr.length != -1) {
          if (filteredCharArr.length == 1) {
            onUserProfile(
              filteredCharArr[0]?.user_id || filteredCharArr[0]?.id
            );
          } else {
            var filteredNewCharArr = filteredCharArr.filter((val) =>
              (val?.name == val?.name.split(' ').length) == 1
                ? data?.lastname
                : data?.isFirst
                ? data?.isLast
                  ? `${
                      firstLetter[0] == '@'
                        ? data?.firstname.slice(1)
                        : data?.firstname
                    } ${
                      newLetter[0] == '@'
                        ? data?.lastname.slice(1)
                        : data?.lastname
                    } ${
                      preNameLetter[0] == '@'
                        ? data?.preName.slice(1)
                        : data?.preName
                    }`
                  : `${
                      firstLetter[0] == '@'
                        ? data?.firstname.slice(1)
                        : data?.firstname
                    } ${
                      newLetter[0] == '@'
                        ? data?.lastname.slice(1)
                        : data?.lastname
                    }`
                : `${
                    newLetter[0] == '@'
                      ? data?.lastname.slice(1)
                      : data?.lastname
                  }`
            );
            onUserProfile(
              filteredNewCharArr[0]?.user_id || filteredNewCharArr[0]?.id
            );
          }
        }
      }
    } else {
      if (item.tagged_users.length == 1) {
        onUserProfile(item?.tagged_users[0]?.user_id || item?.tagged_users[0]);
      }
    }
  };

  const highlightText = () => (
    <Hyperlink
      onPress={(url, text) => Linking.openURL(url)}
      linkStyle={{ color: Colors.Blueberry }}
    >
      <View
        style={{
          flexWrap: 'wrap',
          width: mxWidth * 0.95,
          flexDirection: 'row',
        }}
      >
        {item?.description?.split(' ').map((it, i) => {
          if (it !== '') {
            var firstChar = it.split('');
            if (i < showNumber) {
              return (
                <Text
                  onPress={() => {
                    viewUserProfile({
                      name: `${
                        item?.description.split(' ')[i == 0 ? i : i - 1]
                      } ${it}`,
                      firstname:
                        item?.description.split(' ')[i == 0 ? i : i - 1],
                      lastname: it,
                      preName:
                        item?.description.split(' ')[
                          i == 0
                            ? i
                            : item?.description.split(' ').length == i + 1
                            ? ''
                            : i + 1
                        ],
                      isFirst: checkChar({
                        firstname:
                          item?.description.split(' ')[i == 0 ? i : i - 1],
                        lastname: it,
                      }),
                      isLast:
                        item?.description.split(' ').length == i + 1
                          ? false
                          : checkChar({
                              firstname:
                                item?.description.split(' ')[
                                  i == 0 ? i : i + 1
                                ],
                              lastname: it,
                            }),
                    });
                    // viewUserProfile(it);
                  }}
                  style={{
                    ...theme.fontRegular,

                    fontSize: fontResize(15),

                    color:
                      firstChar[0] == '@'
                        ? Colors.Blueberry
                        : checkChar({
                            firstname:
                              item?.description.split(' ')[i == 0 ? i : i - 1],
                            lastname: it,
                          })
                        ? Colors.Blueberry
                        : Colors.CodGray,
                    marginLeft: 5,
                  }}
                >
                  {it}
                </Text>
              );
            } else if (i == 22) {
              return (
                <TouchableOpacity
                  onPress={() => {
                    setShowMore(!showMore);
                    setShowNumber(!showMore ? 90000000000 : 21);
                  }}
                >
                  <Text style={{ color: Colors.Blueberry }}>
                    {'  ...See More'}
                  </Text>
                </TouchableOpacity>
              );
            }
          } else {
            <Text> </Text>;
          }
        })}

        {item?.description?.split(' ').length > 24 && showMore && (
          <TouchableOpacity
            onPress={() => {
              setShowMore(!showMore);
              setShowNumber(!showMore ? 90000000000 : 21);
            }}
          >
            <Text style={{ color: Colors.Blueberry }}>{'  ...See Less'}</Text>
          </TouchableOpacity>
        )}
      </View>
    </Hyperlink>
  );

  return (
    <View>
      {!isEdit && <View style={style.container}>{highlightText()}</View>}
      {item?.media != '' && (
        <View
          style={{
            borderRadius: 12,
            width: isEdit ? width * 0.8 : width * 0.9,
          }}
        >
          {typeof item?.media == 'string' ? (
            <TouchableOpacity
              style={style.postImageView}
              disabled={isEdit}
              onPress={() => {
                onViewImage({
                  data: [{ uri: EndPoint.baseAssestURL + item?.media }],
                  index: 0,
                });
              }}
            >
              <Image
                resizeMode={'contain'}
                style={style.postImage}
                defaultSource={PostPlaceholder}
                source={
                  item?.media
                    ? {
                        uri: EndPoint.baseAssestURL + item?.media,
                      }
                    : PostPlaceholder
                }
              />
              {isEdit && (
                <TouchableOpacity
                  style={{ position: 'absolute', top: 10, right: 10 }}
                  onPress={() => {
                    deleteImage({ index: 0 });
                  }}
                >
                  <Image
                    source={BLACK_CROSS_ICON}
                    style={{ width: combinSize * 0.2, height: combinSize * 0.2 }}
                  />
                </TouchableOpacity>
              )}
            </TouchableOpacity>
          ) : item?.media &&
            item?.media.length == 1 &&
            item?.media[0]?.type == 'video' ? (
              <View
                style={{
                  height: combinSize * 2.5,
                  width: combinSize * 2.5,
                  marginVertical: combinSize * 0.1,
                  backgroundColor: Colors.White,
                  marginRight: combinSize * 0.05,
                  shadowColor: Colors.PrimaryGray1,
                  shadowOffset: {
                    width: 0,
                    height: combinSize * 0.02,
                  },
                  alignSelf: 'center',
                  shadowOpacity: combinSize * 0.025,
                  shadowRadius: combinSize * 0.01,
                  elevation: combinSize * 0.2,
                }}
              >
              {isVideo ? <ReactPlayer
                  url={EndPoint.baseAssestURL + item?.media[0]?.uri}
                  width={`${combinSize * 2.5}px`}
                  height={`${combinSize * 2.5}px`}
                  controls={false}
                  loop={true}
                  playing={isPlay}
                  onBuffer={(res) => {
                    console.log('buffering>>>', res);
                    // setBuffering(false);
                    // setBuffering(true);
                  }}
                  onBufferEnd={(res) => {
                    // console.log('bufferingEnd>>>', res);
                    // setBuffering(false);
                  }}
                /> : <Image
                  resizeMode={'contain'}
                  style={[
                    {
                      overflow: 'hidden',
                      height: combinSize * 2.5,
                      width: combinSize * 2.5,
                    },
                  ]}
                  defaultSource={PostPlaceholder}
                  source={
                    item?.media[0]?.thumbnil
                      ? {
                          uri:
                            EndPoint.baseAssestURL + item?.media[0]?.thumbnil,
                        }
                      : PostPlaceholder
                  }
                />}
                
                <View style={{
                  height: combinSize * 2.5,
                  width: combinSize * 2.5,
                  alignItems: 'center',
                  justifyContent: 'center', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
{isPlay ?<CustomCrossButton onPress={() => {
                   setPlay(false)
                  }} imageSrc={PAUSE_ICON} imageStyle={{ width: combinSize * 0.2, height: combinSize * 0.2 }}/> : <CustomCrossButton onPress={() => {
                   setIsVideo(true);
                   setPlay(true)
                  }} imageSrc={PLAY_ICON} imageStyle={{ width: combinSize * 0.2, height: combinSize * 0.2 }}/>}
                </View>
                {isEdit && <CustomCrossButton buttonStyle={{ position: 'absolute', top: 10, right: 10 }} onPress={() => {
                    deleteVideo({ index: 0 });
                  }} imageSrc={BLACK_CROSS_ICON} imageStyle={{ width: combinSize * 0.2, height: combinSize * 0.2 }}/> }
                

              </View>
            
          ) : (
            <View
              style={[
                {
                  width: isEdit ? width * 0.89 : window.width * 0.8,
                  overflow: 'hidden',
                },
                item?.media?.length < 3 ? { alignItems: 'center' } : {},
              ]}
            >
              <FlatList
                data={item?.media || []}
                renderItem={renderImage}
                horizontal
              />
            </View>
          )}
        </View>
      )}
    </View>
  );
};

export default PostDescription;
