import { View, Text, Image } from 'react-native';
import React from 'react';
import Colors from '../../Resources/Colors';
import EndPoint from '../../Redux/constants/EndPoint';
import { fontResize } from '../../Util/font';

const CustomNotificationRenderView = ({ window, item, isNotificationView, formatted }) => {
    const {width, height} = window
    const combinSize = width > height ?  (width * 0.05) + (height * 0.1) : width  == height ? width + height  :   (height * 0.05) + (width * 0.1)
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1,
        borderBottomWidth: combinSize * 0.005,
        borderBottomColor: Colors.DimGray,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          paddingVertical: '2%',
          alignItems: 'center',
          width: '100%',
          flex: 1,
        }}
      >
        <View
          style={{
            height: combinSize * 0.4,
            width: combinSize * 0.4,
            borderWidth: combinSize * 0.001,
            borderRadius: combinSize * 0.4,
            borderColor: Colors.White,
            justifyContent: 'center',
            alignItems: 'center',
            padding: combinSize * 0.001,
            shadowColor: '#171717',
            shadowOffset: { width: combinSize * 0.006, height: combinSize * 0.01 },
            shadowOpacity: 0.2,
            shadowRadius: combinSize * 0.01,
          }}
        >
          <Image
            resizeMode="contain"
            style={{ height: combinSize * 0.33, width: combinSize * 0.33, resizeMode: 'cover' }}
            source={EndPoint.baseAssestURL + item?.user?.image || ''}
          />
        </View>

        <View style={{ marginLeft: '2%', width: '70%', justifyContent:'center', }}>
          <Text
            style={{
              fontSize: fontResize(combinSize * 0.1),
              fontWeight: '800',
              paddingBottom: '1%',
              textTransform: 'capitalize',
            }}
          >
            {item?.title || ''}
          </Text>
          <Text
            style={{
              fontSize: fontResize(combinSize * 0.1),
              color: Colors.DimGray,
              paddingBottom: '1%',
            }}
          >
            {item?.description}
          </Text>
          {isNotificationView == false && (
            <Text
              style={{ fontSize: fontResize(combinSize * 0.09), color: Colors.yellow[300] }}
            >
              {'Gold Member'}
            </Text>
          )}
        </View>
      </View>
        <Text style={{fontSize: fontResize(combinSize * 0.09)}}>{formatted || ''}</Text>
    </View>
  );
};

export default CustomNotificationRenderView;
