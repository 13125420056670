//import liraries
import React, { useMemo } from 'react';
import {
  TouchableOpacity,
  useWindowDimensions,
  Image,
  Modal,
  View,
} from 'react-native-web';
import Colors from '../../Resources/Colors';
import Strings from '../../Resources/Strings';
import { theme } from '../../Util/constant';
import { fontResize } from '../../Util/font';
import { CustomText } from '../CustomComponent';
import Type from '../../Constants/Type/type';
import { useDropzone } from 'react-dropzone';
import { setLoading } from '../../Redux/actions/authAction';
import { imageUplaod } from '../../Redux/services/profileService';
import { useDispatch } from 'react-redux';

const ImagePickerModal = ({
  showPickerModal,
  setPickerModal,
  setSelection,
  token,
  imageType,
  isPost = false,
}) => {
  const dispatch = useDispatch();
  const { width, height } = useWindowDimensions();

  const { getRootProps, getInputProps } = useDropzone({
    multiple: isPost,
    accept:
      imageType === 'video'
        ? {
            'video/mp4': ['.mp4', '.MP4'],
          }
        : {
            'image/jpeg': ['.jpeg'],
            'image/png': ['.png'],
          },
    onDrop: async (acceptedFiles) => {
      try {
        let imageFile = await acceptedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        });
        if (isPost) {
          setSelection(imageFile);
        } else {
          dispatch(setLoading(true));
          var getImageURI = await imageUplaod({
            file: imageFile[0],
            token: token,
          });
          dispatch(setLoading(false));
          setSelection(getImageURI.data[0]);
        }

        // console.log("imageFile[0]>>>", imageFile[0])
      } catch (err) {
        dispatch(setLoading(false));
      }
    },
  });

  return (
    <Modal
      animationType="transparent"
      transparent={true}
      visible={showPickerModal}
      onRequestClose={() => {
        setPickerModal(!showPickerModal);
      }}
    >
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            width: width * 0.2,
            alignSelf: 'center',
            marginTop: height * 0.01,
            justifyContent: 'center',
            shadowColor: Colors.gray2,
            backgroundColor: Colors.White,
            padding: height * 0.02,
            overflow: 'hidden',
            shadowRadius: height * 0.02,
            shadowOpacity: 1,
            borderRadius: height * 0.01,
            flexDirection: 'column',
          }}
        >
          <CustomText
            textAlign={'flex-start'}
            {...theme.fontBold}
            fontSize={`${fontResize(height * 0.018)}px`}
            textColor={Colors.Black}
          >
            {imageType === 'video'
              ? Strings.SELECT_VIDEO
              : Strings.SELECT_IMAGE}
          </CustomText>

          <TouchableOpacity
            onPress={() => {
              setSelection(Type.IMAGE_TYPE.CAMERA);
            }}
            style={{ marginTop: height * 0.02 }}
          >
            <CustomText
              textAlign={'flex-start'}
              {...theme.fontMedium}
              marginTop={height * 0.01}
              fontSize={`${fontResize(height * 0.018)}px`}
              textColor={Colors.Black}
            >
              {imageType === 'video' ? Strings.TAKE_VIDEO : Strings.CAMERA}
            </CustomText>
          </TouchableOpacity>

          <TouchableOpacity style={{ marginTop: height * 0.02 }}>
            <div
              {...getRootProps({
                className: 'dropzone',
              })}
            >
              <input {...getInputProps()} />

              <CustomText
                textAlign={'flex-start'}
                {...theme.fontMedium}
                marginTop={height * 0.01}
                fontSize={`${fontResize(height * 0.018)}px`}
                textColor={Colors.Black}
              >
                {Strings.GALLERY}
              </CustomText>
            </div>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              setPickerModal(!showPickerModal);
            }}
          >
            <CustomText
              textAlign={'flex-end'}
              {...theme.fontMedium}
              marginTop={height * 0.018}
              fontSize={`${fontResize(height * 0.018)}px`}
              textColor={Colors.Black}
            >
              {Strings.CANCEL}
            </CustomText>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default ImagePickerModal;
