//import liraries
import React, { useState, useEffect } from 'react';
import {
  View,
  FlatList,
  StyleSheet,
  useWindowDimensions,
  Text,
  RefreshControl
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../Redux/actions/authAction';
import {
  fetchNormalNotificationAction,
  fetchViewedNotificationAction,
} from '../../Redux/actions/profileAction';
import { getDateTime, theme } from '../../Util/constant';
import CustomNotificationRenderView from './CustomNotificationRenderView';
import NotificationTab from './NotificationTab.web';
import { fontResize } from '../../Util/font';
import Colors from '../../Resources/Colors';

// create a component
const Notification = () => {
  const [isNotificationView, setNotificationView] = useState(true);
  const [viewNotification, setViewNotification] = useState([]);
  const [notificationNormal, setNotificationNormal] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [page, setPage] = useState(1);
  const [viewPage, setViewPage] = useState(1);
  const dispatch = useDispatch();
  const window = useWindowDimensions();
  const { width, height } = window;
  const combinSize =
    width > height
      ? width * 0.05 + height * 0.1
      : width == height
      ? width + height
      : height * 0.05 + width * 0.1;

  useEffect(() => {
    fetchNotifications(1);
  }, []);
  const fetchNotifications = (it) => {
    try {
      dispatch(setLoading(true));
      const data = {
        page: it,
      };
      dispatch(
        fetchNormalNotificationAction({
          data,
          onSuccess: (result) => {
            dispatch(setLoading(false));
            setRefreshing(false)
            if (result?.data && result?.data.length !== 0) {
              if (it == 1) {
                setNotificationNormal((previous) => [...result.data]);
                setPage((previous) => previous + 1);
                if(refreshing){
                  setRefreshing(false)
                }
                else {
                  fetchViewedProfileNotification(1);
                  setRefreshing(false)
                }
                
              } else {
                setPage((previous) => previous + 1);
                setNotificationNormal((previous) => [
                  ...previous,
                  ...result.data,
                ]);
              }
            } else {
              fetchViewedProfileNotification(1);
            }
          },
          onError: (error) => {
            setRefreshing(false)
            dispatch(setLoading(false));
            console.log('error', error);
          },
        })
      );
    } catch (err) {
      setRefreshing(false)
      dispatch(setLoading(false));
    }
  };
  const fetchViewedProfileNotification = (it) => {
    try {
      dispatch(setLoading(true));
      const data = {
        page: it,
      };
      dispatch(
        fetchViewedNotificationAction({
          data,
          onSuccess: (result) => {
            dispatch(setLoading(false));
            setRefreshing(false)
            if (result?.data && result?.data.length !== 0) {
              if (it == 1) {
                setViewNotification((previous) => [...result.data]);
                setViewPage((previous) => previous + 1);
              } else {
                setViewPage((previous) => previous + 1);
                setViewNotification((previous) => [
                  ...previous,
                  ...result.data,
                ]);
              }
            }
          },
          onError: (error) => {
            setRefreshing(false)
            dispatch(setLoading(false));
          },
        })
      );
    } catch (err) {
      setRefreshing(false)
      dispatch(setLoading(false));
    }
  };

  const getNextNotification = () => {
    if (isNotificationView) {
      fetchNotifications(page);
    } else {
      fetchViewedProfileNotification(viewPage);
    }
  };

  const renderItem = ({ item, index }) => {
    var formatted = getDateTime(
      item?.updated_at ? item?.updated_at : item?.created_at
    );
    var isLastIndex =
      isNotificationView == true
        ? notificationNormal.length == index + 1
          ? notificationNormal.length % 10 == 0
            ? true
            : false
          : false
        : viewNotification.length == index + 1
        ? viewNotification.length % 10 == 0
          ? true
          : false
        : false;
    return (
      <View>
        <CustomNotificationRenderView
          window={window}
          formatted={formatted}
          item={item}
          isNotificationView={isNotificationView}
        />
        {isLastIndex && (
          <Text
            onPress={() => getNextNotification()}
            style={{
              fontSize: fontResize(combinSize * 0.1),
              ...theme.fontBold,
              color: Colors.Blueberry,
              textDecorationLine: 'underline',
              textAlign: 'right',
              marginTop: combinSize * 0.05,
            }}
          >
            See More
          </Text>
        )}
      </View>
    );
  };
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    if (isNotificationView) {
      setPage(1)
      fetchNotifications(1);
    } else {
      setViewPage(1)
      fetchViewedProfileNotification(1);
    }
  }, [refreshing]);

  return (
    <View style={[styles.container, { width: window.width }]}>
      {/* notification Tab container */}
      <NotificationTab
        window={window}
        setNotificationView={setNotificationView}
        isNotificationView={isNotificationView}
      />
      {/* notification view */}
      <View style={styles.subContainer}>
        <FlatList
        refreshControl={
                  <RefreshControl
                    refreshing={refreshing}
                    tintColor={Colors.Blueberry}
                    onRefresh={onRefresh}
                  />
                }
          data={
            isNotificationView
              ? notificationNormal
              : viewNotification
          }
          renderItem={renderItem}
          showsVerticalScrollIndicator={false}
        />
      </View>
    </View>
  );
};

// define your styles
const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    paddingTop: '2%',
    flex: 1,
  },

  subContainer: {
    marginTop: '1%',
    flex: 1,
    width: '90%',
    marginHorizontal: '5%',
    borderTopWidth: 1,
  },
});

//make this component available to the app
export default Notification;
