import React,{useEffect} from 'react';
import { StatusBar, BackHandler } from 'react-native';
import Route from './Navigation/route';
import { Provider } from 'react-redux';
import { store } from './Redux/store';

const App = () => {
  console.log("App web js")
  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      // Handle the back button press here
      // For example, you can navigate back
      return true; // Prevent default behavior (exit the app)
    });

    return () => backHandler.remove();
  }, []);
  return (
    <>
      <Provider store={store}>
        <StatusBar translucent backgroundColor={'transparent'} />
        <Route />
      </Provider>
    </>
  );
};

export default App;
