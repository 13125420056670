import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import Colors from '../../Resources/Colors';
import { fontResize } from '../../Util/font';
import Strings from '../../Resources/Strings';

const PrivacyPolicy = () => {
  return (
    <View style={style.container}>
      <iframe
        height="99%"
        style={{ border: 'none' }}
        src="https://api.axesseq.com/Privacy-Policy"
        title="description"
      ></iframe>
    </View>
  );
};
const style = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.White,
    paddingTop: '1%',
  }
});

export default PrivacyPolicy;
