import React, { useState } from 'react';
import {
  View,
  Text,
  Image,
  useWindowDimensions,
  TouchableOpacity,
  Linking,
  FlatList,
} from 'react-native';
import {
  faqActive,
  faqNoActive,
  paymentActive,
  paymentNoActive,
  subcriptionActive,
  subscriptionNoActive,
  termActive,
  termNoAcitve,
  profileActive,
  profileNoActive,
  settingActive,
  settingNoActive,
  notificationActive,
  notificationNoActive,
  Logout,
  BlockUser,
  VISIT_GRAY_ICON,
  VISIT_ICON,
} from '../../Resources/assets';
import Colors from '../../Resources/Colors';
import Strings from '../../Resources/Strings';
import SettingRoute from '../../Navigation/SettingRoute';
import { RouteName } from '../../Navigation/routeName';
import { fontResize } from '../../Util/font';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useDispatch, useSelector } from 'react-redux';
import { reset, setToken, setUserData } from '../../Redux/actions/authAction';
import { setProfileData } from '../../Redux/actions/profileAction';
import MoreModal from '../../Component/Feed/more/index.web';
import LogoutModal from './LogoutModal.web';
import { WhiteBlockUser } from '../../Resources/assets';
import { useEffect } from 'react';
import Emitter from '../../Util/eventEmitter';
import Type from '../../Constants/Type/type';
import CustomSettingTab from './CustomSettingTab';

// create a component
const NotificationHeader = ({ navigation }) => {
  const [hideModal, sethideModal] = useState(false);
  const window = useWindowDimensions();
  const { width, height } = window;
  const userData = useSelector((state) => state.auth.userData);
  const type = userData?.data?.role
    ? userData?.data?.role
    : Strings.PROFESSIONAL;
  const dispatch = useDispatch();
  const combinSize =
    width > height
      ? width * 0.05 + height * 0.1
      : width == height
      ? width + height
      : height * 0.05 + width * 0.1;
  const settingTab =
    type == Strings.PROFESSIONAL
      ? [
          {
            name: Strings.PAYMENT,
            active_image: paymentActive,
            inactive_image: paymentNoActive,
          },
          {
            name: Strings.NOTIFICATIONS,
            active_image: notificationActive,
            inactive_image: notificationNoActive,
          },
          {
            name: Strings.FAQ,
            active_image: faqActive,
            inactive_image: faqNoActive,
          },
          {
            name: Strings.SUPPORT,
            active_image: profileActive,
            inactive_image: profileNoActive,
          },
          {
            name: Strings.TERMS_CONDITION,
            active_image: termActive,
            inactive_image: termNoAcitve,
          },
          {
            name: Strings.PRIVACY_POLICY,
            active_image: termActive,
            inactive_image: termNoAcitve,
          },
          {
            name: Strings.SUBSCRIPTIONS,
            active_image: subcriptionActive,
            inactive_image: subscriptionNoActive,
          },
          {
            name: Strings.WEBSITE_VISIT,
            active_image: VISIT_ICON,
            inactive_image: VISIT_GRAY_ICON,
          },
          {
            name: Strings.PRIVACY_SETTINGS,
            active_image: settingActive,
            inactive_image: settingNoActive,
          },
          {
            name: Strings.BLOCK,
            active_image: WhiteBlockUser,
            inactive_image: BlockUser,
          },
          {
            name: Strings.LOGOUT,
            active_image: Logout,
            inactive_image: Logout,
          },
        ]
      : [
          {
            name: Strings.NOTIFICATIONS,
            active_image: notificationActive,
            inactive_image: notificationNoActive,
          },
          {
            name: Strings.FAQ,
            active_image: faqActive,
            inactive_image: faqNoActive,
          },
          {
            name: Strings.SUPPORT,
            active_image: profileActive,
            inactive_image: profileNoActive,
          },
          {
            name: Strings.TERMS_CONDITION,
            active_image: termActive,
            inactive_image: termNoAcitve,
          },
          {
            name: Strings.PRIVACY_POLICY,
            active_image: termActive,
            inactive_image: termNoAcitve,
          },
          {
            name: Strings.WEBSITE_VISIT,
            active_image: VISIT_ICON,
            inactive_image: VISIT_GRAY_ICON,
          },
          {
            name: Strings.PRIVACY_SETTINGS,
            active_image: settingActive,
            inactive_image: settingNoActive,
          },
          {
            name: Strings.BLOCK,
            active_image: WhiteBlockUser,
            inactive_image: BlockUser,
          },
          {
            name: Strings.LOGOUT,
            active_image: Logout,
            inactive_image: Logout,
          },
        ];

  useEffect(() => {
    Emitter.on(Type.LOGOUT, () => {
      logout();
    });
    return () => {
      Emitter.off(Type.LOGOUT);
    };
  }, []);

  const [activeTab, setActiveTab] = useState(
    type == Strings.PROFESSIONAL ? Strings.PAYMENT : Strings.NOTIFICATIONS
  );

  const logout = async () => {
    AsyncStorage.removeItem(Strings.ACCESS_TOKEN_KEY);
    AsyncStorage.removeItem(Strings.LOGIN_DATA);
    dispatch(setToken(''));
    dispatch(setUserData(''));
    dispatch(setProfileData([]));
    dispatch(reset([]));
    navigation.reset({
      index: 0,
      routes: [{ name: RouteName.AUTH }],
    });
  };

  const renderItem = ({ item, index }) => {
    return (
      <CustomSettingTab
        type={type == Strings.PROFESSIONAL}
        tabText={item.name}
        window={window}
        tabPress={() => {
          swichScreen({ index, name: item.name });
        }}
        activeTabColor={
          activeTab == item.name ? Colors.Blueberry : Colors.PrimaryLightGray
        }
        tabImage={
          activeTab == item.name ? item.active_image : item.inactive_image
        }
        textColor={
          item.name == Strings.LOGOUT ? '#EA4335' : activeTab == item.name ? Colors.Blueberry : Colors.PrimaryGray
        }
      />
    );
  };

  const swichScreen = ({ index, name }) => {
    if (type == Strings.PROFESSIONAL) {
      switch (index) {
        case 0:
          setActiveTab(name);
          navigation.navigate(Strings.PAYMENT);
          break;
        case 1:
          setActiveTab(name);
          navigation.navigate(Strings.NOTIFICATION);
          break;
        case 2:
          setActiveTab(name);
          navigation.navigate(RouteName.FAQ);
          break;
        case 3:
          setActiveTab(name);
          navigation.navigate(RouteName.SUPPORT);
          break;
        case 4:
          setActiveTab(name);
          navigation.navigate(RouteName.TERMS_CONDITION);
          break;
        case 5:
          setActiveTab(name);
          navigation.navigate(RouteName.PRIVACY_POLICY);
          break;
        case 6:
          setActiveTab(name);
          navigation.navigate(RouteName.SUBSCRIPTIONS, { isLogin: false });
          break;
        case 8:
          setActiveTab(name);
          navigation.navigate(RouteName.PRIVACY_SETTINGS);
          break;
        case 7:
          Linking.openURL(RouteName.WEBSITE);
          break;
        case 9:
          setActiveTab(name);
          navigation.navigate(RouteName.BLOCK_USER_DATA);
          break;
        case 10:
          sethideModal(!hideModal);
          break;
      }
    } else {
      switch (index) {
        case 0:
          setActiveTab(name);
          navigation.navigate(Strings.NOTIFICATION);
          break;
        case 1:
          setActiveTab(name);
          navigation.navigate(RouteName.FAQ);
          break;
        case 2:
          setActiveTab(name);
          navigation.navigate(RouteName.SUPPORT);
          break;
        case 3:
          setActiveTab(name);
          navigation.navigate(RouteName.TERMS_CONDITION);
          break;
        case 4:
          setActiveTab(name);
          navigation.navigate(RouteName.PRIVACY_POLICY);
          break;
        case 5:
          Linking.openURL(RouteName.WEBSITE);
          break;
        case 6:
          setActiveTab(name);
          navigation.navigate(RouteName.PRIVACY_SETTINGS);
          break;
        case 7:
          setActiveTab(name);
          navigation.navigate(RouteName.BLOCK_USER_DATA);
          break;
        case 8:
          sethideModal(!hideModal);
          break;
      }
    }
  };
  return (
    <>
      <View
        style={{
          marginTop: height * 0.01,
          flexDirection: 'row',
          alignContent: 'center',
          backgroundColor: 'white',
          justifyContent: 'space-between',
          width: width,
        }}
      >
        <FlatList horizontal={true} data={settingTab} renderItem={renderItem} />
      </View>
      <SettingRoute />
      <LogoutModal
        showDeleteModal={hideModal}
        setDeleteModal={sethideModal}
        onHideCallback={() => {
          logout();
        }}
        data={Strings.LOGOUT_ALERT}
      />
      ;
    </>
  );
};

// define your styles

//make this component available to the app
export default NotificationHeader;
