import {
  View,
  Text,
  TouchableOpacity,
  Image,
  TextInput,
  useWindowDimensions,
  FlatList,
  ScrollView,
} from 'react-native';
import React, { useState } from 'react';
import { mxHeight, mxWidth } from '../../Util';
import {
  activityGalleryIcon,
  cameraIcon,
  crossIcon,
  BLACK_CROSS_ICON,
  CAMERA_VIDEO_ICON,
} from '../../Resources/assets';
import { theme } from '../../Util/constant';
import Colors from '../../Resources/Colors';
import { useDropzone } from 'react-dropzone';
import EndPoint from '../../Redux/constants/EndPoint';
import CustomTagView from '../CustomTagView';

const FeedPostWeb = ({
  onPressGallery,
  onTextChange,
  onCreatePost,
  selectedImage,
  commentTextInputValue,
  removeImage,
  cameraPress,
  imagePickPress,
  postVideoImage,
  mediaType,
  postLoading,
  window,
  istag,
  tagValue,
  addTagData,
}) => {
  const [files, setFiles] = useState([]);
  const { width, height } = window;
  const combinSize =
    width > height
      ? width * 0.03 + height * 0.1
      : width == height
      ? width * 0.6 + height
      : height * 0.05 + width * 0.06;
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: { 'video/mp4': ['.mp4', '.MP4'] },
    onDrop: (acceptedFiles) => {
      onPressGallery(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const renderItem = ({ item, index }) => {
    return (
      <View>
        <Image
          resizeMode="stretch"
          style={{
            height: combinSize * 1,
            width: width * 0.07,
            marginLeft: combinSize * 0.2,
            marginVertical: combinSize * 0.03,
          }}
          source={{ uri: `${EndPoint.baseAssestURL}${item?.uri}` }}
        />
        <TouchableOpacity
          onPress={() => {
            removeImage(index);
          }}
          style={{
            padding: 5,
            backgroundColor: Colors.gray2,
            borderRadius: 5,
            position: 'absolute',
            top: 0,
            right: -5,
          }}
        >
          <Image style={{ width: 5, height: 5 }} source={BLACK_CROSS_ICON} />
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <View
      style={{
        borderWidth: 1,
        borderRadius: 12,
        borderColor: '#D8D8D8',
      }}
    >
      {/* Text Input */}
      <View style={{ height: mxHeight * 0.14, padding: 12 }}>
        <TextInput
          style={{
            ...theme.fontRegular,
            fontSize: 15,
            height: '100%',
            color: Colors.Black,
            outlineColor: Colors.White
          }}
          outlineColor= {Colors.White}
          value={commentTextInputValue}
          multiline={true}
          placeholder="What's on your mind?"
          onChangeText={(text) => {
            onTextChange(text);
          }}
        />
      </View>
      {selectedImage && (
        <View>
          {mediaType == 'video' ? (
            postVideoImage ? (
              <Image
                resizeMode="contain"
                style={{ height: 44, width: 44, marginLeft: 22 }}
                source={{ uri: postVideoImage }}
              />
            ) : (
              <View />
            )
          ) : mediaType == 'image' ? (
            selectedImage.length == 0 ? (
              <View />
            ) : (
              <FlatList
                numColumns={5}
                data={selectedImage}
                renderItem={renderItem}
                keyExtractor={(item) => item?.uri}
              />
            )
          ) : (
            <View />
          )}
        </View>
      )}
      {/* Bottom buttons camera, gallery and create post */}

      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          flex: 1,
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            paddingHorizontal: 16,
          }}
        >
          <TouchableOpacity
            style={{ padding: 6 }}
            onPress={() => {
              imagePickPress();
            }}
          >
            <Image
              resizeMode="contain"
              style={{ height: 22, width: 22 }}
              source={activityGalleryIcon}
            />
          </TouchableOpacity>

          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <View style={{ padding: 6 }}>
              <Image
                resizeMode="contain"
                style={{ height: 22, width: 22 }}
                source={CAMERA_VIDEO_ICON}
              />
            </View>
          </div>
        </View>
        <TouchableOpacity
          onPress={onCreatePost}
          style={{
            backgroundColor: Colors.Blueberry,
            // flex: 1,
            height: 55,
            justifyContent: 'center',
            alignItems: 'center',
            borderTopLeftRadius: 12,
            width: '28%',
            borderBottomRightRadius: 12,
          }}
        >
          <Text
            style={{ color: Colors.White, ...theme.fontSemiBold, fontSize: 16 }}
          >
            Create Post
          </Text>
        </TouchableOpacity>
      </View>
      
    </View>
  );
};
export default FeedPostWeb;
