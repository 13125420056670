import { View, ScrollView } from 'react-native'
import React from 'react'
import CustomButton from '../../Component/CustomButton';
import Colors from '../../Resources/Colors';
import Strings from '../../Resources/Strings';
import Events from '../Events';
import { RouteName } from '../../Navigation/routeName';


const HomeEventView = ({window, navigation, events,onViewImage, eventDeleted, searchedEvents}) => {
  return (
    <ScrollView
                  bounces={false}
                  showsVerticalScrollIndicator={false}
                >
                  <View>
                    <CustomButton
                      width={'50%'}
                      marginLeft={'50%'}
                      height={window.height * 0.06}
                      backgroundColor={Colors.Blueberry}
                      marginTop={window.height * 0.01}
                      marginBottom={window.height * 0.04}
                      borderRadius={'10px'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      fontFamily={'Gilroy-SemiBold'}
                      fontSize={'18px'}
                      textColor={Colors.White}
                      onPress={() => {
                        navigation.navigate(RouteName.CREATE_EVENT_POST);
                      }}
                      text={Strings.CREATE_EVENT_POST}
                    />
                  </View>
                  <Events
                    navigation={navigation}
                    events={events}
                    searchedEvents={searchedEvents}
                    eventDeleted={() => {
                        eventDeleted()
                    }}
                    onViewImage={onViewImage}
                    isView={false}
                  />
                </ScrollView>
  )
}

export default HomeEventView