import { View, ScrollView } from 'react-native'
import React from 'react'
import CustomButton from '../../Component/CustomButton';
import Colors from '../../Resources/Colors';
import Strings from '../../Resources/Strings';
import { RouteName } from '../../Navigation/routeName';
import PromotionWeb from '../PromotionWeb/index.web';


const HomePromotionView =({window, navigation, promotion,promotionDeleted, onViewImage}) => {
  return (
    <ScrollView
                  bounces={false}
                  showsVerticalScrollIndicator={false}
                >
                  <View>
                    <CustomButton
                      width={'50%'}
                      marginLeft={'50%'}
                      height={window.height * 0.06}
                      backgroundColor={Colors.Blueberry}
                      marginTop={window.height * 0.03}
                      borderRadius={'10px'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      fontFamily={'Gilroy-SemiBold'}
                      fontSize={'18px'}
                      textColor={Colors.White}
                      onPress={() => {
                        navigation.navigate(RouteName.CREATE_PROMOTIONS, {
                          isEdited: false,
                        });
                      }}
                      text={Strings.CREATE_PROMOTIONS}
                    />
                  </View>
                  <PromotionWeb
                    navigation={navigation}
                    promotions={promotion}
                    promotionDeleted={() => {
                        promotionDeleted();
                    }}
                    onViewImage={onViewImage}
                  />
                </ScrollView>
  )
}
export default HomePromotionView;