import { View, Text, TouchableOpacity, Image } from 'react-native';
import React from 'react';
import { fontResize } from '../../Util/font';

const CustomSettingTab = ({
  window,
  tabPress,
  activeTabColor,
  tabImage,
  textColor,
  tabText,
  type
}
) => {
  const { width, height } = window;
  const combinSize = width > height ?  (width * 0.05) + (height * 0.1) : width  == height ? width + height  :   (height * 0.05) + (width * 0.1)
  return (
    <TouchableOpacity
      style={{
        width: type ? width * 0.0909 : width * 0.115,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        flexDirection: 'row',
        paddingVertical: combinSize * 0.05
      }}
      onPress={() => {
        tabPress();
      }}
    >
      <View
        style={{
          borderRadius: combinSize* 0.12 ,
          backgroundColor: activeTabColor,
          padding: height * 0.01,
        }}
      >
        <Image
          resizeMode="contain"
          style={{ height: combinSize* 0.12, width: combinSize * 0.12 }}
          source={tabImage}
        />
      </View>

      <Text
        style={[
          {
            marginHorizontal: combinSize * 0.02,
            fontSize: fontResize(combinSize * 0.08),
            color: textColor,
          },
        ]}
      >
        {tabText}
      </Text>
    </TouchableOpacity>
  );
};

export default CustomSettingTab;
