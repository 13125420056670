const Strings = {
  OUR_MISSION: 'Our Mission',
  OUR_DETAILS:
    'Our goal is to drive axess to Equality in a passionate way by cultivating a supportive space focused on Diversity, Equity, Inclusion and bonding for  people of color and historically underserved groups. A place to encourage and enable development and promotion of qualified diverse talent at all levels.  Here we are!',
  CONTINUE: 'Continue',
  SAVE_CHANGE: 'Save Changes',
  PROFESSIONAL: 'Professional',
  SELECT_USER_TYPE: 'Select User Type',
  CHOOSE_COLOR: 'Choose Color',
  DO_NOT: 'Don’t have an account?  ',
  SIGNUP: 'Sign up',
  LOGIN: 'Login',
  UPGRADE_APP: 'Upgrade',
  LOGIN_WITH: 'Or Login With',
  SIGN_UP: 'Sign Up',
  SIGN_UP_WITH: 'Or Sign Up With',
  NAME: 'Name',
  COMPANY_NAME: 'Company Name',
  EMAIL: 'Email',
  EMAIL_ADDRESS: 'Email Address',
  NEW_PASSWORD: 'New password',
  CONFIRM_PASSWORD: 'Confirm password',
  TITLE: 'Title',
  DESCRIPTION: 'Description',
  PHONE: 'Phone',
  PASSWORD: 'Password',
  ABOUT_ME: 'About Me',
  FORGOT_PASSWORD: 'Forgot Password?',
  DONT_HAVE_ACCOUNT: 'Don’t have an account?',
  BY_CLICK_LOGIN: 'By clicking login, you agree to our ',
  TERMS_CONDITIONS: 'Terms and Conditions',
  AND: ' and ',
  PRIVACY_POLICY: 'Privacy Policy',
  ALREADY_HAVE_ACCOUNT: 'Already have an account?',
  HOME: 'Home',
  JOB_EVENTS: 'Job & Events',
  SETTINGS: 'Settings',
  SWITCH_SUBSCRIPTION: 'Switch to gold',
  CREATE_PROFILE: 'Create your Profile',
  CREATE_JOB_POST: 'Create Job Post',
  CREATE_PROMOTIONS: 'Create Promotions',
  PRESENT_WORK: 'I am currently working in this role',
  PRESENT: 'Present',
  APPLY_JOB: 'Apply Job',
  APPLY_NOW: 'Apply Now',
  CREATE_JOB: 'Create Job',
  SEND_NEW_TICKET: 'Send New Ticket',
  SUBSCRIPTIONS_BUTTON: 'subscribed',
  UNSUBSCRIPTION_BUTTON: 'Subscription',
  SUBSCRIBE_BUTTON: 'Subscribe',
  CANCEL_SUBSCRIPTION: 'Cancel Subscription?',
  SUBMIT: 'Submit',
  JOB_POST_TITLE: 'Title of the Job',
  JOB_TYPE: 'Job Type',
  JOB_TIME: 'Job Time',
  SALARY_RANGE: 'Salary Range',
  JOB_DESCRIPTION: 'Job Description',
  EVENT_DESCRIPTION: 'Event Description',
  QUALIFICATIONS: 'Qualifications',
  CREATE_EVENT_POST: 'Create Event Post',
  EVENT_POST_TITLE: 'Event Title',
  SUMMARY: 'Summary',
  SUBMITTED: 'Submitted',
  SUCCESSFULLY_APPLIED: 'Successfully Applied',
  SKILL: 'Skill',
  OK: 'Ok',
  MY_BOOKING: 'My Booking',
  MY_RECRUTING_DATES: 'My Recruting Dates',
  CREATE_STORY: 'Create Story',
  BASIC_INFO: 'Basic info',
  IM_INTERESTED: "I'm Interested",
  IM_GOING: "I'm Going",
  RESET_PASSWORD: 'Reset password',
  SET_PASSWORD: 'Set password',
  RESET_PASSWORD_DETAILS:
    'Enter your email ID or phone number associated with your account and we’ll send an verification code for reset your password',
  CHANGE_PASSWORD: 'Create new password',
  CHANGE_PASSWORD_DETAILS:
    'Your password must be different from previous used password.',
  RECENT_CONTACTS: 'Recent Contacts',
  VIEW_ALL: 'View all',
  SEE_ALL: 'See All',
  VACANCIES: 'Vacancies',
  CONTACT: 'contact',
  OUR_GOAL: 'Our Goal',
  VIEW_FULL_PROFILE: 'View Full Profile',
  NO_NETWORK: 'Oops, No Internet Connection',
  NO_NETWORK_DESCRIPTION:
    'Make sure wifi or cellular data is turned on and then try again.',
  UNBLOCK: 'Unblock',

  // Oraganizer

  ORGANIZATION: 'Organization',
  BUSINESS_TYPE: 'Business Type',
  USER_TYPE: 'User Type',
  USER_INFO: 'User Info',
  END_USER_TYPE: 'End User Type',
  VERIFY: 'Verify',
  VERIFY_YOUR_PHONE: 'Verify Your Phone',
  DID_NOT_RECIEVE_CODE: 'Didn’t receive code? ',
  RESEND_OTP: 'Resend OTP',
  COMPANY_INFO: 'Company info',
  OTHER: 'Other',
  COMPANY_OVERVIEW: 'Company Overview',
  COMPANY_EMAIL: 'Company Email',
  COMPANY_PHONE: 'Company Phone',
  BUSINESS_ADDRESS: 'Business Address',
  ADDRESS: 'Address',
  BUSINESS_NUMBER: 'Business Number',
  ABOUT_COMPANY: 'About Company',
  ATTACH_COMPANT_LOGO: 'Attach Company Logo',
  ATTACH_IMAGES_OR_PROOF: 'Attach images or proof',
  COMPANY_LOGO: 'Company Logo',
  CREATE_COMPANY_PROFILE: 'Create Company Profile',
  WEBSITE: 'Website',
  FOUNDED: 'Founded',
  HEADQUARTES: 'Headquartes',
  SIZE_OF_COMPANY: 'Size of Company',
  INDUSTRY: 'Industry',
  HOW_MANY_ATTENDEES: 'How many Attendees',
  DATE_AND_TIME: 'Date & Time',
  DATE: 'Date',
  TIME: 'Time',
  START_DATE_TIME: 'Start Date & Time',
  END_DATE_TIME: 'End Date & Time',

  // Professional
  PROFESSIONALS: 'Professionals',
  USER_PROFILE_IMAGE: 'User Profile picture',
  ADD_CERTIFICATE: 'Add Certificates',
  ATTACH_YOUR_PHOTO: 'Attach your photo',
  ATTACH_CERTIFICATE_PHOTO: 'Attach Certificates images',
  UPLOAD_PHOTO: 'Upload Photo',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  YOUR_EXPERIENCE: 'Your Experience',
  GOOGLE_DRIVE: 'Google Drive',
  DROPBOX: 'Dropbox',
  LOCALFILE: 'Local File',
  ADD_NEW: 'Add New',
  ADD_MORE: 'Add More',
  YOUR_ROLE: 'Your Role',
  CREATE_USER_PROFILE: 'Create User Profile',
  UPDATE_USER_PROFILE: 'Update User Profile',
  ADD_IMAGE_OPTIONAL: 'Add Images (Optional)',
  ADD_KEYWORDS: 'Add Keywords',
  WORK_EXPERIENCE: 'Work Experience',
  EDUCATION: 'Education',
  DEGREE: 'Degree',
  LINK_WITH_RESUME: 'Link your resume on',
  FILE_SIZE_TEXT: 'File size is greater than 10MB',
  FILE_SIZE_VIDEO: 'File size is greater than 100MB',
  UPLOAD: 'Upload',
  PROMOTION_POST: 'PROMOTION POST',
  LOCAL_PDF: 'local pdf',
  LOCAL: 'local',
  GOOGLE_DRIVE_DOC: 'googleDrive doc',
  GOOGLE_DRIVE_PDF: 'googleDrive pdf',
  SELECT_EDUCATION: 'Select Education',
  VIEW_APP_TUTORIAL: 'View App Tutorial',
  WEBSITE_VISIT: 'Visit Our Website',
  GOLD_PRICE_VALUE: '34.99',

  // Recruiter
  RECRUITER: 'Recruiter',

  // Web
  CREATE_WEB_PROFILE: 'Create Profile',
  CREATE_PROMO: 'Create Promo / Ad  Post',

  //Error messages
  INVALID_NAME: 'Please enter atleast 3 character name',
  EMPTY_EMAIL: 'Please enter email',
  EMPTY_PASSWORD: `Please enter password`,
  EMPTY_PHONE_NUMBER: 'Please enter phone number',
  INVALID_EMAIL: 'Please enter valid email',
  INVALID_PHONE_NUMBER: 'Please enter valid phone number',
  INVALID_PASSWORD: 'Please enter valid password',
  INVALID_NEW_PASSWORD: 'Please enter new password',
  CONFIRM_INVALID_PASSWORD: 'Please enter confirm password',
  INVALID_PASSWORD_MATCH: "Entered password doesn't match",
  INVALID_PASSWORD_LENGTH: 'Please enter correct password',
  INVALID_NEW_PASSWORD_LENGTH: 'Password length must be at least 6 characters',
  ENTER_VALID_CREDENTIALS: 'Enter a valid credentials',
  PASSWORD_NOT_MATCH: 'Password are not same',
  PASSWORD_MATCH: "Password doesn't match",
  INVALID_JOB_TITLE: 'Please enter atleast 3 character title',
  INVALID_COMPANY_NAME: 'Please enter company name',
  INVALID_COMPANY_ADDRESS: 'Please enter address',
  INVALID_AMOUNT: 'Please enter amount',
  INVALID_JOB_DESCRIPTION: 'Please enter job description',
  INVALID_EVENT_DESCRIPTION: 'Please enter event description',
  INVALID_EVENT_IMAGE: 'Please select image for the event',
  INVALID_QUALIFICATIONS: 'Please enter qualifications',
  INVALID_ATTENDEES: 'Please enter attendees',
  INVALID_DATE_AND_TIME: 'Please select date & time',
  EMPTY_COMPANY_NAME: 'Please enter company name',
  EMPTY_COMPANY_EMAIL: 'Please enter company email',
  VAILD_COMPANY_EMAIL: 'Please enter valid company email',
  EMPTY_COMPANY_ADDRESS: 'Please enter business address',
  EMPTY_COMPANY_NUMBER: 'Please enter business number',
  EMPTY_COMPANY_PHONE_NUMBER: 'Please enter  phone number',
  EMPTY_COMPANY_ABOUT: 'Please enter company information',
  SUCCESSFULLY_ADD_CALENDER: 'Successfully add to calender event',
  SUCCESSFULLY_EVENT: 'Successfully apply to event',
  COMPANY_PROFILE_IMAGE: 'Company picture',
  INVALID_POST_MESSAGE: 'Please enter message',
  INVALID_PROMOTION_TITLE: 'Please enter atleast 3 character title',
  INVALID_PROMOTION_AMOUNT: 'Please enter amount',
  INVALID_PROMOTION_DESCRIPTION: 'Please enter promotion description',
  INVALID_AMOUNT: 'Please enter valid amount',
  INVALID_IMAGE: 'Please select image',
  INVALID_CARD_NAME: 'please enter valid card name',
  INVALID_CARD_NUMBER: 'Please enter valid card number',

  //Async Storage key
  ACCESS_TOKEN_KEY: 'ACCESS_TOKEN_KEY',
  DEVICE_TOKEN: 'DEVICE_TOKEN',
  SEND: 'Send',
  READ_MORE_COMMENTS: 'Read More Comments',
  LESS: 'Less',
  LOGIN_DATA: 'LOGIN_DATA',
  IS_CREATE_PROFILE: 'IS_CREATE_PROFILE',
  CREATE_PROFILE_DATA: 'CREATE_PROFILE_DATA',
  IS_VERIFICATION: 'IS_VERIFICATION',
  IS_SOCIAL: 'IS_SOCIAL',
  ATTENDEES: 'Attendees',
  SHOW_BIG_BANNER: 'SHOW_BIG_BANNER',
  See_Who_Viewed:
    'See who viewed your profile and many other exciting features',
  UNLOCK_FULL: 'Unlock Full',
  LEFT_TIME: 'left at this price!',
  // Profile
  DETAILS: 'Details',
  COMPANY_DETAILS: 'Company Detail',
  CONNECTIONS: 'Connections',
  FOLLOWERS: 'followers',
  PROFILE_COMPLETION: 'Profile Completion',
  CONTACT_INFO: 'Contact Info',
  ABOUT: 'About',
  EDUCATION: 'Education',
  LICENSES: 'Licenses & Certification',
  EDIT_DETAILS: 'Edit Details',
  MY_WORKSPLACE: 'My Workplace',
  EDIT_YOUR_PROFILE: 'Edit your Profile',
  APPLY_CHANGES: 'Apply Changes',
  PERMISSION_NOTITIFCATION_MESSAGE:
    'Please turn on notification permission manually',
  BLOCKED_PERMISSION: 'The permission is denied and not requestable anymore',
  CANNOT_OPEN: 'cannot open settings',
  GRANTED_PERMISSION: 'The permission is granted',
  LIMITED_PERMISSION: 'The permission is limited: some actions are possible',
  DENIED_PERMISSION:
    'The permission has not been requested / is denied but requestable',
  UNIAVAILABLE_PERMISSION:
    'This feature is not available (on this device / in this context)',
  BLOCK_USER_MESSAGE: 'You blocked this account. Tap to unblock',
  POST_LIKES: 'Reactions',
  POST: 'Post',

  // Calender
  SCHEDULED_EVENTS: 'Scheduled Events',
  MY_EVENTS: 'My Events',
  MY_EVENT_DATES: 'My Event Dates',
  MY_SCHEDULE: 'My Schedules',
  MY_EXPERIENCE: 'My Experience',
  COMPANIES: 'Companies',
  DIRECT_MESSAGE: 'Direct message',
  NOTIFICATIONS: 'Notifications',

  // Job post and Event post

  PROMOTIONS: 'Promotions',
  JOB_POST_WEB: 'Job Posts',
  EVENTS: 'Events',
  FEATURED_JOB: 'Featured Jobs',
  REQUIREMENTS: 'Requirements',
  EVENT_TITLE: 'Event Title',
  IM_ATTENDED: 'I attended',
  CONVERSATIONS: 'Conversations',
  EDIT: 'Edit',
  ALL: 'All',
  RECOMMENDED: 'Recommended',

  // CREATE POST
  HIDE_POST: 'Hide Post',
  EDIT_POST: 'Edit Post',

  // Calender
  INTERVIEWS: 'Interviews',

  // Profile Settings Strings
  PAYMENT: 'Payment',
  MANAGE_CARDS: 'Manage Cards',
  ADD_NEW_CARD: 'Add New Card',
  EDIT_CARD: 'Edit Card',
  FAQ: 'FAQ',
  SUPPORT: 'Support',
  TERMS_CONDITION: 'Terms & Condition',
  SUBSCRIPTIONS: 'Subscriptions',
  APP_TUTORIAL: 'App Tutorial',
  SUBSCRIPTIONS_HEADER: 'Subscription',
  PRIVACY_SETTINGS: 'Privacy Settings',
  LOGOUT: 'Logout',
  DELETE_ACCOUNT: 'Delete Account',
  FOLLOW: 'Follow',
  UNFOLLOW: 'Unfollow',
  FOLLOWED: 'Followed',
  PROFILE: 'Profile',
  PROFILE_PIC: 'Profile picture',
  NO_NOTIFICATION: 'No Notification',
  NO_SUBSCRIPTION: 'No Subscriptions',
  PLEASE_UPGRADE_GOLD:
    'Please upgrade your Membership to Gold Axess for unlocking this feature.',
  LOGOUT_ALERT: 'Are you sure you want to logout',
  DELETE: 'Delete',
  BLOCK: 'Block user',
  SUGGESTION_USER: 'Suggestion user',
  DELETE_ACCOUNT: 'Delete Your Account?',
  DELETE_ACCOUNT_DESC:
    ' Deleting your account will remove all of your account’s data, contacts, and other information. Are you sure you want to proceed?',

  // Notification Strings

  WHO_VIEWED: 'Who Viewed  My Profile',
  UNDER_REVIEW: 'Under Review',
  HOW_CAN_YOU: 'How can we help you?',
  I_AGREE: 'I Agree',
  I_AGREE_WITH: 'I agree with Terms & Conditions',

  // Message
  MESSAGE: 'Message',
  RECOMMENDED: 'Recommended',
  SET_TIME_DATE: 'Set Time and Date',
  SET: 'Set',
  Note: 'Note',
  TERM_AND_CONDITION: 'Terms & Condition',
  PRIVACY_SETTING: 'Privacy Setting',
  NOTIFICATION: 'Notification',
  PROFILE_VIEWED: 'Who Viewed Profile',

  // Payment
  BILLING_INFORMATION: 'Billing Information',
  NAME_ON_CARD: 'Name on card',
  CARD_NO: 'Card Number',
  EXPIRY_DATE: 'Expiry Date',
  CARD_CVV: 'Card CVV',
  CARD_HOLDER_REQUIRED: 'Card holder is required',
  CARD_NUMBER_REQUIRED: 'Card number is required',
  CARD_EXPIRE_DATE_REQUIRED: 'Card exp date is required',
  CARD_CVV_REQUIRED: 'Card cvv is required',
  INVALID_YEAR: 'Invalid year',
  INVALID_MONTH: 'Invalid month',
  INVAILD_MONTH_AND_YEAR: 'Invalid month and year',

  // Support
  SUBJECT_REQUIRED: 'Subject is required',
  SUBJECT: 'Subject',
  YOUR_MESSAGE: 'Your Message',
  MESSAGE_REQUIRED: 'Message is required',

  // PROMOTION
  PROMOTION_TITLE: 'Promotion Title',
  AMOUNT: 'Amount',

  // DELETE MODAL
  DELETE_JOB: 'Are you sure you want to remove Job post?',
  DELETE_JOB_TITLE: 'Delete Job Post',
  DELETE_EVENT_TITLE: 'Delete Event Post',
  DELETE_PROMOTION_TITLE: 'Delete Promotion Post',
  DELETE_EVENT: 'Are you sure you want to remove Event post?',
  DELETE_PROMOTION: 'Are you sure you want to remove Promotion post?',

  // ADVERTISEMENT
  ADVERTISEMENT: 'Advertisement',
  PLACE_AD: 'Place Your Ad',

  // IMAGE PICKER
  SELECT_IMAGE: 'Select Image',
  GALLERY: 'Choose from Device',
  CAMERA: 'Take Photo',
  CANCEL: 'Cancel',
  IMAGE: 'Image',

  SEE_MORE: '...See More',
  SEE_LESS: '...See Less',
  LIKES: 'Likes',
  NO_LIKE: 'No Like',
  NO_COMMENT: 'No Comment',
  COMMENT: 'Comment',
  COMMENTS: 'Comments',
  BE_FIRST_COMMENT: 'Be the first to comment',
  ONE_SHARE: '1 share',
  SHARES: 'shares',
  ZERO_SHARE: '0 Share',

  EDUCATION_TYPE: {
    EDUCATION_NAME: 'School/College/University name',
    DEGREE_TITLE: 'Degree Title',
    FIELD_OF_STUDY: 'Field of study',
    EDUCATION_NAME_ERROR: 'School/College/University name field is empty',
    DEGREE_TITLE_ERROR: 'Degree Title field is empty',
    FIELD_OF_STUDY_ERROR: 'Field of study field is empty',
    EMPTY_EDUCATION: 'Please enter education',
    EMPTY_DEGREE: 'Please enter degree',
    EMPTY_STUDY: 'Please enter study',
  },

  REPORT_POST: 'Report Post',
  ARE_YOU_SURE_REPORT: 'Are you sure you want to report this post?',

  TUTORIAL: {
    WELCOME: 'Welcome to AxessEQ',
    FREE_SUBSCRIPTION:
      'User will have Basic Axess subscription After login that is free with limited features.',
    GOLD_SUBSCRIPTION:
      'User will have Gold Axess subscription for unlocking all features in AxessEQ App.',
    JOB_POST:
      'Users can see Job post along with Featured Jobs and Recommended from Jobs Screen to apply.',
    SCHEDULED_EVENTS:
      'User can see Scheduled Events from my Recruiting Dates screen.',
    MESSAGES:
      'AxessEQ provides End users to chat with direct companies and individual inside the app.',
    SUPPORT:
      'User can create support ticket and contact with Admin from “Settings > Support”',
    DELETE_ACCOUNT:
      'User Can delete AxessEQ account from “Settings > Delete Account”.Account will be deleted permanently.',
    HOMEPAGE:
      'Users Can create post from Homepage and interact with connections by sharing ideas, job posts and thoughts with each other.',
    WHO_VIEWD_MY_PROFILE:
      'End User will notify whenever App users viewed their proﬁle and User can see who viewed my proﬁle from “Notiﬁcations > Who Viewed my Proﬁle” section.',
    CREATE_JOB_POST:
      'Recruiters and Organization can create Job Post from “Jobs > Create Job Post”.',
    CREATE_EVENT:
      'Recruiters and Organization can create Events from “Jobs > Create Events”.',
    EDIT_PROFILE:
      'User can update user proﬁle information anytime from “Proﬁle > Edit Proﬁle”',
  },
  ARE_YOU_SURE_REPORT: 'Are you sure you want to report this post?',

  TUTORIAL: {
    WELCOME: 'Welcome to AxessEQ',
    FREE_SUBSCRIPTION:
      'User will have Basic Axess subscription After login that is free with limited features.',
    GOLD_SUBSCRIPTION:
      'User will have Gold Axess subscription for unlocking all features in AxessEQ App.',
    JOB_POST:
      'Users can see Job post along with Featured Jobs and Recommended from Jobs Screen to apply.',
    SCHEDULED_EVENTS:
      'User can see Scheduled Events from my Recruiting Dates screen.',
    MESSAGES:
      'AxessEQ provides End users to chat with direct companies and individual inside the app.',
    SUPPORT:
      'User can create support ticket and contact with Admin from “Settings > Support”',
    DELETE_ACCOUNT:
      'User Can delete AxessEQ account from “Settings > Delete Account”.Account will be deleted permanently.',
    HOMEPAGE:
      'Users Can create post from Homepage and interact with connections by sharing ideas, job posts and thoughts with each other.',
    WHO_VIEWD_MY_PROFILE:
      'End User will notify whenever App users viewed their proﬁle and User can see who viewed my proﬁle from “Notiﬁcations > Who Viewed my Proﬁle” section.',
    CREATE_JOB_POST:
      'Recruiters and Organization can create Job Post from “Jobs > Create Job Post”.',
    CREATE_EVENT:
      'Recruiters and Organization can create Events from “Jobs > Create Events”.',
    EDIT_PROFILE:
      'User can update user proﬁle information anytime from “Proﬁle > Edit Proﬁle”',
    SEARCH_PROFILE:
      'User can Search AxessEQ connections from Homepage > Search Feature (magnifying glass Icon) and follow them.',
  },

  MORE: {
    I_DONT_WANT_TO_SEE_POST: 'I don’t want to see this post',
    DONT_SEE_POST: 'Don’t want to see this',
    BLOCK_USER: 'Block User',
    SHARE_VIA: 'Share via',
    UNFOLLOW: 'Unfollow',
    DELETE_POST: 'Delete post',
    EDIT_POST: 'Edit post',
    REMOVE_CONNECTION: 'Remove connection with',
    REPORT_POST: 'Report Post',
    WHY_DONT_SEE: "Tell us why you don't want to see this",
    YOUR_FEEDBACK_WILL_HELP_US:
      'Your feedback will help us improve your experience',
    REPORT: 'Report',
    WHY_ARE_YOU_REPORTING_THIS: 'Why are you reporting this?',
    WHY_ARE_YOU_REPORTING_THIS_DESC:
      "We won't notify the member of your report unless you report it as intellectual property infringement.",
  },

  FEEDBACK_POST: {
    NOT_INTERESTED_IN_AUTHOR: "I'm not interested in the author.",
    NOT_INTERESTED_IN_TOPIC: "I'm not interested in this topic.",
    I_SEEN_TOO_SAME_POST: "I've seen too many posts on this topic",
    I_SEEN_POST_BEFORE: "I've seen this post before",
    OLD_POST: 'This post is old.',
    SOMETHING_ELSE: "It's something else",
    IF_THINK_THIS_GOES_AGAINST_PROFESSION:
      'If you think this goes against our Professional Community Policies, please let us know.',
    REPORT_THIS_POST: 'Report this post',
  },

  REPORT_POST: {
    SUSPICIOUS_SPAM_FAKE: 'Suspicious, spam, or fake',
    HATEFUL_SPEECH: 'Harassment or hateful speech',
    PHYSICAL_HARM: 'Violence or physical harm',
    ADULT_CONTENT: 'Adult content',
    DEFAMATION: 'Intellectual property infringement or defamation',
    POST_REMOVED: 'Post removed',
    NO_LONGER_SEE_POST_1: "You'll no longer see posts from",
    NO_LONGER_SEE_POST_2: 'in your feed.',
  },

  YES: 'Yes',
  NO: 'No',
  UNFOLLOW_TITLE: 'Unfollow',
  ARE_YOU_SURE_TO_UNFOLLOW: 'Are you sure you want to unfollow',
  BLOCK_TITLE: 'Block User',
  ARE_YOU_SURE_TO_BLOCK: 'Are you sure you want to block?',
  BLOCKED_USER: 'Blocked user',
  SUGGESTION_NEW_CONNECT: 'Suggestion new connections',
  CANCEL_SUGGESTION_MESSAGE: 'Are you sure you want to hide this user?',
  SUGGESTIONS: 'Suggestions',
  ARE_YOU_SURE_TO_DELETE_POST: 'Are you sure you want to delete this post?',
  ARE_YOU_SURE_IMAGE: 'Are you sure you want to delete image?',
  IMAGE_DELETE: 'Image Delete',
  ARE_YOU_SURE_VIDEO: 'Are you sure you want to delete video?',
  VIDEO_DELETE: 'Video Delete',

  DELETE_DESCRIPTION:
    'Please enter the word “DELETE”\nbefore we delete your account.',
  CONFIRMATION: 'Confirmation',
  RETURN: 'Return',
  DELETED: 'Deleted!',
  FOLLOWERS: 'Followers',
  SELECT_IMAGE: 'Select IMAGE',
  SELECT_VIDEO: 'Select VIDEO',
  TAKE_PHOTO: 'Take Photo...',
  TAKE_VIDEO: 'Take Video...',
  CHOOSE_GALLERY: 'Choose from Gallery...',
  ENTER_MOBILE_NUMBER: 'Enter Mobile Number Without Dashes',

  // Gold subscription

  MOST_POPULAR: 'MOST POPULAR',
  GOLD_AXESS: 'Gold Axess',
  THIS_BASIC: 'This is Basic access to recruiters.',
  USERS_WILL_HAVE: 'Users will have suggestion contact feature inside the app.',
  USERS_CAN_DIRECTLY:
    'Users can directly contact recruiters with a search feature.',
  USERS_WILL_NOTIFY: "Users will notify who's viewed your profile.",
  FOUR_MORE_PROFILE: '4x more profile views on average in a month.',
};
export default Strings;
