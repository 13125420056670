import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  FlatList,
  TextInput,
  KeyboardAvoidingView,
  Keyboard,
  Platform,
  Alert,
  TouchableWithoutFeedback,
  useWindowDimensions,
} from 'react-native';
import React, { useRef, useState, useEffect } from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import Colors from '../../Resources/Colors';
import { isNative, mxHeight, mxWidth } from '../../Util';
import {
  BackButton,
  HeaderContainer,
} from '../Authentication/VerificationStyle';
import { backIcon, faqActive } from '../../Resources/assets';
import style from './style';
import PostUserDetails from '../../Component/PostUserDetails';
import { useDispatch, useSelector } from 'react-redux';
import PostDescription from '../../Component/PostDescription';
import PostLikeCommentView from '../../Component/PostLikeCommentView';
import SingleLineText from '../../Component/SingleLineView';
import LikeCommentButton from '../../Component/LikeCommentButton';
import Strings from '../../Resources/Strings';
import { replaceMentionValues } from 'react-native-controlled-mentions';
import {
  blockuser,
  commentPost,
  deletePostAction,
  getPostDetails,
  hideAllPost,
  likePost,
  setCreatePostLoading,
  sharePost,
  updatePost,
  uploadImage,
} from '../../Redux/actions/homeAction';
import CommentsView from '../../Component/commentsView';
import Emitter from '../../Util/eventEmitter';
import CustomTagView from '../../Component/CustomTagView';
import { RouteName } from '../../Navigation/routeName';
import Type from '../../Constants/Type/type';
import CustomConfirmationModal from '../../Component/CustomConfirmationModal';
import { unFollowUserAction } from '../../Redux/actions/profileAction';
import { setLoading } from '../../Redux/actions/authAction';
import {
  generateVideoThumbnail,
  getFileFromBase64,
  theme,
} from '../../Util/constant';
import MediaButton from './MediaButton';
import CustomMediaSelectorModal from '../../Component/CustomMediaSelectorModal/CustomMediaSelectorModal';
import { imageUplaod } from '../../Redux/services/profileService';
import CustomHeader from '../../Component/CustomHeader';
import CameraModal from '../HomeWeb/Camera/Modal/index.web';
import CustomConfirmationWebModal from '../../Component/CustomConfirmationModal/index.web';

var textLength = 0;

const EditPost = ({ navigation, route }) => {
  const userData = useSelector((state) => state.auth.userData);
  const allUsers = useSelector((state) => state.profile.allUser);
  const postData = useSelector((state) => state.home.postDetails);
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  const [commentText, setCommentText] = useState('');
  const type = userData?.data?.role
    ? userData?.data?.role
    : Strings.PROFESSIONAL;
  const [description, setDescription] = useState('');
  const [video, setVideo] = useState([]);
  const [postImage, setPostImage] = useState([]);
  const [postOldImage, setPostOldImage] = useState([]);
  const [postNewImage, setPostNewImage] = useState([]);
  const [isVisible, setVisible] = useState(false);
  const [mediaType, setMediaType] = useState('text');
  const [postVideo, setPostVideo] = useState([]);
  const [postVideoImage, setPostVideoImage] = useState([]);
  const [tagValue, setTagValue] = useState([]);
  const [istag, setTag] = useState(false);
  const [tagUser, setTagUser] = useState([]);
  const [lastValue, setLastValue] = useState('');
  const [postText, setPostText] = useState('');
  const { _id } = route?.params?.postData;
  const window = useWindowDimensions();
  const [open, setOpen] = useState(false);
  const [newPostImage, setNewPostImage] = useState([]);
  const [deleteType, setDeleteType] = useState('image');
  const [deleteData, setDeleteData] = useState('');
  const [isDelete, setIsDelete] = useState(false);

  var inputRef = useRef(null);

  useEffect(() => {
    fetchPostData();
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      () => {
        setKeyboardVisible(true);
      }
    );
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => {
        setKeyboardVisible(false);
      }
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, []);

  const fetchPostData = () => {
    try {
      dispatch(
        getPostDetails({
          _id,
          onSuccess: (result) => {
            setDescription(result?.data[0]?.description || '');
            var newTagUser = result?.data[0]?.tagged_users
              ? [...result?.data[0]?.tagged_users]
              : [];
            setTagUser([...newTagUser]);
            if (
              result?.data[0]?.media !== '' &&
              typeof result?.data[0]?.media !== 'string'
            ) {
              if (result?.data[0]?.media[0]?.type == 'video') {
                // setVideo(result?.data[0]?.media);
                setPostVideo(result?.data[0]?.media);
              } else {
                setPostImage(result?.data[0]?.media);
                setPostOldImage(result?.data[0]?.media);
              }
            } else if (
              result?.data[0]?.media !== '' &&
              typeof result?.data[0]?.media == 'string'
            ) {
              setPostImage([{ type: 'image', uri: result?.data[0]?.media }]);
              setPostOldImage([{ type: 'image', uri: result?.data[0]?.media }]);
            }
          },
          onError: (error) => {
            console.log('err', error);
            Alert.alert('Post Details', 'Post does not exist', [
              {
                text: 'Yes',
                onPress: () => navigation.goBack(),
              },
              { text: 'No', onPress: () => {} },
            ]);
          },
        })
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  // const onTextChange = (text) => {
  //   setDescription(text);
  // };

  const removeImage = (data) => {
    // if (postVideoImage.length == 0) {
      
    // } else {
    //   var oldData = postVideo;
    //   var oldVideoImage = postVideoImage;
    //   oldVideoImage.splice(0, 1);
    //   oldData.splice(0, 1);
    //   setPostVideoImage([]);
    //   setPostVideo([]);
    // }

    var oldImage = postImage;
              var oldImage = postImage.filter((val, i) => i != data?.index);
              console.log(...oldImage, postImage.length, data?.index);
              setPostImage([...oldImage]);
  };

  const removeVideo = () => {
    var oldData = postVideo;
          oldData.splice(0, 1);
          setPostVideo([]);
  };

  const updatePostButton = () => {
    try {
      if (description !== '') {
        if (postImage.length == 0 && (postVideo == null || postVideo == '')) {
          dispatch(setCreatePostLoading(true));
          dispatch(setLoading(true));
          createPostService('');
        } else {
          dispatch(setLoading(true));
          dispatch(setCreatePostLoading(true));
          createPostService(mediaType === 'video' ? postVideo.length == 0 ? postImage == 0 ? '' : postImage : postVideo  : postImage == 0 ? postVideo.length == 0 ? '' : postVideo :   postImage);
        }
      } else {
        dispatch(setCreatePostLoading(false));
        dispatch(setLoading(false));
      }
    } catch (err) {
      dispatch(setCreatePostLoading(false));
    }
  };

  const createPostService = (imagePath) => {
    try {
      let params = {};
      if (imagePath) {
        params = {
          id: _id,
          data: {
            image: imagePath,
            description: description,
            tagged_users: tagUser,
          },
        };
      } else {
        params = {
          id: _id,
          data: {
            description: description,
            image: '',
            tagged_users: tagUser,
          },
        };
      }


      dispatch(
        updatePost({
          params,
          onSuccess: (result) => {
            dispatch(setLoading(false));
            dispatch(setCreatePostLoading(false));
            Emitter.emit(Type.EMITTER.STORE_CREATE);
            navigation.goBack();
          },
          onError: (error) => {
            console.log('error>>>', error);
            dispatch(setLoading(false));
          },
        })
      );
    } catch (error) {
      dispatch(setLoading(false));
      console.log('error', error);
    }
  };

  const listHeaderComponent = () => {
    if (postData?.created_at) {
      return (
        <View
          // onPress={Keyboard.dismiss}
          // accessible={false}
          style={{
            backgroundColor: Colors.White,
            height:
              postVideo?.length == 0
                ? postImage?.length == 0
                  ? window.height * 0.8
                  : window.height * 1.2
                : window.height * 1.2,
          }}
        >
          <PostUserDetails
            item={postData || route.params.postData}
            onUserProfile={(id) => {
              onUserProfile(id);
            }}
          />
          <View
            style={{
              height: window.height * 0.1,
              padding: 12,
              backgroundColor: Colors.White,
              borderWidth: 1,
              borderRadius: 10,
              marginTop: window.height * 0.01,
            }}
          >
            <TextInput
              style={{
                ...theme.fontRegular,
                fontSize: 15,
                color: Colors.Black,
                backgroundColor: Colors.White,
              }}
              multiline={true}
              value={description}
              placeholder="What's on your mind?"
              onChangeText={(text) => onTextChange(text)}
            />
          </View>
          {istag ? (
            <View
              style={{
                marginTop:
                  Platform.OS == 'ios'
                    ? isKeyboardVisible
                      ? -window.height * 0.2
                      : 0
                    : 0,
                height: window.height * 0.5,
              }}
            >
              <FlatList
                bounces={false}
                data={tagValue}
                renderItem={renderItem}
                ListFooterComponent={
                  <>
                    {Platform.OS == 'ios' && (
                      <View
                        style={{
                          height: mxHeight * 0.5,
                        }}
                      />
                    )}
                  </>
                }
              />
            </View>
          ) : (
            <PostDescription
              userData={userData}
              item={{
                media:
                  postVideo?.length == 0
                    ? postImage?.length == 0
                      ? []
                      : [...postImage]
                    : [...postVideo],
              }}
              isEdit={true}
              deleteVideo={(data) => {
                // removeVideo(data);
                setDeleteType('video');
                setDeleteData(data);
                setIsDelete(true);
              }}
              deleteImage={(data) => {
                setDeleteType('image');
                setDeleteData(data);
                setIsDelete(true);
                // removeImage(data);
              }}
              videoPlaceHolder={postVideoImage}
              window={window}
            />
          )}

          <MediaButton
            window={window}
            updateButton={() => {
              updatePostButton();
            }}
            imagePress={() => {
              setMediaType('image');
              setVisible(true);
            }}
            videoPress={(data) => {
              setMediaType('video');
              onUploadVideo(data[0]);
            }}
          />
        </View>
      );
    } else {
      return <View />;
    }
  };
  const uploadMediaData = async (data) => {
    var getImageURI = await imageUplaod({
      file: data,
      token: userData?.access_token,
    });
    return getImageURI;
  };

  const onUploadVideo = async (videoData) => {
    const thumbnal = await generateVideoThumbnail(videoData);
    var data = 'data:image/png;base64,';
    var removeData = thumbnal.replace(data, '');
    const fileName = 'post_' + new Date().getTime() + '.png';
    const dataFile = getFileFromBase64(removeData, fileName);
    var getVideoImageURI = await uploadMediaData(dataFile);
    setPostVideoImage(getVideoImageURI.data[0]);
    var uploadVideoData = await uploadMediaData(videoData);
    let files = {
      uri: uploadVideoData.data[0],
      type: 'video',
      thumbnil: getVideoImageURI.data[0],
    };
    setPostImage([]);
    setPostVideo([files]);
  };

  const replaceTag = (data) => {
    return replaceMentionValues(data, ({ name }) => `@${name}`);
  };

  const onTextChange = (text) => {
    if (text.length == 1 && text == '@') {
      setTag(true);
      textLength = text.length;
      setTagValue(allUsers || []);
    } else if (
      text.charAt(text.length - 1) == '@' &&
      text.charAt(text.length - 2) == ' '
    ) {
      textLength = text.length;
      setTag(true);
      const before_ =
        description == '' ? '' : text.substring(0, text.length - 1);
      setLastValue(before_);
      setTagValue(allUsers || []);
    } else if (istag) {
      var afterChar = text.slice(textLength);
      var tagData = allUsers || [];
      const filteredArr = tagData.filter((val) =>
        val?.name?.toLowerCase().includes(afterChar)
      );
      setTagValue(filteredArr);
    }
    if (text.length == 0) {
      setTag(false);
    }

    if (
      text.charAt(text.length - 1) !== '@' ||
      text.charAt(text.length - 2) !== ' '
    ) {
      var newCommentText = `${postText}${text.charAt(text.length - 1)}`;
      if (!istag) {
        setPostText(newCommentText);
      }
    }
    setDescription(text);
  };

  const renderItem = ({ item, index }) => {
    if (item !== null) {
      return (
        <CustomTagView
          window={window}
          item={item}
          index={index}
          addTagData={() => addTagData({ item })}
        />
      );
    }
  };

  const addTagData = ({ item }) => {
    setDescription('');
    let newText =
      description == '@' ? `@${item.name} ` : `${lastValue} @${item.name} `;
    let newCommenttagText = `${postText} [${item.name}](${item._id}) `;
    setDescription(newText);
    setPostText(newCommenttagText);
    setTag(false);

    var oldTagValue = tagUser;
    var checkIndex = oldTagValue.findIndex((element) => element == item._id);
    if (checkIndex == -1) {
      oldTagValue.push({ user_id: item._id, name: item.name });
      setTagUser(oldTagValue);
    }
  };

  const onUserProfile = (id) => {
    if (id !== userData?.data?._id) {
      navigation.navigate(RouteName.OTHER_PROFILE, {
        userId: id,
        isPost: true,
      });
    }
  };

  const uploadCreateImageData = (imagedata) => {
    return new Promise((resolve, reject) => {
      let data = {
        imagePath: imagedata,
        mediaType: mediaType,
        isCreate: true,
      };

      dispatch(
        uploadImage({
          data,
          onSuccess: (result) => {
            if (result.data) {
              const initialArr = [];
              result.data.forEach(myFunction);
              function myFunction(item) {
                let files = {
                  uri: item,
                  type: mediaType,
                  thumbnil: postVideoImage,
                };
                initialArr.push(files);
              }
              resolve(initialArr);
            }
          },
          onError: (error) => {
            reject(error);
            dispatch(setLoading(false));
            // setPageLoading(false);
          },
        })
      );
    });
  };

  return (
    <>
      <CustomHeader
        window={window}
        activeTab={''}
        isProfile={true}
        backPress={() => navigation.goBack()}
        title={Strings.EDIT_POST}
      />
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          backgroundColor: Colors.White,
          width: window.width,
          flex: 1,
          paddingHorizontal: window.width * 0.05,
        }}
      >
        {postData?.created_at ? (
          <View
            style={{
              backgroundColor: Colors.White,
            }}
          >
            {listHeaderComponent()}
          </View>
        ) : (
          <View />
        )}
        {isVisible && (
          <CustomMediaSelectorModal
            modalVisible={isVisible}
            mediaType={mediaType}
            onRequestClose={() => {
              setVisible(false);
            }}
            selectImage={(data) => {
              setVisible(false);
              setTimeout(() => {
                setOpen(true);
              }, 800);
            }}
            setSelection={async (value) => {
              var uploadGalaryImageData = await uploadCreateImageData(value);
              setPostImage([...postImage, ...uploadGalaryImageData]);
              setVisible(false);
            }}
          />
        )}

        {open && (
          <CameraModal
            imageType={mediaType}
            showCameraModal={open}
            setCameraModal={(value) => {
              setOpen(value);
            }}
            setCameraData={async (value) => {
              setOpen(false);
              var uploadCameraData = await uploadMediaData(value);

              let files = {
                uri: uploadCameraData.data[0],
                type: mediaType,
                thumbnil: '',
              };
              // setNewPostImage([...newPostImage, files]);
              // if (postImage == !null) {
              //   setPostImage([...postImage, files]);
              // } else {
              //   setPostImage([files]);
              // }

              setPostImage([...postImage, files]);
            }}
          />
        )}
        <CustomConfirmationWebModal
          isModal={isDelete}
          setModal={(value) => {
            setIsDelete(false);
          }}
          setSelection={(value) => {
            if (value == Type.CONFIRMATION.YES) {
              setIsDelete(false);
              deleteType === 'image'
                ? removeImage(deleteData)
                : removeVideo(deleteData);
            }
          }}
          data={
            deleteType === 'image'
              ? {
                  title: Strings.IMAGE_DELETE,
                  desc: Strings.ARE_YOU_SURE_IMAGE,
                }
              : {
                  title: Strings.VIDEO_DELETE,
                  desc: Strings.ARE_YOU_SURE_VIDEO,
                }
          }
        />
      </ScrollView>
    </>
  );
};

export default EditPost;
