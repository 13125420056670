import { View, ScrollView } from 'react-native'
import React from 'react'
import Calender from '../Calender';
import Chat from '../Chat';

const CalenderChat = () => {
  return (
    <View style={{ width: '25%' }}>
          <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
            <Calender />
            <Chat />
          </ScrollView>
        </View>
  )
}

export default CalenderChat