import { View, Text, ScrollView } from 'react-native'
import React from 'react'
import Profile from '../Profile';

const HomeProfileView = ({onViewImage}) => {
  return (
    <View style={{ width: '25%' }}>
    <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
      <Profile onViewImage={onViewImage} />
    </ScrollView>
  </View>
  )
}

export default HomeProfileView