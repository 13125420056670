import {
  View,
  TouchableOpacity,
  Image,
  FlatList,
  StyleSheet,
  useWindowDimensions,
  Text,
} from 'react-native';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SafeAreaView } from 'react-native-safe-area-context';
import CustomHeader from '../../../Component/CustomHeader';
import { STAR_ICON, backIcon, dummyImage } from '../../../Resources/assets';
import Strings from '../../../Resources/Strings';
import Colors from '../../../Resources/Colors';
import {
  getCommentLikeAction,
  getPostLikeAction,
} from '../../../Redux/actions/homeAction';
import { RouteName } from '../../../Navigation/routeName';
import { CustomText } from '../../../Component/CustomComponent';
import { theme } from '../../../Util/constant';
import EndPoint from '../../../Redux/constants/EndPoint';
import CustomImageVideoDownload from '../../../Component/CustomImageVideoDownload';
import {
  getFirebaseChatOrderByUserList,
  getFirebaseUserData,
  getNewFirebaseChatListUserData,
  setFirebaseChatList,
  setFirebaseUserData,
} from '../../../Constants/FireStoremanager/FireStoremanager.web';
import { fontResize } from '../../../Util/font';
import ProfileDetail from '../../../Component/ProfileDetail/index.web';
import { fetchOtherProfile } from '../../../Redux/actions/profileAction';
import CustomLikeUserView from './CustomLikeUserView';

const LikeUserScreen = ({ navigation, route }) => {
  const { _id } = route?.params.postData;
  const dispatch = useDispatch();
  const window = useWindowDimensions();
  const [page, setPage] = useState(1);
  const [postLike, setPostLike] = useState([]);
  const [activeTab, setActiveTab] = useState('');
  const [isProfile, setIsProfile] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const [profileImageIndex, setProfileImageIndex] = useState(0);
  const [profileItem, setProfileItem] = useState([]);
  const [showProfileModal, setProfileModal] = useState(false);
  const userData = useSelector((state) => state.auth.userData);

  const { width, height } = window;
  const combinSize =
    width > height
      ? width * 0.05 + height * 0.1
      : width == height
      ? width + height
      : height * 0.05 + width * 0.1;

  useEffect(() => {
    getPostLikeData(1);
  }, []);

  const getPostLikeData = (it) => {
    const data = {
      id: _id,
      page: it,
    };
    if (route?.params.isComment) {
      dispatch(
        getCommentLikeAction({
          data,
          onSuccess: (result) => {
            if (it == 1) {
              setPostLike((perviousData) => [...result.data]);
            } else {
              setPostLike((perviousData) => [...perviousData, ...result.data]);
            }
          },
          onError: (error) => {},
        })
      );
    } else {
      dispatch(
        getPostLikeAction({
          data,
          onSuccess: (result) => {
            if (it == 1) {
              setPostLike((perviousData) => [...result.data]);
            } else {
              setPostLike((perviousData) => [...perviousData, ...result.data]);
            }
          },
          onError: (error) => {},
        })
      );
    }
  };

  const onViewImage = (data) => {
    setIsProfile(true);
    setProfileImage(data?.data);
    setProfileImageIndex(data?.index);
  };

  const onImagePress = (item) => {
    const imageUrl = item?.userDetails?.image
      ? typeof item?.userDetails?.image == 'string'
        ? EndPoint.baseAssestURL + item?.userDetails?.image
        : EndPoint.baseAssestURL + item?.userDetails?.image[0]
      : item?.image
      ? typeof item?.image == 'string'
        ? EndPoint.baseAssestURL + item?.image
        : EndPoint.baseAssestURL + item?.image[0]
      : '';

    if (imageUrl !== '') {
      onViewImage({
        data: [imageUrl],
        index: 0,
      });
    }
  };

  const createChat = async (otherUserProfile) => {
    const otherUserId = otherUserProfile?._id;

    const userExist = await getFirebaseChatOrderByUserList({
      id: userData?.data?.user_id || userData?.data?._id,
      userId: otherUserId,
    });
    const newID = userData?.data?.user_id || userData?.data?._id;
    var chatId =
      newID > otherUserId ? `${newID}${otherUserId}` : `${otherUserId}${newID}`;
    var getUserChatLists = await getNewFirebaseChatListUserData({
      id: userData?.data?.user_id || userData?.data?._id,
      chatId: chatId,
    });
    var getOtherUserChatLists = await getNewFirebaseChatListUserData({
      id: otherUserId,
      chatId: chatId,
    });
    if (userExist.length == 0) {
      const usergetExist = await getFirebaseUserData({
        id: otherUserId,
      });
      if (!usergetExist) {
        const userStoreData = {
          id: otherUserId,
          email: otherUserProfile?.email || '',
          device_token: otherUserProfile?.device_token || '',
          image:
            otherUserProfile?.image || otherUserProfile?.userinfo?.image || '',
          name: otherUserProfile?.name || '',
          role: otherUserProfile?.role || '',
          phone_number: otherUserProfile?.phone_number || '',
          status: 'offline',
          dateTime: new Date(),
        };
        const storeFirbaseData = await setFirebaseUserData({
          id: otherUserId,
          userData: userStoreData,
        });
      }
      var otherUserData = {
        id: otherUserId,
        name: otherUserProfile?.name || '',
        role: otherUserProfile?.role || '',
        image:
          otherUserProfile?.image || otherUserProfile?.userinfo?.image || '',
        status: '',
        chatId: chatId,
        message: '',
        maessageType: 'text',
        dateTime: new Date(),
      };
      var cureentUserData = {
        id: userData?.data?.user_id || userData?.data?._id,
        name: userData?.data?.name || '',
        role: userData?.data?.role || '',
        image: userData?.data?.image || '',
        status: '',
        chatId: chatId,
        message: '',
        maessageType: 'text',
        dateTime: new Date(),
      };
      if (!getUserChatLists) {
        await setFirebaseChatList({
          id: userData?.data?.user_id || userData?.data?._id,
          userData: otherUserData,
          chatUserId: otherUserId,
        });
      }
      if (!getOtherUserChatLists) {
        await setFirebaseChatList({
          id: otherUserId,
          userData: cureentUserData,
        });
      }
      navigationChat({
        chatId: chatId,
        receiverId: otherUserId,
        isOther: true,
        otherUserProfile,
      });
    } else {
      navigationChat({
        chatId: chatId,
        receiverId: otherUserId,
        isOther: true,
        otherUserProfile,
      });
    }
  };

  const navigationChat = ({
    chatId,
    receiverId,
    isOther,
    otherUserProfile,
  }) => {
    global.isChat = true;
    navigation.navigate(RouteName.CHAT_USER, {
      chatId: chatId,
      receiverId: receiverId,
      isOther: isOther,
      receiverData: {
        id: receiverId,
        name: otherUserProfile?.name || '',
        role: otherUserProfile?.role || '',
        image:
          otherUserProfile?.image || otherUserProfile?.userinfo?.image || '',
        status: '',
        chatId: chatId,
        message: '',
        maessageType: 'text',
        dateTime: new Date(),
      },
    });
  };

  const viewMoreLike = () => {
    getPostLikeData(page + 1);
    setPage(page + 1);
  };

  const fetchOtherUserProfile = ({ userId, isChat }) => {
    dispatch(
      fetchOtherProfile({
        userId,
        onSuccess: (result) => {
          if (isChat) {
            createChat(result.data);
          } else {
            setProfileModal(true);
          }
        },
        onError: (error) => {},
      })
    );
  };

  const renderItem = ({ item, index }) => {
    var isLastIndex =
      postLike.length == index + 1
        ? postLike.length % 10 == 0
          ? true
          : false
        : false;
    return (
      <CustomLikeUserView
        item={item}
        isLastIndex={isLastIndex}
        window={window}
        combinSize={combinSize}
        onViewImage={onViewImage}
        otherPress={() => {
          fetchOtherUserProfile({ userId: item?.user_id, isChat: false });
          setProfileItem({ _id: item?.user_id });
        }}
        viewMoreLike={viewMoreLike}
      />
    );
  };

  return (
    <SafeAreaView style={styles.container}>
      <CustomHeader
        window={window}
        activeTab={activeTab}
        isProfile={true}
        backPress={() => navigation.goBack()}
        title={Strings.POST_LIKES}
      />

      <CustomText
        style={{
          color: 'gray',
          borderTopWidth: 1,
          borderColor: Colors.gray2,
          paddingTop: window.width * 0.01,
        }}
      />
      <FlatList
        contentContainerStyle={{
          justifyContent: 'space-between',
          width: window.width,
        }}
        bounces={false}
        data={postLike}
        renderItem={renderItem}
        numColumns={3}
        keyExtractor={(item) => item._id}
      />

      <ProfileDetail
        item={profileItem}
        showProfileModal={showProfileModal}
        setProfileModal={setProfileModal}
        suggestionPress={() => {
        }}
        chatPress={(chatData) => {
          setProfileModal(false);
          createChat(chatData);
        }}
        onViewImage={onViewImage}
      />

      {isProfile && (
        <CustomImageVideoDownload
          isVisible={isProfile}
          setVisible={setIsProfile}
          selectedFile={profileImage}
          index={profileImageIndex}
        />
      )}
    </SafeAreaView>
  );
};
// define your styles
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.White,
  },
});
export default LikeUserScreen;
