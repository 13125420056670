import { View, TouchableOpacity } from 'react-native';
import React from 'react';
import Colors from '../../Resources/Colors';
import { CustomText } from '../CustomComponent';
import Strings from '../../Resources/Strings';
import { style } from './style';
import { fontResize } from '../../Util/font';

const CustomChatHeader = ({ onTabPress, activeTab, window }) => {
  const {width, height} = window;
  const combinSize = width > height ?  (width * 0.05) + (height * 0.1) : width  == height ? width + height  :   (height * 0.05) + (width * 0.1)
  return (
    <View>
      <View style={style.container}>
        <TouchableOpacity
          onPress={() => onTabPress(Strings.COMPANIES)}
          style={[style.buttonContainer,{height: height * 0.07}]}
        >
          <CustomText
            textColor={
              activeTab === Strings.COMPANIES
                ? Colors.Blueberry
                : Colors.PrimaryGray
            }
            fontSize={fontResize(combinSize * 0.1)}
          >
            {Strings.COMPANIES}
          </CustomText>
          {activeTab === Strings.COMPANIES ? (
            <View style={style.tabEmptyView} />
          ) : <View/>}
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => onTabPress(Strings.DIRECT_MESSAGE)}
          style={[style.buttonContainer,{height: height * 0.07,}]}
        >
          <CustomText
            textColor={
              activeTab === Strings.DIRECT_MESSAGE
                ? Colors.Blueberry
                : Colors.PrimaryGray
            }
            fontSize={fontResize(combinSize * 0.1)}
          >
            {Strings.DIRECT_MESSAGE}
          </CustomText>
          {activeTab === Strings.DIRECT_MESSAGE && (
            <View style={style.tabEmptyView} />
          )}
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => onTabPress(Strings.NOTIFICATIONS)}
          style={[style.buttonContainer,{height: height * 0.07,}]}
        >
          <CustomText
          fontSize={fontResize(combinSize * 0.1)}
            textColor={
              activeTab === Strings.NOTIFICATIONS
                ? Colors.Blueberry
                : Colors.PrimaryGray
            }
          >
            {Strings.NOTIFICATIONS}
          </CustomText>
          {activeTab === Strings.NOTIFICATIONS && (
            <View style={style.tabEmptyView} />
          )}
        </TouchableOpacity>
      </View>
      <View style={style.emptyView} />
    </View>
  );
};

export default CustomChatHeader;
