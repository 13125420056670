import { StyleSheet } from "react-native";
import Colors from "../../Resources/Colors";

const styles = StyleSheet.create({
  horizontalLine: {
    width: "100%",
    borderWidth: 0.4,
    borderColor: Colors.ShadowGray,
    alignSelf: "center",
   
  },
});
export default styles;
