import { StyleSheet } from 'react-native';
import React from 'react';
import { theme } from '../../Util/constant';
import { fontResize } from '../../Util/font';
import Colors from '../../Resources/Colors';
import { mxHeight, mxWidth } from '../../Util';

const style = StyleSheet.create({
  container: {
    paddingVertical: 10,
  },
  descriptionText: {
    ...theme.fontRegular,
    fontSize: fontResize(15),
    color: Colors.CodGray,
  },
  imageView: {
    borderRadius: 12,
    width: mxWidth * 0.9,
  },
  imageWebView: {
    height: mxWidth * 0.2,
    borderRadius: 12,
    width: mxWidth,
    alignItems: 'center',
  },
  postImage: {
    height: mxWidth * 0.9,
    width: mxWidth * 0.9,
  },
  postImageWeb: {
    height: mxWidth * 0.3,
    width: mxWidth * 0.4,
  },
  postEditImage: {
    height: mxWidth * 0.7,
    width: mxWidth * 0.9,
  },
  postEditImageWeb: {
    height: mxWidth * 0.3,
    width: mxWidth * 0.4,
  },
  postImageView: {
    height: mxWidth * 0.9,
    width: mxWidth * 0.9,
    marginVertical: 10,
    backgroundColor: Colors.White,
    shadowColor: Colors.PrimaryGray1,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 10,
    elevation: 5,
    overflow: 'hidden',
  },
  postImageViewWeb: {
    height: mxWidth * 0.4,
    width: mxWidth * 0.4,
    marginRight: mxWidth * 0.05,
    backgroundColor: Colors.White,
    shadowColor: Colors.PrimaryGray1,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 10,
    elevation: 5,
    overflow: 'hidden',
  },

  postEditImageView: {
    height: mxWidth * 0.7,
    width: mxWidth * 0.9,
    marginVertical: 10,
    backgroundColor: Colors.White,
    shadowColor: Colors.PrimaryGray1,
    shadowOffset: {
      width: 0,
      height: 2,
    },

    shadowOpacity: 0.25,
    shadowRadius: 10,
    elevation: 5,
    // overflow: 'hidden',
  },

  postEditImageViewWeb: {
    height: mxWidth * 0.45,
    width: mxWidth * 0.45,
    marginVertical: 10,
    backgroundColor: Colors.White,
    shadowColor: Colors.PrimaryGray1,
    shadowOffset: {
      width: 0,
      height: 2,
    },

    shadowOpacity: 0.25,
    shadowRadius: 10,
    elevation: 5,
    // overflow: 'hidden',
  },
  imageWidth: mxWidth * 0.9,
  postWebImage: {
    height: mxWidth * 0.2,
    width: mxWidth,

    // borderRadius: mxWidth * 0.05,
  },
  videoContainer: {
    height: mxWidth * 0.6,
    width: mxWidth * 0.9,
  },
  videoLoaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: mxWidth * 0.6,
    width: mxWidth - 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default style;
