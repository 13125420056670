import { View, Text, Dimensions, StyleSheet, useWindowDimensions } from 'react-native';
import React, { useState, useEffect } from 'react';
import Strings from '../../Resources/Strings';
import Colors from '../../Resources/Colors';

import CustomChatHeader from '../../Component/CustomChatHeader';
import ChatWebRoute from '../../Navigation/chatWebRoute';
import { RouteName } from '../../Navigation/routeName';
import { useNavigation } from '@react-navigation/native';
import { fontResize } from '../../Util/font';
import CustomSearchViewWeb from '../../Component/CustomSearchInputWeb';
import { useDispatch, useSelector } from 'react-redux';
import {
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';
import { firestore } from '../../Constants/FirebaseWeb';
import {
  CompanyChatDetails,
  DirectChatDetails,
  SelectChatDetails,
  fetchViewedNotificationAction,
  isChatDetails,
} from '../../Redux/actions/profileAction';
import ChatJobRoute from '../../Navigation/chatJobRoute';
import ConversationRow from '../../Component/ConversationRow';

const JobChat = ({}) => {
  const [activeTab, setTab] = useState(Strings.COMPANIES);
  const window = useWindowDimensions()
  const {width, height} = window;
  const navigation = useNavigation();
  const [searchText, setSearchText] = useState('');
  const [searchUser, setSearchUser] = useState([]);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const isChatDetail = useSelector((state) => state.profile.isChatDetails);
  const userData = useSelector((state) => state.auth.userData);
  const [chatUserList, setChatUserList] = useState([]);
  const dispatch = useDispatch();
  const combinSize = width > height ?  (width * 0.05) + (height * 0.1) : width  == height ? width + height  :   (height * 0.05) + (width * 0.1)

  useEffect(() => {
    getChatUserList();
  }, []);

  const getChatUserList = async () => {
    try {
      const unsub = onSnapshot(
        query(
          collection(
            firestore,
            'ChatUserList',
            userData?.data?.user_id || userData?.data?._id,
            'userlist'
          ),
          orderBy('dateTime', 'desc')
        ),
        (snapshot) => {
          const newData = [];
          const companyNewData = [];
          const listUserData = [];
          var newRow = {};
          snapshot?.docs?.forEach((doc, index) => {
            newRow = doc.data();
            newRow.message_id = doc?.id;
            if (newRow?.role !== Strings.ORGANIZATION) {
              newData.push(newRow);
            } else {
              companyNewData.push(newRow);
            }
            listUserData.push(newRow);
          });
          setChatUserList((perviousData) => [...listUserData]);
          dispatch(DirectChatDetails(newData));
          dispatch(CompanyChatDetails(companyNewData));
          setTimeout(()=>{
            getUnReadMessageCount({data:companyNewData, type: 'comapny'});
            getUnReadMessageCount({data:newData, type: 'Profestional'});
            getUnReadMessageCount({data:listUserData, type: 'all'});
          },800)
        }
      );
    } catch (error) {
      console.log('error>>>Driect', error);
    }
  };

  const getUnReadMessageCount = ({data, type}) =>{
    try{
      var newUserData = [...data];
      var newObject = {};
      const newData = [];
      const companyNewData = [];
      for (let ij = 0; ij < data.length; ij++) {
    const unsub = onSnapshot(
      query(
        collection(firestore, 'messages', data[ij].chatId, 'messages'),
        orderBy('sentDate')
      ),
      (snapshot) => {
        const listData = [];
              var chatData = {};
              var lastIndexRead = 0;
              const currentId = userData?.data?.user_id || userData?.data?._id;
              snapshot?.docs?.forEach((doc, index) => {
                chatData = doc.data();
                chatData.message_id = doc.id;
                if (chatData.senderId == currentId) {
                  if (chatData?.read == true) {
                    lastIndexRead = index;
                  }
                }
                listData.push(chatData);
              });
              var getCountUnreadMessage = listData?.filter(
                (item, index) =>
                  item?.receiverId === currentId && item?.read !== true
              );
              newObject = data[ij];
              newObject.unReadCount = getCountUnreadMessage?.length || 0;
              newUserData[ij].unReadCount = getCountUnreadMessage?.length || 0;
      }
      );
    }
    setTimeout(()=>{
      if(type == 'all'){
        setChatUserList((perviousData) => [...newUserData]);
      }
      else if(type == 'company'){
        
          dispatch(CompanyChatDetails([...newUserData]));
      }
      else {
        dispatch(DirectChatDetails([...newUserData]));
      }
      
    },)
    }
    catch(error){

    }
  }

  const selectedTab = (text) => {
    if (text === Strings.COMPANIES) {
      setTab(text);
      navigation.navigate(RouteName.COMPANIES_WEB);
    } else if (text === Strings.DIRECT_MESSAGE) {
      setTab(text);
      navigation.navigate(RouteName.DIRECT_WEB);
    } else {
      setTab(text);
      navigation.navigate(RouteName.NOTIFICATION_WEB);
    }
  };

  const onSearchText = (text) => {
    let result = chatUserList.filter((o) =>
      o.name.toLowerCase().includes(text.toLowerCase())
    );
    if (text.length > 2) {
      setSearchUser(result);
    } else {
      setSearchUser([]);
    }
    setSearchText(text)
  };

  const renderItem = ({ item, index }) => {
    return (
      <ConversationRow
      window={{height: window.height,width: window.width * 0.25}}
        data={item}
        onPressChat={() => onPressChat(item)}
        deleteChat={() => {
          deleteChatUser(item);
        }}
      />
    );
  };


  const onPressChat = (data) => {
    dispatch(
      SelectChatDetails({
        chatId: data.chatId,
        receiverId: data.id,
        receiverData: data,
        isOther: false,
      })
    );
    global.isChat = true;
    navigation.navigate(RouteName.CHAT_USER, {
      chatId: data.chatId,
      receiverId: data.id,
      receiverData: data,
      isOther: false,
    });
    setSearchUser([]);
    setSearchText('')
    dispatch(isChatDetails(true));
  };


  return (
    <View
      style={{
        height: window.height * 0.6,
        backgroundColor: Colors.White,
        marginVertical: '10%',
        borderRadius: window.height * 0.02,
        overflow: 'hidden',
      }}
    >
      {isChatDetail ? (
        <View />
      ) : (
        <>
          {/* search view */}
          <View style={styles.searchContainer}>
            <Text style={styles.conversationText}>{Strings.CONVERSATIONS}</Text>
            <CustomSearchViewWeb
            window={windex}
              placeholder={'search people'}
              onChange={(text) => onSearchText(text)}
              textinputContainer={styles.textinputContainer}
            />
          </View>

          {searchText == '' ? <CustomChatHeader
            window={window}
            activeTab={activeTab}
            onTabPress={(text) => selectedTab(text)}
          /> : <View/>}
        </>
      )}
      {searchText == '' ?  <ChatJobRoute />: <FlatList data={searchUser} renderItem={renderItem}/>}
    </View>
  );
};

const styles = StyleSheet.create({
  searchContainer: {
    paddingTop: '4%',
    // paddingVertical: '4%',
    paddingHorizontal: '5%',
  },
  conversationText: {
    fontSize: fontResize(24),
    fontFamily: 'Gilroy-Medium',
    fontWeight: '700',
    marginVertical: '5%',
  },
  textinputContainer: {
    backgroundColor: Colors.gray2,
  },
});

export default JobChat;
