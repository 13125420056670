/* eslint-disable react-native/no-inline-styles */
import { View, useWindowDimensions } from 'react-native';
import React from 'react';
import { CustomText } from '../../Component/CustomComponent';
import { style } from './style';
import Colors from '../../Resources/Colors';
import { theme } from '../../Util/constant';
import { fontResize } from '../../Util/font';

const FaqView = ({ question, answer, isView, viewAnswer, index, combinSize }) => {
  const window = useWindowDimensions();
  const {width }= window;
  return (
    <View
      style={{
        width: width * 0.5,
        paddingVertical: combinSize * 0.05,
        marginTop: combinSize * 0.1,
        borderColor: Colors.PrimaryGray4,
        borderTopWidth: index === 0 ? 0 : 1,
      }}
    >
      <View style={style.questionVw}>
        <CustomText
          style={{ width: width * 0.5, marginRight: combinSize * 0.3 }}
          fontFamily={'Gilroy-Medium'}
          fontSize={fontResize(combinSize * 0.11)}
        >
          {question}
        </CustomText>
        <CustomText
          style={{ paddingHorizontal: combinSize * 0.1 }}
          onPress={() => viewAnswer()}
          fontSize={fontResize(combinSize* 0.16)}
        >
          {isView ? '-' : '+'}
        </CustomText>
      </View>
      {isView === true && (
        <CustomText
          style={{
            ...theme.fontRegular,
            marginRight: combinSize * 0.3,
          }}
          textColor={Colors.DimGray}
          marginTop={combinSize * 0.1}
          fontSize={fontResize(combinSize * 0.09)}
        >
          {answer}
        </CustomText>
      )}
    </View>
  );
};

export default FaqView;
