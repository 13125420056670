import {
  View,
  Text,
  TouchableOpacity,
  Image,
  TextInput,
  Share,
  FlatList,
  Alert,
  ActivityIndicator,
  Linking,
} from 'react-native';
import React, { useState, useCallback, useRef } from 'react';

import {
  messageIcon,
  shareIcon,
  moreIcon,
  thumbsUpIcon,
  ActivethumbsUpIcon,
  EyeClose,
  CancelFilled,
  ReportFlag,
  BlockUser,
  PostPlaceholder,
  STAR_ICON,
  BIN_ICON,
  PLAY_ICON,
  PAUSE_ICON,
} from '../../Resources/assets';
import { getDateTime, theme } from '../../Util/constant';
import Colors from '../../Resources/Colors';
import { dummyImage, dummyPost } from '../../Resources/assets';
import EndPoint from '../../Redux/constants/EndPoint';
import moment from 'moment';
import ViewComments from '../../Screen/ViewComments';
import MoreModal from './more/index.web';
import Type from '../../Constants/Type/type';
import { RouteName } from '../../Navigation/routeName';
import Strings from '../../Resources/Strings';
import CustomMoreComponentWeb from './more/index.web';
import DontSeePostWeb from '../../Screen/More/DontSeePost/index.web';
import CustomConfirmationWebModal from '../CustomConfirmationModal/index.web';
import { unFollowUserAction } from '../../Redux/actions/profileAction';
import { setLoading } from '../../Redux/actions/authAction';
import { useDispatch, useSelector } from 'react-redux';
import { blockuser, deletePostAction } from '../../Redux/actions/homeAction';
import Emitter from '../../Util/eventEmitter';
import UnlockFullAccess from '../UnlockFullAccess';
import ReactPlayer from 'react-player';
import { fontResize } from '../../Util/font';
import Hyperlink from 'react-native-hyperlink';
import { EditBlack } from '../../Resources/assets/ProfileAssets';
import CustomCrossButton from '../CustomCrossButton/index.web';

const Feed = ({
  item,
  onLike,
  onComment,
  onHide,
  userId,
  onSubmitComment,
  navigation,
  onUserProfile,
  window,
  setUpdate,
  userInfo,
  index,
  inViewPort,
  onViewImage,
  isPlayVideo,
  pressMediaIcon,
  isSeeMore,
  seePress,
}) => {
  const [isSeePost, setSeePost] = useState(false);
  const [isReportPost, setReportPost] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [hideModal, sethideModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [showUnfollow, setShowUnfollow] = useState(false);
  const [hideType, setHideType] = useState('');
  const [showComments, setShowComments] = useState(false);
  const [showPostHide, setShowPostHide] = useState(false);
  const [numberOfLine, setNumberOfLine] = useState(0);
  const toolTipRef = useRef(null);
  const [isBuffering, setBuffering] = useState(true);
  const { width, height } = window;
  const [isProfile, setIsProfile] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const [profileImageIndex, setProfileImageIndex] = useState(0);
  const [showNumber, setShowNumber] = useState(22);

  const projectInfo = item;

  const userData = useSelector((state) => state.auth.userData);
  const type = userData?.data?.role
    ? userData?.data?.role
    : Strings.PROFESSIONAL;

  const id = item?.user_id;

  var formatted = getDateTime(
    item?.updated_at ? item?.updated_at : item?.created_at
  );
  const dispatch = useDispatch();

  const [seePostData, setSeePostData] = useState([
    {
      id: 1,
      title: Strings.MORE.WHY_DONT_SEE,
      type: Type.REPORT.TITLE,
    },
    {
      id: 2,
      title: Strings.MORE.YOUR_FEEDBACK_WILL_HELP_US,
      type: Type.REPORT.SUBTITLE,
    },
    {
      id: 3,
      title: Strings.FEEDBACK_POST.NOT_INTERESTED_IN_AUTHOR,
    },
    {
      id: 4,
      title: Strings.FEEDBACK_POST.NOT_INTERESTED_IN_TOPIC,
    },
    {
      id: 5,
      title: Strings.FEEDBACK_POST.I_SEEN_TOO_SAME_POST,
    },
    {
      id: 6,
      title: Strings.FEEDBACK_POST.NOT_INTERESTED_IN_AUTHOR,
    },
    {
      id: 7,
      title: Strings.FEEDBACK_POST.I_SEEN_POST_BEFORE,
    },
    {
      id: 8,
      title: Strings.FEEDBACK_POST.OLD_POST,
    },
    {
      id: 9,
      title: Strings.FEEDBACK_POST.SOMETHING_ELSE,
    },
    {
      id: 10,
      title: Strings.FEEDBACK_POST.IF_THINK_THIS_GOES_AGAINST_PROFESSION,
      type: Type.REPORT.NOTE,
    },
    {
      id: 11,
      title: Strings.FEEDBACK_POST.REPORT_THIS_POST,
      type: Type.REPORT.REPORT_POST,
    },
  ]);

  const [reportData, setReportData] = useState([
    {
      id: 1,
      title: Strings.MORE.WHY_ARE_YOU_REPORTING_THIS,
      type: Type.REPORT.TITLE,
    },
    {
      id: 2,
      title: Strings.MORE.WHY_ARE_YOU_REPORTING_THIS_DESC,
      type: Type.REPORT.SUBTITLE,
    },
    {
      id: 3,
      title: Strings.REPORT_POST.SUSPICIOUS_SPAM_FAKE,
      type: Type.REPORT.OPTION,
    },
    {
      id: 4,
      title: Strings.REPORT_POST.HATEFUL_SPEECH,
      type: Type.REPORT.OPTION,
    },
    {
      id: 5,
      title: Strings.REPORT_POST.PHYSICAL_HARM,
      type: Type.REPORT.OPTION,
    },
    {
      id: 6,
      title: Strings.REPORT_POST.ADULT_CONTENT,
      type: Type.REPORT.OPTION,
    },
    {
      id: 7,
      title: Strings.REPORT_POST.DEFAMATION,
      type: Type.REPORT.OPTION,
    },
  ]);

  const [moreData, setMoreData] = useState([
    {
      title: Strings.MORE.I_DONT_WANT_TO_SEE_POST,
      icon: EyeClose,
    },
    {
      title: `${Strings.MORE.UNFOLLOW} ${item?.user?.name}`,
      icon: CancelFilled,
    },
    {
      title: Strings.MORE.REPORT_POST,
      icon: ReportFlag,
    },
    {
      title: Strings.MORE.BLOCK_USER,
      icon: BlockUser,
    },
  ]);

  const onTextLayout = useCallback((e) => {
    setNumberOfLine(e.nativeEvent.lines.length);
  }, []);

  function empty(str) {
    if (str == '') {
      return true;
    } else {
      return false;
    }
  }

  const unfollowUser = () => {
    try {
      dispatch(setLoading(true));
      const params = {
        follower_id: item?.user?._id,
      };
      dispatch(
        unFollowUserAction({
          params,
          onSuccess: (result) => {
            setShowMore(false);
            dispatch(setLoading(false));
            Emitter.emit(Type.EMITTER.STORE_CREATE);
            setUpdate({ type: true, name: item?.user?.name });
          },
          onError: (error) => {
            dispatch(setLoading(false));
          },
        })
      );
    } catch (err) {
      dispatch(setLoading(false));
    }
  };

  const blockUser = () => {
    let params = {
      block_user_id: item?.user?._id,
      status: 'block',
    };
    dispatch(
      blockuser({
        params,
        onSuccess: (result) => {
          setShowMore(false);
          Emitter.emit(Type.EMITTER.STORE_CREATE);
          setUpdate({ type: true, name: item?.user?.name });
        },
        onError: (error) => {
          dispatch(setLoading(false));
        },
      })
    );
  };

  // delete post by user

  const deletePost = () => {
    const { _id } = item;
    dispatch(
      deletePostAction({
        _id,
        onSuccess: (result) => {
          Emitter.emit(Type.EMITTER.STORE_CREATE);
          setUpdate();
          // setPostData(result?.data[0]);
        },
        onError: (error) => {},
      })
    );
  };

  const createImageData = (data) => {
    var newImageData = [];
    for (let i = 0; i < data.data.length; i++) {
      var newObject = EndPoint.baseAssestURL + data.data[i].uri;
      newImageData.push(newObject);
    }

    onViewImage({ data: newImageData, index: data?.index || 0 });
  };

  const renderMoreItem = ({ item, index }) => {
    return (
      <CustomMoreComponentWeb
        window={window}
        data={item}
        onClick={() => {
          switch (index) {
            case 0: {
              if (id !== userId) {
                setShowMore(false);
                setSeePost(true);
              } else {
                setShowMore(false);
                // navigation.navigate(RouteName.POST_DETAILS, { postData: item, isEdit: true });
                navigation.navigate(RouteName.POST_EDIT, {
                  postData: projectInfo,
                });
                // deletePostPopup();
              }

              break;
            }
            case 1: {
              if (id !== userId) {
                setShowMore(false);
                setShowUnfollow(true);
                setModalType(Type.MORE.UNFOLLOW);
              } else {
                setShowMore(false);
                setShowUnfollow(true);
                setModalType(Type.MORE.DELETE_POST);
              }

              break;
            }
            case 2: {
              setShowMore(false);
              setReportPost(true);
              break;
            }
            case 3: {
              setShowMore(false);
              setShowUnfollow(true);
              setModalType(Type.MORE.BLOCK);
              break;
            }
          }
        }}
      />
    );
  };

  let isId = item?.like.findIndex((element) => element.user_id == userId);

  const renderImageItem = (it, i, index) => {
    if (it?.type == 'video') {
      const isVideoPlay =
        index == isPlayVideo.index ? isPlayVideo.isPlay : false;
      return (
        <View>
          {isPlayVideo.isVideo ? (
            <ReactPlayer
              url={EndPoint.baseAssestURL + it?.uri}
              width={`${width * 0.45}px`}
              height={`${width * 0.35}px`}
              controls={false}
              loop={true}
              playing={isVideoPlay}
              onBuffer={(res) => {
                setBuffering(true);
              }}
              onBufferEnd={(res) => {
                console.log('bufferingEnd>>>', res);
                setBuffering(false);
              }}
            />
          ) : (
            <Image
              resizeMode={'cover'}
              style={{
                width: width * 0.45,
                height: width * 0.35,
                borderRadius: 12,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              defaultSource={PostPlaceholder}
              source={
                it?.thumbnil
                  ? {
                      uri: EndPoint.baseAssestURL + it?.thumbnil,
                    }
                  : PostPlaceholder
              }
            />
          )}

          <View
            style={{
              zIndex: 1,
              height: width * 0.35,
              width: width * 0.45,
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            {isVideoPlay ? (
              <CustomCrossButton
                onPress={() => {
                  pressMediaIcon({
                    isPlay: false,
                    index: index,
                    isVideo: true,
                  });
                }}
                imageSrc={PAUSE_ICON}
                imageStyle={{ width: width * 0.025, height: width * 0.025 }}
              />
            ) : (
              <CustomCrossButton
                onPress={() => {
                  pressMediaIcon({ isPlay: true, index: index, isVideo: true });
                }}
                imageSrc={PLAY_ICON}
                imageStyle={{ width: width * 0.025, height: width * 0.025 }}
              />
            )}
          </View>
        </View>
      );
    } else {
      if (item?.media.length > 4) {
        if (i < 4) {
          return (
            <View>
              <TouchableOpacity
                onPress={() => {
                  createImageData({ data: item?.media, index: i });
                }}
              >
                <Image
                  resizeMode={item?.media.length > 2 ? 'cover' : 'contain'}
                  style={{
                    height:
                      item?.media.length < 3
                        ? width * 0.3
                        : i == 0
                        ? width * 0.3
                        : width * 0.18,
                    width:
                      item?.media.length == 2
                        ? width * 0.224
                        : item?.media.length == 1
                        ? width * 0.45
                        : item?.media.length > 2
                        ? i == 0
                          ? width * 0.45
                          : item?.media.length == 3
                          ? width * 0.224
                          : width * 0.148
                        : width * 0.148,
                    marginBottom: 10,
                    backgroundColor:
                      item?.media.length == 4
                        ? Colors.Transparent
                        : i == 3
                        ? Colors.lightBlack
                        : Colors.Transparent,
                  }}
                  defaultSource={PostPlaceholder}
                  source={
                    it?.type == 'image'
                      ? {
                          uri: EndPoint.baseAssestURL + it?.uri,
                        }
                      : it?.type == undefined
                      ? it
                        ? {
                            uri: EndPoint.baseAssestURL + it,
                          }
                        : PostPlaceholder
                      : PostPlaceholder
                  }
                />
              </TouchableOpacity>
              {i == 3 ? (
                <Text
                  style={{
                    position: 'absolute',
                    top: width * 0.065,
                    left: width * 0.05,
                    color: Colors.White,
                    fontSize: fontResize(width * 0.04),
                  }}
                >
                  {`+${item?.media.length - 4}`}
                </Text>
              ) : <View/>}
            </View>
          );
        }
      } else {
        return (
          <TouchableOpacity
            onPress={() => {
              createImageData({ data: item?.media, index: i });
            }}
          >
            <Image
              resizeMode={item?.media.length > 2 ? 'cover' : 'contain'}
              style={{
                height:
                  item?.media.length < 3
                    ? width * 0.3
                    : i == 0
                    ? width * 0.3
                    : width * 0.18,
                width:
                  item?.media.length == 2
                    ? width * 0.224
                    : item?.media.length == 1
                    ? width * 0.45
                    : item?.media.length > 2
                    ? i == 0
                      ? width * 0.45
                      : item?.media.length == 3
                      ? width * 0.224
                      : width * 0.14
                    : width * 0.14,
                marginBottom: 15,
              }}
              defaultSource={PostPlaceholder}
              source={
                it?.type == 'image'
                  ? {
                      uri: EndPoint.baseAssestURL + it?.uri,
                    }
                  : it?.type == undefined
                  ? it
                    ? {
                        uri: EndPoint.baseAssestURL + it,
                      }
                    : PostPlaceholder
                  : PostPlaceholder
              }
            />
          </TouchableOpacity>
        );
      }
    }
  };

  const likeButtonPress = (item) => {
    navigation.navigate(RouteName.POST_LIKE_USER, {
      postData: item,
      isComment: false,
    });
  };

  const checkChar = ({ firstname, lastname }) => {
    if (item?.tagged_users) {
      var firstLetter = firstname.split('');
      var lastLetter = lastname.split('');
      if (firstLetter[0] !== '@') {
        return false;
      } else {
        if (lastLetter[0] == '@') {
          return false;
        } else {
          if (item?.tagged_users[0]?.name) {
            var afterChar = firstname.slice(1);
            var margeChar = `${afterChar} ${lastname}`;
            const filteredArr = item?.tagged_users?.filter((val) =>
              val?.name?.toLowerCase().includes(margeChar.toLowerCase())
            );
            if (filteredArr.length == 0) {
              return false;
            } else {
              return true;
            }
          } else {
            return false;
          }
        }
      }
    } else {
      return false;
    }
  };

  const viewUserProfile = (name) => {
    if (item.tagged_users[0]?.name) {
      var newLetter = name.split('');

      if (newLetter[0] == '@') {
        var afterChar = name.slice(1);
        var filteredArr = item?.tagged_users.filter((val) =>
          val?.name?.toLowerCase().includes(afterChar.toLowerCase())
        );
        if (filteredArr.length !== 0) {
          onUserProfile(filteredArr[0]?.user_id || filteredArr[0]?.id);
        }
      } else {
        var filteredCharArr = item.tagged_users.filter((val) =>
          val?.name?.toLowerCase().includes(name.toLowerCase())
        );
        if (filteredCharArr.length != -1) {
          onUserProfile(filteredCharArr[0]?.user_id || filteredCharArr[0]?.id);
        }
      }
    } else {
      if (item.tagged_users.length == 1) {
        onUserProfile(item?.tagged_users[0]?.user_id || item?.tagged_users[0]);
      }
    }
  };

  return (
    <View
      style={{
        paddingVertical: 12,
        paddingLeft: 12,
        paddingRight: 12,
        flex: 1,
      }}
    >
      {/* {type == 'Professional' && !userInfo?.gold_member && index == 3 && (
        <UnlockFullAccess
          onUnlock={() => {
            navigation.navigate(RouteName.SUBSCRIPTIONS);
          }}
          OnCancel={() => {}}
        />
      )} */}
      <TouchableOpacity
        onPress={() => {
          navigation.navigate(RouteName.POST_DETAILS, { postData: item });
        }}
      >
        <View style={{ flexDirection: 'row' }}>
          {/* // User Avatar */}
          <TouchableOpacity
            style={{
              borderRadius: width * 0.2,
              height: '8vh',
              width: '8vh',
              borderColor: Colors.yellow[400],
              borderWidth: 0.5,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={() => {
              createImageData({ data: [{ uri: item?.user?.image }], index: 0 });
            }}
          >
            <Image
              style={{
                height: '8vh',
                width: '8vh',
                borderRadius: width * 0.2,
              }}
              source={
                item?.user?.image
                  ? { uri: EndPoint.baseAssestURL + item?.user?.image }
                  : dummyImage
              }
            />
          </TouchableOpacity>
          {/* // User Info */}
          <View style={{ justifyContent: 'space-around', paddingLeft: 12 }}>
            <Text
              style={{ ...theme.fontBold, fontSize: 15, color: Colors.Black }}
            >
              {' '}
              {item?.user?.name || ''}
              {item?.user?.gold_member ? (
                <Image
                  source={STAR_ICON}
                  style={{
                    width: fontResize(13),
                    height: fontResize(13),
                    marginLeft: 2,
                  }}
                />
              ) : (
                ''
              )}
            </Text>
            <Text
              style={{
                ...theme.fontRegular,
                fontSize: 12,
                color: Colors.CodGray,
              }}
            >
              {item?.user?.skill || ''}
            </Text>
            <Text
              style={{
                ...theme.fontRegular,
                fontSize: 11,
                color: Colors.CodGray,
              }}
            >
              {formatted}
            </Text>
          </View>
        </View>
        <Hyperlink
          onPress={(url, text) => Linking.openURL(url)}
          linkStyle={{ color: Colors.Blueberry }}
        >
          {item?.tagged_users ? (
            item?.tagged_users.length > 0 ? (
              <View
                style={{
                  flexWrap: 'wrap',
                  width: width * 0.45,
                  flexDirection: 'row',
                  marginTop: 10,
                  marginBottom: 5,
                }}
              >
                {item?.description?.split(' ').map((it, i) => {
                  if (it !== '') {
                    var firstChar = it.split('');

                    if (i < showNumber) {
                      return (
                        <Text
                          disabled={
                            firstChar[0] == '@'
                              ? false
                              : !checkChar({
                                  firstname:
                                    item?.description.split(' ')[
                                      i == 0 ? i : i - 1
                                    ],
                                  lastname: it,
                                })
                          }
                          onPress={() => {
                            viewUserProfile(it);
                          }}
                          style={{
                            ...theme.fontRegular,
                            fontSize: fontResize(15),
                            color:
                              firstChar[0] == '@'
                                ? Colors.Blueberry
                                : checkChar({
                                    firstname:
                                      item?.description.split(' ')[
                                        i == 0 ? i : i - 1
                                      ],
                                    lastname: it,
                                  })
                                ? Colors.Blueberry
                                : Colors.CodGray,
                            marginLeft: 5,
                          }}
                        >
                          {it.replace(/   /g, '')}
                        </Text>
                      );
                    } else if (i == 22) {
                      return (
                        <TouchableOpacity
                          onPress={() => {
                            setShowMore(!showMore);
                            setShowNumber(!showMore ? 90000000000 : 21);
                          }}
                        >
                          <Text style={{ color: Colors.Blueberry }}>
                            {'  ...See More'}
                          </Text>
                        </TouchableOpacity>
                      );
                    }
                  } else {
                    <Text> </Text>;
                  }
                })}

                {item?.description?.split(' ').length > 24 ? showMore ? (
                  <TouchableOpacity
                    onPress={() => {
                      setShowMore(!showMore);
                      setShowNumber(!showMore ? 90000000000 : 21);
                    }}
                  >
                    <Text style={{ color: Colors.Blueberry }}>
                      {'  ...See Less'}
                    </Text>
                  </TouchableOpacity>
                ) : <View/>: <View/>}
              </View>
            ) : (
              <View style={{ paddingVertical: 12 }}>
                <Text
                  onTextLayout={onTextLayout}
                  numberOfLines={showMore ? undefined : 3}
                  style={{
                    ...theme.fontRegular,
                    fontSize: 15,
                    color: Colors.CodGray,
                  }}
                >
                  {item?.description || ''}
                </Text>
                {numberOfLine >= 3 ? (
                  <TouchableOpacity onPress={() => setShowMore(!showMore)}>
                    <Text style={{ color: Colors.Blueberry }}>
                      {!showMore ? '...See More' : '...See Less'}
                    </Text>
                  </TouchableOpacity>
                ): <View/>}
              </View>
            )
          ) : (
            <View style={{ paddingVertical: 12 }}>
              <Text
                onTextLayout={onTextLayout}
                numberOfLines={showMore ? undefined : 3}
                style={{
                  ...theme.fontRegular,
                  fontSize: 15,
                  color: Colors.CodGray,
                }}
              >
                {item?.description || ''}
              </Text>
              {numberOfLine >= 3 ? (
                <TouchableOpacity onPress={() => setShowMore(!showMore)}>
                  <Text style={{ color: Colors.Blueberry }}>
                    {!showMore ? '...See More' : '...See Less'}
                  </Text>
                </TouchableOpacity>
              ): <View/>}
            </View>
          )}
        </Hyperlink>

        {!empty(item?.media) ? (
          <View
            style={{
              width: width * 0.45,
              // height: '33vh',
              borderRadius: 12,
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {typeof item?.media == 'string' ? (
              <TouchableOpacity
                onPress={() => {
                  createImageData({ data: [{ uri: item?.media }], index: 0 });
                }}
              >
                <Image
                  resizeMode={'contain'}
                  style={{
                    width: width * 0.45,
                    height: '33vh',
                    borderRadius: 12,
                    resizeMode: 'contain',
                    borderWidth: 1,
                  }}
                  defaultSource={PostPlaceholder}
                  source={
                    item?.media
                      ? {
                          uri: EndPoint.baseAssestURL + item?.media,
                        }
                      : PostPlaceholder
                  }
                />
              </TouchableOpacity>
            ) : (
              item?.media.map((it, i) => renderImageItem(it, i, index))
            )}
          </View>
        ): <View/>}

        <View
          style={{
            flexDirection: 'row',
            paddingVertical: 12,
            justifyContent: 'space-between',
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <Text
              style={{
                ...theme.fontRegular,
                fontSize: 12,
                color: '#777E90',
              }}
              onPress={() => {
                navigation.navigate(RouteName.POST_LIKE_USER, {
                  postData: item,
                  isComment: false,
                });
              }}
            >
              {item?.likes ? `${item?.likes || ''} Likes` : 'No Likes'}
            </Text>

            <Text
              style={{
                ...theme.fontRegular,
                fontSize: 12,
                color: '#777E90',
                paddingHorizontal: 12,
              }}
            >
              {item?.comment ? item?.comment?.length === 0
                ? 'No Comment'
                : `${item?.comment?.length || ''} Comments` : 'No Comment'}
            </Text>
          </View>
          <Text
            style={{
              ...theme.fontRegular,
              fontSize: 12,
              color: '#777E90',
            }}
          >
            {/* 3 Shares */}
            {item?.share ? `${item?.share} Share` : `${0} Share`}
          </Text>
        </View>
        <View style={{ backgroundColor: '#777E90', height: 0.4 }} />
        <View
          style={{
            flexDirection: 'row',
            paddingVertical: 12,
            paddingHorizontal: 12,
            justifyContent: 'space-between',
          }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
            <TouchableOpacity
              onPress={() => onLike(item?._id)}
              style={{ flexDirection: 'row', alignItems: 'center' }}
            >
              <Image
                resizeMode="contain"
                style={{ height: 22, width: 22 }}
                source={isId == -1 ? thumbsUpIcon : ActivethumbsUpIcon}
              />
              <Text
                style={{
                  ...theme.fontRegular,
                  fontSize: 12,
                  color: '#777E90',
                  paddingLeft: 6,
                }}
              >
                Like
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => {
                setShowComments(!showComments);
              }}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingLeft: 12,
              }}
            >
              <Image
                resizeMode="contain"
                style={{ height: 22, width: 22 }}
                source={messageIcon}
              />
              <Text
                style={{
                  ...theme.fontRegular,
                  fontSize: 12,
                  color: '#777E90',
                  paddingLeft: 6,
                }}
              >
                Comment
              </Text>
            </TouchableOpacity>
          </View>
          <View style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
            <View style={{ flexDirection: 'row' }}>
              <TouchableOpacity
                onPress={() => {
                  const shareOptions = {
                    title: item?.description || '',
                    failOnCancel: false,
                    urls: [EndPoint.baseAssestURL + item?.user?.image],
                  };
                  Share.share({
                    message: item?.description || '',
                    url: EndPoint.baseAssestURL + item?.user?.image,
                    title: item?.description || '',
                  })
                    .then((result) => {})
                    .catch((errorMsg) => console.log(errorMsg));
                }}
                style={{ flexDirection: 'row', alignItems: 'center' }}
              >
                <Image
                  resizeMode="contain"
                  style={{ height: 22, width: 22 }}
                  source={shareIcon}
                />
                <Text
                  style={{
                    ...theme.fontRegular,
                    fontSize: 12,
                    color: '#777E90',
                    paddingLeft: 6,
                  }}
                >
                  Share
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingLeft: 12,
                }}
                onPress={() => {
                  setShowMore(!showMore);
                }}
              >
                <Image
                  resizeMode="contain"
                  style={{ height: 22, width: 22 }}
                  source={moreIcon}
                />
              </TouchableOpacity>
            </View>
            {showMore ? (
              <View
                style={{
                  alignSelf: 'center',
                  marginTop: 10,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  shadowColor: Colors.gray2,
                  backgroundColor: Colors.White,
                  padding: 20,
                  overflow: 'hidden',
                  shadowRadius: 20,
                  shadowOpacity: 1,
                  borderRadius: 10,
                  flexDirection: 'column',
                  // position: 'absolute',
                  // bottom: -30,
                  // // right: 0,
                }}
              >
                <FlatList
                  bounces={false}
                  data={
                    item?.user_id !== userId
                      ? moreData
                      : [
                          {
                            title: Strings.MORE.EDIT_POST,
                            icon: EditBlack,
                          },
                          {
                            title: Strings.MORE.DELETE_POST,
                            icon: BIN_ICON,
                          },
                        ]
                  }
                  keyExtractor={(item) => item?._id}
                  renderItem={renderMoreItem}
                />
              </View>
            ): <View/>}
          </View>
        </View>
      </TouchableOpacity>
      {showComments ? (
        <ViewComments
          comments={item?.comment}
          postId={item?._id}
          onSubmitComment={(postId, textDescription, tagUser) =>
            onSubmitComment({ postId: item?._id, textDescription, tagUser })
          }
          onUserProfile={onUserProfile}
          viewCommentLike={() => {
            likeButtonPress(item);
          }}
        />
      ) : <View/>}

      {isSeePost ||
        (isReportPost && (
          <DontSeePostWeb
            showSeePost={isSeePost || isReportPost}
            setShowSeePost={() => {
              setSeePost(false);
              setReportPost(false);
              setShowMore(false);
            }}
            setPostResult={(value) => {
              setShowMore(false);
              setUpdate({ type: isSeePost, name: item?.user?.name });
            }}
            postData={item}
            isSeePost={isSeePost ? true : false}
            data={isSeePost ? seePostData : reportData}
            title={isSeePost ? Strings.MORE.DONT_SEE_POST : Strings.MORE.REPORT}
          />
        ))}

      <CustomConfirmationWebModal
        isModal={showUnfollow}
        setModal={(value) => {
          setShowUnfollow(false);
          setShowMore(false);
        }}
        setSelection={(value) => {
          if (value == Type.CONFIRMATION.YES) {
            modalType == Type.MORE.DELETE_POST
              ? deletePost()
              : modalType == Type.MORE.UNFOLLOW
              ? unfollowUser()
              : blockUser();
          }
        }}
        data={
          modalType == Type.MORE.DELETE_POST
            ? {
                title: Strings.MORE.DELETE_POST,
                desc: Strings.ARE_YOU_SURE_TO_DELETE_POST,
              }
            : modalType == Type.MORE.UNFOLLOW
            ? {
                title: Strings.UNFOLLOW_TITLE,
                desc: `${Strings.ARE_YOU_SURE_TO_UNFOLLOW} ${
                  item?.user?.name || ''
                }?`,
              }
            : {
                title: Strings.BLOCK_TITLE,
                desc: `${Strings.ARE_YOU_SURE_TO_BLOCK} ${
                  item?.user?.name || ''
                }?`,
              }
        }
      />

      {isSeeMore ? (
        <Text
          onPress={() => {
            seePress();
          }}
          style={{
            alignSelf: 'flex-end',
            fontSize: fontResize(width * 0.008 + height * 0.008),
            textDecoration: 'underline',
            ...theme.fontBold,
            color: Colors.Blueberry,
            marginTop: width * 0.02 + height * 0.02,
          }}
        >
          {'See more'}
        </Text>
      ) : (
        <View />
      )}
    </View>
  );
};
export default Feed;
