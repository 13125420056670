import { useState, useEffect } from 'react';
import { FlatList, useWindowDimensions, View } from 'react-native-web';
import { CustomText } from '../../Component/CustomComponent';
import Colors from '../../Resources/Colors';
import Strings from '../../Resources/Strings';
import { fontResize } from '../../Util/font';
import FaqView from './FaqView.web';
import { fetchFaqData } from '../../Redux/actions/profileAction';
import { useDispatch } from 'react-redux';

const Faq = ({ navigation }) => {
  const window = useWindowDimensions();
  const [faqData, setFaqData] = useState([]);
  const dispatch = useDispatch();
  const { width, height } = window;
  const combinSize = width > height ?  (width * 0.05) + (height * 0.1) : width  == height ? width + height  :   (height * 0.05) + (width * 0.1)

  useEffect(() => {
    getFaqList(); 
  }, []);

  const getFaqList = () => {
    dispatch(
      fetchFaqData({
        onSuccess: (result) => {
          setFaqData(result);
        },
        onError: (error) => {
          console.log('error', error);
        },
      })
    );
  };

  const renderItem = ({ item, index }) => {
    return (
      <FaqView
      combinSize={combinSize}
        question={item.title}
        answer={item.description}
        isView={item.isView}
        index={index}
        viewAnswer={() => {
          var updateData = [...faqData];
          updateData[index].isView = !item.isView;
          setFaqData(updateData);
        }}
      />
    );
  };
  return (
    <View
      style={{
        width: width,
        height: height * 0.8,
        paddingVertical: height * 0.01,
        paddingHorizontal: width * 0.2,
        backgroundColor: Colors.White,
      }}
    >
      <CustomText
        style={{
          width: width,
          paddingVertical: combinSize * 0.1,
        }}
        textAlign={'left'}
        fontFamily={'Gilroy-bold'}
        fontSize={fontResize(combinSize * 0.19)}
      >
        {Strings.FAQ}
      </CustomText>
      <View
        style={{
          flex: 1,
          paddingVertical: combinSize * 0.2,
        }}
      >
        <CustomText
          marginTop={combinSize * 0.1}
          fontSize={fontResize(combinSize * 0.14)}
          fontFamily={'Gilroy-SemiBold'}
          textAlign={'left'}
        >
          {Strings.HOW_CAN_YOU}
        </CustomText>

        <FlatList
          showsVerticalScrollIndicator={false}
          data={faqData}
          renderItem={renderItem}
          contentContainerStyle={{
            flexGrow: 1,
          }}
        />
      </View>
    </View>
  );
};

export default Faq;
