
import React from 'react';
import ImageViewer from 'react-simple-image-viewer';

const CustomImageVideoDownload = ({
  setVisible,
  isVisible,
  selectedFile,
  index = 0,
}) => {
console.log("selectedFile>>>", selectedFile)
  return (
    <div>
    <ImageViewer
      src={selectedFile}
      currentIndex={index}
      disableScroll={ true }
      // visible={isVisible}
      closeOnClickOutside={ true }
      onClose={() => setVisible(false)}
    />
    </div>
  );
};

export default CustomImageVideoDownload;
