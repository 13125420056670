import { View, Text, TouchableOpacity, Image } from 'react-native';
import React from 'react';
import {
  CAMERA_VIDEO_ICON,
  White_Gallery,
  White_Video,
  activityGalleryIcon,
} from '../../../Resources/assets';
import style from './style';
import Colors from '../../../Resources/Colors';
import { fontResize } from '../../../Util/font';
import { theme } from '../../../Util/constant';
import { useDropzone } from 'react-dropzone';

const MediaButton = ({ updateButton, imagePress, videoPress, window }) => {
  const { width, height } = window;
  const combinSize =
    width > height
      ? width * 0.05 + height * 0.1
      : width == height
      ? width + height
      : height * 0.05 + width * 0.1;


      const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        accept: { 'video/mp4': ['.mp4', '.MP4'] },
        onDrop: (acceptedFiles) => {
          videoPress(
            acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
        },
      });


  return (
    <View
      style={{
        backgroundColor: Colors.White,
        width: width * 0.9,
        paddingHorizontal: width * 0.05,
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'absolute',
        bottom: combinSize * 0.3,
      }}
    >
      <View
        style={{
          backgroundColor: Colors.White,
          width: width * 0.9,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TouchableOpacity
          style={{
            backgroundColor: Colors.White,
            width: width * 0.4,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            paddingVertical: combinSize * 0.2,
            borderRadius: combinSize * 0.1,
          }}
          onPress={imagePress}
        >
          <Image
            style={{ height: combinSize * 0.3, width: combinSize * 0.3 }}
            source={activityGalleryIcon}
          />
          <Text
            style={{
              ...theme.fontBold,
              fontSize: fontResize(combinSize * 0.2),
              color: Colors.lightGray,
              marginLeft: combinSize * 0.1,
            }}
          >
            {'Add image'}
          </Text>
        </TouchableOpacity>
        <View style={{width: width * 0.2,marginHorizontal: width * 0.1}}>
        <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
        <View
          style={{
            width: width * 0.2,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            paddingVertical: combinSize * 0.2,
            borderRadius: combinSize * 0.1,
            
            
          }}
          
        >
          <Image
            style={{ height: combinSize * 0.3, width: combinSize * 0.3 }}
            source={CAMERA_VIDEO_ICON}
          />
          <Text
            style={{
              ...theme.fontBold,
              fontSize: fontResize(combinSize * 0.2),
              color: Colors.lightGray,
              marginLeft: combinSize * 0.1,
            }}
          >
            {'Add Video'}
          </Text>
        </View>
        </div>
        </View>
      </View>
      <TouchableOpacity
        onPress={updateButton}
        style={{
          width: width * 0.9,
          backgroundColor: Colors.Blueberry,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          paddingVertical: combinSize * 0.12,
          borderRadius: combinSize * 0.1,
        }}
      >
        <Text
          style={{
            ...theme.fontBold,
            fontSize: fontResize(combinSize * 0.15),
            color: Colors.White,
          }}
        >
          {'Update Post'}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default MediaButton;
