import { View, Text, TouchableOpacity } from 'react-native';
import React from 'react';
import Colors from '../../Resources/Colors';
import Strings from '../../Resources/Strings';
import { fontResize } from '../../Util/font';

const NotificationTab = ({  
  window,
  setNotificationView,
  isNotificationView,
}) => {
  const { width, height } = window;
  const combinSize =
    width > height
      ? width * 0.05 + height * 0.1
      : width == height
      ? width + height
      : height * 0.05 + width * 0.1;
  return (
    <View
      style={{
        width: '90%',
        marginHorizontal: '5%',
        flexDirection: 'row',
        paddingVertical: '2%',
        justifyContent: 'space-around',
        alignItems: 'center',
        borderRadius: combinSize * 0.08,
        borderWidth: 1,
        backgroundColor: 'white',
        borderColor: Colors.PrimaryGray,
      }}
    >
      <TouchableOpacity
        style={{
          width: '50%',
          height: combinSize * 0.1,
          borderRightWidth: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onPress={() => setNotificationView(true)}
      >
        <Text
          style={{
            color: isNotificationView
              ? Colors.LightOrangeColor
              : Colors.DimGray,
            fontSize: fontResize(combinSize * 0.13),
          }}
        >
          {Strings.NOTIFICATIONS}
        </Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={{
          borderBottomColor: isNotificationView
            ? Colors.LightOrangeColor
            : Colors.DimGray,
          width: '50%',
          height: combinSize * 0.1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onPress={() => setNotificationView(false)}
      >
        <Text
          style={{
            color: !isNotificationView
              ? Colors.LightOrangeColor
              : Colors.DimGray,
            fontSize: fontResize(combinSize * 0.13),
          }}
        >
          {Strings.WHO_VIEWED}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default NotificationTab;
