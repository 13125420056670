import {
  View,
  Text,
  FlatList,
  Image,
  useWindowDimensions,
} from 'react-native-web';
import React, { useEffect, useState, useCallback } from 'react';
import Colors from '../../Resources/Colors';
import { theme } from '../../Util/constant';
import CustomInputText from '../../Component/CustomInputText';
import Strings from '../../Resources/Strings';
import EndPoint from '../../Redux/constants/EndPoint';

import CustomButton from '../../Component/CustomButton';
import { fontResize } from '../../Util/font';
import { mxWidth } from '../../Util';
import { useDispatch, useSelector } from 'react-redux';
import CustomTagView from '../../Component/CustomTagView';
import {
  ActivethumbsUpIcon,
  STAR_ICON,
  thumbsUpIcon,
} from '../../Resources/assets';
import CommentsView from '../../Component/commentsView/index.web';
import { commentPost, postCommentLikeAction } from '../../Redux/actions/homeAction';

var textLength = 0;

const ViewCommentsWeb = ({
  comments,
  onSubmitComment,
  postId,
  onUserProfile,
  onCommentUserLike,
  viewCommentLike,
}) => {
  const [textDescription, setTextDescription] = useState('');
  const allUsers = useSelector((state) => state.profile.allUser);
  const [allComments, setAllComments] = useState(comments);
  const [readMore, setReadMore] = useState(false);
  const window = useWindowDimensions();
  const [lastValue, setLastValue] = useState('');
  const [tagValue, setTagValue] = useState([]);
  const [istag, setTag] = useState(false);
  const [tagUser, setTagUser] = useState([]);
  const [commentText, setCommentText] = useState('');
  const [comment, setComments] = useState(comments);
  const user = useSelector((state) => state.auth.userData);
  const type = user?.data?.role ? user?.data?.role : Strings.PROFESSIONAL;
  const userInfo = useSelector((state) => state?.profile?.myProfile);
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  const [isVisible, setVisible] = useState(true);
  const dispatch = useDispatch();

  const addComment = (text) => {
    // if (allComments.length == 0) {
    //   var newComment = {
    //     _id: user?.data?._id,
    //     comment: text,
    //     created_at: new Date(),
    //     image:
    //       type == Strings.PROFESSIONAL
    //         ? userInfo?.image
    //           ? userInfo?.image
    //           : ''
    //         : userInfo?.userinfo?.profile_pic
    //         ? userInfo?.userinfo?.profile_pic
    //         : '',
    //     name: userInfo?.name,
    //     post_id: '62f1f01098349071530d6f8d',
    //     updated_at: new Date(),
    //     user_id: userInfo?._id,
    //     tagged_users: tagUser,
    //   };
    //   let updateData = allComments;
    //   updateData.push(newComment);
    //   setAllComments(updateData);
    //   setTextDescription('');
    // } else {
    //   var newComment = {
    //     _id: allComments[0]?._id,
    //     comment: text,
    //     created_at: new Date(),
    //     image: allComments[0]?.image,
    //     name: allComments[0]?.name,
    //     post_id: allComments[0]?.post_id,
    //     updated_at: new Date(),
    //     user_id: allComments[0]?.user_id,
    //     tagged_users: tagUser,
    //   };
    //   let updateData = allComments;
    //   updateData.push(newComment);
    //   setAllComments(updateData);
    //   setTextDescription('');
    // }
    // setTextDescription('');
    let updateData = allComments;
      updateData.push(text);
      setAllComments([...updateData]);
  };

  const renderItems = useCallback(({ item, index }) => {
      return (
        <CommentsView
        key={item.id}
          item={item}
          userId={user?.data?._id}
          onUserProfile={(id) => {
            onUserProfile(id);
          }}
          onCommentLike={(data) => {
            // onCommentUserLike(data);
            onCommentLikeUser(data, index);
          }}
          viewCommentLike={(data) => {
            viewCommentLike(data);
          }}
        />
      );
   
    
  });

  const commontView = useCallback((item, index)=>{
    return(
      <CommentsView
                    item={item}
                    userId={user?.data?._id}
                    onUserProfile={(id) => {
                      onUserProfile(id);
                    }}
                    onCommentLike={(data) => {
                      // onCommentUserLike(data);
                      onCommentLikeUser(data, index);
                    }}
                    viewCommentLike={(data) => {
                      viewCommentLike(data);
                    }}
                  />
    )
  }, [comment])

  // useEffect(() => {
  //   var length = readMore ? allComments.length : 3;

  //   var newValues = allComments.filter((item, index) => {
  //     if (index < length) {
  //       return item;
  //     }
  //   });
  //   setAllComments(newValues);
  // }, []);

  const onReadMore = () => {
    setReadMore(!readMore);

    // var length = !readMore ? allComments.length : 3;

    // var newValues = allComments.filter((item, index) => {
    //   if (index < length) {
    //     return item;
    //   }
    // });
    // setAllComments(newValues);
  };

  const onChangeText = (text) => {
    if (text.length == 1 && text == '@') {
      setTag(true);
      textLength = text.length;
      setTagValue(allUsers || []);
    } else if (
      text.charAt(text.length - 1) == '@' &&
      text.charAt(text.length - 2) == ' '
    ) {
      textLength = text.length;
      setTag(true);
      const before_ = text.substring(0, text.length - 1);
      setLastValue(before_);
      setTagValue(allUsers || []);
    } else if (istag) {
      var afterChar = text.slice(textLength);
      var tagData = allUsers || [];
      const filteredArr = tagData.filter((val) =>
        val?.name?.toLowerCase().includes(afterChar)
      );
      setTagValue(filteredArr);
    }
    if (text.length == 0) {
      setTag(false);
    }

    if (
      text.charAt(text.length - 1) !== '@' ||
      text.charAt(text.length - 2) !== ' '
    ) {
      var newCommentText = `${commentText}${text.charAt(text.length - 1)}`;
      if (!istag) {
        setCommentText(newCommentText);
      }
    }

    setTextDescription(text);
  };

  const addTagData = ({ item }) => {
    setTextDescription('');
    let newText =
      textDescription == '@' ? `@${item.name} ` : `${lastValue} @${item.name} `;
    let newCommenttagText = `${commentText} [${item.name}](${item._id}) `;
    setTextDescription(newText);
    setCommentText(newCommenttagText);
    setTag(false);

    var oldTagValue = tagUser;
    var checkIndex = oldTagValue.findIndex(
      (element) => element.user_id == item._id
    );
    if (checkIndex == -1) {
      oldTagValue.push({ user_id: item._id, name: item.name });
      setTagUser(oldTagValue);
    }
  };

  const addNewComment = (id, comment, tagUser) => {
    let params = {
      post_id: id,
      comment: comment,
      tagged_users: tagUser,
    };
    debugger
    dispatch(
      commentPost({
        params,
        onSuccess: (result) => {
          addComment(result.data);
          onSubmitComment(result.data);
          setTextDescription('');
          setVisible(true);
          // Emitter.emit(Type.EMITTER.COMMENTDONE, result.data);
        },
        onError: (error) => {
          console.log("err>>>", error)
          // dispatch(setLoading(false));
        },
      })
    );
  };

  const onCommentLikeUser = (it, index) => {
    const data = {
      post_id: it?.item?.post_id,
      comment_user_id: it?.item?.user_id,
      comment_like_user_id: user?.data?._id,
      comment_id: it?.item?._id,
      status: it?.status ? 'like' : 'unlike',
    };
    dispatch(
      postCommentLikeAction({
        data,
        onSuccess: (result) => {
          updateCommentLikeData({
            newData: result.data,
            newParam: data,
            index: index,
          });
        },
        onError: (error) => {
          console.log('error', error);
        },
      })
    );
  };

  const updateCommentLikeData = ({ newData, newParam, index }) => {

    if (newParam.status == 'like') {
      
      var updatecommentData = allComments;
      var updateLikeData = allComments[index];
      updateLikeData.comment_likes = updateLikeData?.comment_likes
        ? [...updateLikeData?.comment_likes, newData]
        : [newData];
      updatecommentData[index] = updateLikeData;
      setAllComments([...updatecommentData]);
    } else {
      var updateComment = allComments;
      var updateLikeCommentData = allComments[index].comment_likes;
      var likeIndex = updateLikeCommentData.findIndex(
        (item) => item.comment_like_user_id == user?.data?._id
      );
      updateLikeCommentData.splice(likeIndex, 1);
      updateComment[index].comment_likes = [...updateLikeCommentData];
      setAllComments([...updateComment]);
    }
  };

  return (
    <View
      style={{
        flex: 1,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        padding: 12,
      }}
    >
      {istag ? (
        <View>
          {tagValue.map((item, index) => {
            if (item !== null) {
              return (
                <CustomTagView
                  item={item}
                  index={index}
                  addTagData={() => addTagData({ item })}
                />
              );
            }
          })}
        </View>
      ) : allComments?.length > 0 ? (
        <View>
          <FlatList keyExtractor={item => item.id || item._id} data={readMore ? allComments : allComments.length >= 3  ? [allComments[0],allComments[1],allComments[2]] : allComments.length == 2 ? [allComments[0],allComments[1]] : [allComments[0]] } renderItem={renderItems} />
          {allComments.length > 3 && (
            <CustomButton
              width={'30%'}
              height={'40px'}
              backgroundColor={Colors.White}
              borderRadius={'10px'}
              alignItems={'center'}
              justifyContent={'center'}
              fontFamily={'Gilroy-SemiBold'}
              fontSize={'12px'}
              textColor={Colors.Blueberry}
              onPress={onReadMore}
              text={readMore ? Strings.LESS : Strings.READ_MORE_COMMENTS}
            />
          )}
        </View>
      ) : (
        <View
          style={{
            justifyContent: 'center',
            paddingHorizontal: window.width * 0.01,
            flex: 1,
          }}
        >
          <Text
            style={{ ...theme.fontSemiBold, fontSize: 16, color: Colors.Black }}
          >
            {Strings.BE_FIRST_COMMENT}
          </Text>
        </View>
      )}
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <CustomInputText
          window={window}
          label={''}
          labelStyle={{ ...theme.fontSemiBold, fontSize: 18 }}
          inputOuterView={{
            borderColor: '#C2C5CE',
            borderWidth: 1,
          }}
          value={textDescription}
          width={'68%'}
          onChangeText={(text) => onChangeText(text)}
          placeholder={'Write a comment'}
        />
        <CustomButton
          width={'30%'}
          height={'44px'}
          marginTop={'2 px'}
          backgroundColor={Colors.Blueberry}
          borderRadius={'10px'}
          alignItems={'center'}
          justifyContent={'center'}
          fontFamily={'Gilroy-SemiBold'}
          fontSize={'18px'}
          textColor={Colors.White}
          onPress={() => {
            // onSubmitComment(postId, textDescription, tagUser);
            // addComment(textDescription);
            // setTextDescription('');
            addNewComment(postId, textDescription, tagUser);
          }}
          text={Strings.SEND}
        />
      </View>
    </View>
  );
};

export default ViewCommentsWeb;
