export const RouteName = {
  OURGOAL: 'ourGoal',
  SPLASH: 'splash',
  LOGIN: 'login',
  ONBOARDING: 'OnBoarding',
  AUTH: 'AUTH',
  STARTING: 'starting',
  USERTYPE: 'usertype',
  VERIFICATION: 'Verification',
  SIGNUP: 'signup',
  HOMESTACK: 'homeStack',
  HOME: 'home',
  POST_DETAILS: 'post_details',
  POST_EDIT: 'post_edit',
  SEARCH: 'Search',
  CREATE_PROFILE: 'create_profile',
  CREATE_JOB_POST: 'create_job_post',
  CREATE_PROMOTIONS: 'create_promotions',
  CREATE_EVENT_POST: 'create_event_post',
  JOB_SUMMARY: 'job_summary',
  PROMOTION_SUMMARY: 'promotion_summery',
  EVENT_SUMMARY: 'event_summary',
  JOB_DETAIL: 'job_detail',
  EVENT_DETAIL: 'event_detail',
  APPLY_JOB: 'apply_job',
  MANAGE_CALENDAR: 'manage_calendar',
  MY_BOOKING: 'my_booking',
  MY_RECRUTING_DATES: 'my_recruting_dates',
  OTPVERIFICATION: 'OTPVerification',
  CREATE_STORY: 'Create_story',
  VIEW_STORIES: 'View_Stories',
  FORGET_PASSWORD: 'forget_password',
  CHANGE_PASSWORD: 'change_password',
  PROFILE_STACK: 'profile_stack',
  PROFILE: 'profile',
  EDIT_PROFILE: 'edit_profile',
  EDIT_PROFILE_SETTING: 'edit_profile_setting',
  BUSINESS_PROFILE: 'business_profile',
  MY_PROFILE: 'my_profile',
  RECUITER_COMPANY: 'recuiter_company',
  RECUITER_PROFILE: 'recuiter_profile',
  LinkedInPage: 'linkedin_page',
  CREATE_JOB: 'CreateJob',
  INTERNET_CONNECTION: 'InternetConnection',
  BLOCK_USER: 'block_user',

  // web

  EDIT_WEB_PROFILE: 'edit_web_profile',
  MY_EXPEREINCE: 'my_experience',
  HOME_WEB: 'home_web',
  JOB_WEB: 'job_web',
  SETTING_WEB: 'setting_web',
  CHAT_DETAIL: 'chat_detail',
  CHAT_DETAIL_WEB: 'chat_detail_web',
  COMPANIES_WEB: 'company_web',
  DIRECT_WEB: 'direct_web',
  NOTIFICATION_WEB: 'notification_web',
  JOB_STACK: 'job_stack',
  EVENT_STACK: 'events_stack',
  OTHER_PROFILE: 'OTHER_PROFILE',
  USER_CHAT: 'UserChat',

  // Setting
  SETTINGS_STACK: 'settings_stack',
  SETTINGS: 'settings',
  PAYMENT: 'Payment',
  ADD_NEW_CARD: 'Add_new_card',
  NOTIFICATION: 'Notification',
  JOB_NOTIFICATION: 'Job_Notification',
  FAQ: 'Faq',
  SUPPORT: 'Support',
  SUPPORT_DETAIL: 'Support_Detail',
  CREATE_NEW_TICKET: 'Create_new_ticket',
  TERMS_CONDITION: 'Terms_condition',
  PRIVACY_POLICY: 'privacy_policy',
  SUBSCRIPTIONS: 'subscriptions',
  GLOD_SUBSCRIPTIONS: 'gold_subscriptions',
  PRIVACY_SETTINGS: 'privacy_settings',
  APP_TUTORIAL: 'App_Tutorial',
  BLOCK_USER_DATA: 'Block user',
  SUGGESTION: 'SUGGESTION',
  DELETE_USER: 'Delete_User',
  OUR_MISSION: 'Our Mission',
  FOLLOWERS: 'Followers',
  CONNECTIONS: 'Connections',
  POST_LIKE_USER: 'post_like_user',

  // chat
  CHAT: 'chat',
  DIRECT_MEASSAGE: 'direct_message',
  INTERVIEW_DATETIME: 'interview_datetime',
  SETTING_STACk: 'SettingStack',
  NOTIFICATION_HEADER_STACK: 'NotificationHeaderStack',
  DONT_SEE_POST: 'DontSeePost',
  REPORT_POST: 'ReportPost',
  CHAT_USER: 'ChatUser',

  // website url
WEBSITE: 'http://www.axesseq.com/',
};
