import {
  StyleSheet,
  View,
  useWindowDimensions,
  FlatList,
  TouchableOpacity,
  Image,
  Text,
  ImageBackground,
  ScrollView,
  Platform,
  BackHandler
} from 'react-native';

import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomHeader from '../../../Component/CustomHeader';
import CustomButton from '../../../Component/CustomButton';
import {
  GOLD_ICON,
  PRICE_TAG,
  BLUE_TICK_ICON,
} from '../../../Resources/assets';

import Colors from '../../../Resources/Colors';
import { RouteName } from '../../../Navigation/routeName';
import {
  SetSuggestionUserAction,
  cancelSuggestionUserAction,
  followUserAction,
  getSuggestionUserAction,
  buySubscriptions,
  buySubscriptionsIos,
  cancelSubscriptions,
  fetchCardAction,
  fetchMyProfile,
  fetchSubscriptions,
  setProfileData,
} from '../../../Redux/actions/profileAction';
import { reset, setLoading, setToken, setUserData } from '../../../Redux/actions/authAction';
import { updateGoldMemberFirebaseChatList } from '../../../Constants/FireStoremanager/FireStoremanager.web';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Emitter from '../../../Util/eventEmitter';
import { BackButton } from '../../Authentication/VerificationStyle';
import Strings from '../../../Resources/Strings';
import { theme } from '../../../Util/constant';
import { fontResize } from '../../../Util/font';
import Type from '../../../Constants/Type/type';
import SuggestionUserList from '../../Suggestion/SuggestionComponent/SuggestionUserList';
import { CustomText } from '../../../Component/CustomComponent';
import toast from 'react-simple-toasts';
console.disableYellowBox = true;


const GoldSubscription = ({
  backPress,
  fetchOtherUserProfile,
  setItem,
  setProfileModal,
  navigation,
  route,
}) => {
  const dispatch = useDispatch();
  const subscriptionList = useSelector(
    (state) => state.profile.subscriptionList
  );
  const [selectedPlanIndex, setselectedPlanIndex] = useState(0);
  const { width, height } = useWindowDimensions();
  const userData = useSelector((state) => state.auth.userData);
  const suggestionUsers = useSelector((state) => state.profile.suggestionList);
  const cardList = useSelector((state) => state.profile.cardList);
  const [followUserList, setFollowUserList] = useState([]);
  const [isBuySubscription, setBuySubscription] = useState(false);
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] =
    useState(false);
    const token = useSelector((state) => state.auth.token);

  const combinSize = width > height ?  (width * 0.05) + (height * 0.1) : width  == height ? width + height  :   (height * 0.05) + (width * 0.1)
  
  

  useEffect(() => {
    fetchSubscription();
    fetchCards();
    // window.addEventListener('popstate', onBackButtonEvent);
    Emitter.on('Subscribed', () => {
      fetchSubscription();
    });
    return () => {
      Emitter.off('Subscribed');
    };
  }, []);

  useEffect(() => {
    const backAction = () => {
      Alert.alert('Hold on!', 'Are you sure you want to go back?', [
        {
          text: 'Cancel',
          onPress: () => null,
          style: 'cancel',
        },
        {text: 'YES', onPress: () =>{}},
      ]);
      return true;
    };

    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      backAction,
    );

    return () => backHandler.remove();
  }, []);



  // get suggestion user list
  const fetchSuggestionUser = () => {
    dispatch(
      getSuggestionUserAction({
        onSuccess: (result) => {},
        onError: (error) => {
          dispatch(setLoading(false));
        },
      })
    );
  };

  // Check follow user detail index
  const getFollowIndex = useCallback(
    (id) => {
      var getIndex = followUserList.findIndex((element) => element == id);
      return getIndex == -1
        ? -1
        : suggestionUsers.findIndex((element) => element._id == id);
    },
    [followUserList]
  );

  // render suggest list data

  const suggestRenderItem = ({ item, index }) => {
    if (index < 10) {
      return (
        <SuggestionUserList
          item={item}
          goToProfile={() => goToProfile(item)}
          height={height}
          width={width}
          index={index}
          cancelSuggestion={() => cancelUser({ id: item?._id, index: index })}
          followUser={() => followUser({ id: item?._id, index: index })}
          isFollow={getFollowIndex(item?._id)}
        />
      );
    }
  };

  // call cancel user suggestion

  const cancelUser = ({ id, index }) => {
    cancelSuggestionUser({ id: id, index: index });
  };

  // move to view all details user
  const goToProfile = (data) => {
    if (data._id !== userData?.data._id) {
      fetchOtherUserProfile(data);
      setItem(data);
      setProfileModal(true);
    }
  };

  // cancel suggestion user suggestion list
  const cancelSuggestionUser = ({ id, index }) => {
    const param = {
      suggestion_id: id,
    };

    dispatch(
      cancelSuggestionUserAction({
        param,
        onSuccess: (result) => {
          var userList = suggestionUsers;
          userList.splice(index, 1);
          dispatch(SetSuggestionUserAction({ data: userList }));
          if (suggestionUsers.length < 11) {
            fetchSuggestionUser();
          }
        },
        onError: (error) => {
          console.log('err', error);
        },
      })
    );
  };

  // follow the new suggestion user
  const followUser = ({ id, index }) => {
    const params = {
      follower_id: id,
    };
    setFollowUserList([...followUserList, id]);
    dispatch(
      followUserAction({
        params,
        onSuccess: (result) => {
          setTimeout(() => {
            Emitter.emit(Type.EMITTER.STORE_CREATE);
            var userList = suggestionUsers;
            userList.splice(index, 1);
            dispatch(SetSuggestionUserAction({ data: userList }));
            if (suggestionUsers.length < 11) {
              fetchSuggestionUser();
            }
          }, 2000);
        },
        onError: (error) => {
          console.log('err', error);
        },
      })
    );
  };

  const renderDescriptionView = (text) => {
    return (
      <View style={styles.descriptionVw}>
        <Image style={{height: combinSize * 0.15,
    width: combinSize * 0.15,
    resizeMode: 'contain',
    marginRight: combinSize * 0.05,}} source={BLUE_TICK_ICON} />
        <Text style={[styles.descriptionText, { width: width * 0.7, fontSize: fontResize(combinSize * 0.15), }]}>
          {text}
        </Text>
      </View>
    );
  };

  const fetchSubscription = () => {
    try {
      // dispatch(setLoading(true));
      dispatch(
        fetchSubscriptions({
          onSuccess: (result) => {
            console.log("result?.>>>", result)
            dispatch(setLoading(false));
            setselectedPlanIndex(0);
            setBuySubscription(
              result?.data[0]?.is_subscribed !== null ? true : false
            );
          },
          onError: (error) => {
            dispatch(setLoading(false));
            if (error?.status === 401) {
              logout();
              
              Emitter.emit('logout_account');
            }
            console.log('error', error);
          },
        })
      );
    } catch (err) {
      dispatch(setLoading(false));
    }
  };

  const logout = async () => {
    try {
      AsyncStorage.removeItem(Strings.ACCESS_TOKEN_KEY);
      AsyncStorage.removeItem(Strings.LOGIN_DATA);
      AsyncStorage.setItem(Strings.SHOW_BIG_BANNER, 'true');

      dispatch(setToken(''));
      dispatch(setUserData(''));
      dispatch(setProfileData([]));
      dispatch(reset([]));
      navigation.reset({
        index: 0,
        routes: [{ name: RouteName.AUTH }],
      });
    } catch (err) {
      console.log('err12345', err);
    }
  };

  const fetchCards = () => {
    try {
      // dispatch(setLoading(true));
      dispatch(
        fetchCardAction({
          onSuccess: (result) => {
            dispatch(setLoading(false));
          },
          onError: (error) => {
            dispatch(setLoading(false));
            console.log('error', error);
          },
        })
      );
    } catch (err) {
      dispatch(setLoading(false));
    }
  };

  const buySubscription = (data) => {
    try {
      dispatch(setLoading(true));
      dispatch(
        buySubscriptions({
          data,
          onSuccess: (result) => {
            fetchSubscription();
            fetchProProfile();
            dispatch(setLoading(false));
            toast(result?.message,10)
            backToHomePage();
          },
          onError: (error) => {
            dispatch(setLoading(false));
          },
        })
      );
    } catch (err) {
      console.log("error>>>", err)
      dispatch(setLoading(false));
    }
  };

  const backToHomePage = async () => {
    if (route.params.isLogin) {
      var newUserData = userData;
      newUserData.data.gold_member = true;
      await AsyncStorage.setItem(Strings.LOGIN_DATA, newUserData.toString());
      navigation.reset({
        index: 0,
        routes: [{ name: RouteName.HOMESTACK }],
      });
    }
  };

  const fetchProProfile = () => {
    dispatch(
      fetchMyProfile({
        token,
        onSuccess: (result) => {},
        onError: (error) => {},
      })
    );
  };



  const checkSubScription = () => {
    if (
      subscriptionList[0]?.is_subscribed?.device_name !== 'ios' &&
      Platform.OS !== 'ios'
    ) {
      setShowCancelSubscriptionModal(true);
    } else {
      alert('Subscription have been taken from another device');
    }
  };

  const cancelSubscription = () => {
    try {
      setShowCancelSubscriptionModal(false);

      dispatch(setLoading(true));
      var data = {
        subscription_id:
          subscriptionList[0]?.is_subscribed?.stipeSubscriptionID,
      };
      dispatch(
        cancelSubscriptions({
          data,
          onSuccess: async (result) => {
            fetchProProfile();
            
            toast(
              result?.message || 'Subscription successfully cancel'
            );
            const blockchatUser = updateGoldMemberFirebaseChatList({
              id: userData?.data?._id,
              isSubscription: false,
            });
            fetchSubscription();
            var newUserData = userData;
              newUserData.data.gold_member = false;
              await AsyncStorage.setItem(
                Strings.LOGIN_DATA,
                JSON.stringify(newUserData)
              );
  
            dispatch(setLoading(false));
          },
          onError: (error) => {
            console.log('error', JSON.stringify(error));
            dispatch(setLoading(false));
          },
        })
      );
    } catch (error) {
      dispatch(setLoading(false));
      console.og('error', error);
    }
  };

  const renderCancelSubscription = () => {
    return (
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0,0,0,0.2)',
          justifyContent:'center',
          alignItems: 'center'
        }}
      >
        <TouchableOpacity
          activeOpacity={0.9}
          onPress={() => setShowCancelSubscriptionModal(false)}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        />
        <View
          style={{
            paddingTop: height * 0.1,
            paddingBottom: height * 0.1,
            paddingVertical: height * 0.009,
            borderRadius: width * 0.03,
            backgroundColor: Colors.White,
            width: combinSize *4
          }}
        >
          <View style={{ alignItems: 'center' }}>
            <CustomText
              {...theme.fontBold}
              style={{
                textAlign: 'center',
              }}
              fontSize={fontResize(width * 0.019)}
            >
              Cancel Your Subscription?
            </CustomText>
            <CustomText
              textColor={Colors.DimGray}
              {...theme.fontRegular}
              style={{ textAlign: 'center' }}
              marginTop={height * 0.018}
              fontSize={fontResize(width * 0.013)}
            >
              Are you sure you want to cancel your subscription?
            </CustomText>
          </View>
          <View
            style={{  marginTop: height * 0.05 }}
          >
            <View
              style={{
                paddingHorizontal: combinSize * 0.4 ,
                paddingVertical: 16,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <CustomButton
                onPress={() => cancelSubscription()}
                width={width * 0.14}
                height={width * 0.04}
                backgroundColor={Colors.Blueberry}
                borderRadius={width * 0.01}
                alignItems={'center'}
                justifyContent={'center'}
                {...theme.fontSemiBold}
                fontSize={fontResize(width * 0.013)}
                textColor={Colors.White}
                textTransform={'capitalize'}
                text={'Yes'}
              />
              <CustomButton
                onPress={() => setShowCancelSubscriptionModal(false)}
                width={width * 0.14}
                height={width * 0.04}
                backgroundColor={Colors.White}
                borderRadius={width * 0.01}
                alignItems={'center'}
                justifyContent={'center'}
                {...theme.fontSemiBold}
                fontSize={fontResize(width * 0.013)}
                textColor={Colors.OrangeColor}
                textTransform={'capitalize'}
                text={'No'}
                borderColor={Colors.OrangeColor}
                borderWidth={'1px'}
              />
            </View>
          </View>
        </View>
      </View>
    );
  };

  return (
    <View style={[styles.container]}>
      <ScrollView
        style={styles.scrollContainer}
        contentContainerStyle={{ justifyContent: 'center' }}
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.subsContainer}>
          {/* Header */}
          <View style={[styles.hearderView, { marginVertical: height * 0.03 }]}>
            <Text
              style={[
                styles.titleText,
                { fontSize: fontResize(combinSize * 0.15) },
              ]}
            >
              {Strings.SUBSCRIPTIONS_HEADER}
            </Text>
          </View>
          {/* Subscribe card */}
          <View
            style={[
              styles.mainContainer,
              {
                marginHorizontal: width * 0.03,
                width: width * 0.32,
                borderRadius: height * 0.03,
              },
              styles.mainContainerShadowIos,
            ]}
          >
            <Image
              style={[
                styles.GoldImageStyle,
                { top: height * 0.08, left: width * 0.01, width: combinSize * 0.42,
    height: combinSize * 0.42, },
              ]}
              source={GOLD_ICON}
            />
            <ImageBackground
              resizeMode="contain"
              source={PRICE_TAG}
              style={[
                styles.imageTag,
                {
                  width: combinSize * 1.1,
                  height: combinSize * 1.1,
                  top: -combinSize * 0.1,
                  right: combinSize * 0.2,
                },
              ]}
            >
              <Text
                style={[
                  styles.priceText,
                  {
                    marginLeft: width * 0.01,
                    marginRight: width * 0.0012,
                    fontSize: fontResize(combinSize * 0.18),
                  },
                ]}
              >
                {'$'}
                <Text
                  style={{fontSize: fontResize(combinSize * 0.2)}}
                >{`${Strings.GOLD_PRICE_VALUE}\n`}</Text>
                per year
              </Text>
            </ImageBackground>
            <Text style={[styles.mostText, { marginTop: height * 0.15, fontSize: fontResize(combinSize * 0.16), }]}>
              {Strings.MOST_POPULAR}
            </Text>
            <Text style={[styles.goldText,{fontSize: fontResize(combinSize * 0.28)}]}>{Strings.GOLD_AXESS}</Text>
            {renderDescriptionView(Strings.THIS_BASIC)}
            {renderDescriptionView(`${Strings.USERS_WILL_HAVE}`)}
            {renderDescriptionView(Strings.USERS_CAN_DIRECTLY)}
            {renderDescriptionView(Strings.USERS_WILL_NOTIFY)}
            {renderDescriptionView(Strings.FOUR_MORE_PROFILE)}
          </View>
          {/* Subscribe button */}
          <View
            style={[styles.subscriptionButtonVw, { marginTop: height * 0.02 }]}
          >
            <CustomButton
              onPress={() => {
                if (subscriptionList[0]?.is_subscribed === null) {
                  if (cardList.length !== 0) {
                    let defaultCard = cardList.filter((item) => {
                      return item.is_Default == true;
                    });
                    let data = {
                      subscription_id: subscriptionList[selectedPlanIndex]._id,
                      card_id: defaultCard[0]?.card_id,
                      device_name: Platform.OS,
                    };
                    buySubscription(data);
                  } else {
                    navigation.navigate(RouteName.ADD_NEW_CARD, {
                      subscriptionData: subscriptionList[0],
                      isFromSubscription: true,
                      isEdit: false,
                      data: '',
                      isLogin: route?.params?.isLogin || false,
                    });
                  }
                }
              }}
              width={'92%'}
              height={`${combinSize * 0.44}px`}
              backgroundColor={Colors.Blueberry}
              marginTop={height * 0.01}
              marginBottom={height * 0.01}
              borderRadius={'10px'}
              disabled={false}
              alignItems={'center'}
              justifyContent={'center'}
              {...theme.fontSemiBold}
              fontSize={`${combinSize * 0.13}px`}
              textColor={Colors.White}
              textTransform={'capitalize'}
              text={Strings.SUBSCRIBE_BUTTON}
            />
            {subscriptionList[0]?.is_subscribed !== null  && <CustomButton
              onPress={() => checkSubScription()}
              width={'100%'}
              height={'50px'}
              marginTop={height * 0.03}
              marginBottom={height * 0.1}
              backgroundColor={'#FFECEC'}
                  borderRadius={'10px'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  {...theme.fontSemiBold}
                  fontSize={`${width < 400 ? width * 0.03 : 16}px`}
                  textColor={'#FF5D5D'}
                  textTransform={'capitalize'}
                  imageStyle={{ marginRight: width < 400 ? width * 0.02 : 15 }}
                  text={Strings.CANCEL_SUBSCRIPTION}
                  buttonContainer={{ flexDirection: 'row' }}
                  disabled={Platform.OS === 'ios'}
            />}
          </View>
        </View>
      </ScrollView>
      {showCancelSubscriptionModal && renderCancelSubscription()}
    </View>
  );
};

export default GoldSubscription;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.background,
  },
  scrollContainer: {
    width: '40%',
    backgroundColor: Colors.background,
    padding: '1%',
    borderRadius: 20,
  },
  subsContainer: {
    width: '100%',
    backgroundColor: '#fff',
    justifyContent: 'center',
    padding: '1%',
    borderRadius: 20,
  },
  titleText: {
    ...theme.fontBold,
    color: Colors.Black,
  },
  hearderView: {
    width: '100%',
    alignItems: 'center',
  },
  backButton: {
    position: 'absolute',
  },
  mainContainer: {
    backgroundColor: Colors.White,
    elevation: 5,
    padding: 20,
  },
  mainContainerShadowIos: {
    shadowColor: Colors.Gray,
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 6,
    shadowRadius: 6,
  },
  goldText: {
    ...theme.fontBold,
    
    color: Colors.Black,
    marginBottom: 20,
  },
  mostText: {
    ...theme.fontMedium,
    
    color: Colors.DimGray,
    marginBottom: 5,
  },
  GoldImageStyle: {
    
    resizeMode: 'contain',
    position: 'absolute',
  },
  imageTag: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
  priceText: {
    ...theme.fontBold,
    color: Colors.White,
    
    textAlign: 'center',
  },
  descriptionVw: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5,
  },
  descriptionTickImage: {
    height: 20,
    width: 20,
    resizeMode: 'contain',
    marginRight: 10,
  },
  descriptionText: {
    ...theme.fontMedium,
    color: Colors.DimGray,
  },
  subscriptionButtonVw: {
    paddingHorizontal: 16,
    paddingVertical: 16,
    width: '100%',
  },
});
