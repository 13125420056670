import {
  View,
  TouchableOpacity,
  Image,
  TextInput,
  Keyboard,
  Dimensions,
  useWindowDimensions,
  FlatList,
  Linking,
  Text,
} from 'react-native';

import SendIcon from '../../Resources/assets/sendIcon.png';
import EmojiIcon from '../../Resources/assets/emoj_icon.png';
import AttchIcon from '../../Resources/assets/attach_icon.png';
import React, { useEffect, useRef, useState } from 'react';
import Colors from '../../Resources/Colors';
import { backIcon, addIcon } from '../../Resources/assets';
import Strings from '../../Resources/Strings';
import { NoDataFoundView } from '../../Component/CustomErrorComponent';
import { style } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFirebaseChatOrderByUserList,
  setFirebaseChatList,
  setFirebaseUserChat,
  setFirebaseUserChatLastMessage,
} from '../../Constants/FireStoremanager/FireStoremanager.web';
import ChatRow from '../../Component/ChatRow';
import ReceiverUserProfile from '../../Component/ReceiverUserProfile';
import { imageUplaod } from '../../Redux/services/profileService';
import { setLoading } from '../../Redux/actions/authAction';
import Emitter from '../../Util/eventEmitter';
import { RouteName } from '../../Navigation/routeName';
import EmojiPicker from '../../Constants/CustomEmojiPicker/emojis/EmojiPicker';
import {
  addDoc,
  collection,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  doc,
  getDocs,
} from 'firebase/firestore';
import { firestore } from '../../Constants/FirebaseWeb';
import { isChatDetails } from '../../Redux/actions/profileAction';
import CustomHeaderChat from '../../Component/CustomHeaderChat';
import { useDropzone } from 'react-dropzone';
import { isNative } from '../../Util';
import moment from 'moment';
import { compareTodayDate, compareTwoDate } from '../../Util/constant';

const ChatWeb = ({ navigation, route }) => {
  const chatUserData = route.params;
  const { receiverId, receiverData, chatId, isOther } = chatUserData
    ? chatUserData
    : { receiverId: '0', receiverData: {}, chatId: '1024562', isOther: false };
  const [messages, setMessages] = useState([]);
  const userData = useSelector((state) => state.auth.userData);
  const type = userData?.data?.role
    ? userData?.data?.role
    : Strings.PROFESSIONAL;
  const [message, setMessage] = useState('');
  const [cureentMessage, setCureentMessage] = useState('');
  const [docFile, setDocFile] = useState({});
  const [docPath, setdocPath] = useState('');
  const listRef = useRef(null);
  const [isVisible, setVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [readLastIndex, setReadLastIndex] = useState(0);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const window = useWindowDimensions();
  const { width, height } = window;
  const combinSize =
    width > height
      ? width * 0.05 + height * 0.1
      : width == height
      ? width + height
      : height * 0.05 + width * 0.1;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLoading(true));
    getMessageData();
    // statusUpdateUnreadMessage();
    setShowEmojiPicker(false);
  }, []);

  var inputRef = useRef(null);

  const getMessageData = () => {
    try {
      const unsub = onSnapshot(
        query(
          collection(firestore, 'messages', chatId, 'messages'),
          orderBy('sentDate')
        ),
        (snapshot) => {
          const listData = [];
          var chatData = {};
          var lastIndexRead = 0;
          const currentId = userData?.data?.user_id || userData?.data?._id;
          snapshot?.docs?.forEach((doc, index) => {
            chatData = doc.data();
            chatData.message_id = doc.id;
            if (chatData.senderId == currentId) {
              if (chatData.read == true) {
                lastIndexRead = index;
              }
            }
            listData.push(chatData);
          });
          setMessages(listData);
          dispatch(setLoading(false));

          statusUpdateUnreadMessage();
          setReadLastIndex(lastIndexRead);
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const statusUpdateUnreadMessage = async () => {
    try {
      const senderid = userData?.data?.user_id || userData?.data?._id;

      const newMsgData = await getDocs(
        query(
          collection(firestore, 'messages', chatId, 'messages'),
          orderBy('sentDate')
        )
      );
      newMsgData.forEach((docs) => {
        if (docs.data().receiverId == senderid) {
          if (docs.data().read != undefined) {
            if (docs.data().read == false) {
              if (global.isChat) {
                const docRef = doc(
                  firestore,
                  'messages',
                  chatId,
                  'messages',
                  docs?.id
                );
                const readData = {
                  read: true,
                };
                updateDoc(docRef, readData)
                  .then((doRef) => {})
                  .catch((err) => {
                    console.log('err', err);
                  });
              }
            } else {
            }
          } else {
          }
        } else {
        }
      });
    } catch (err) {
      console.log('====================================');
      console.log(err);
      console.log('====================================');
    }
  };

  const renderItem = ({ item, index }) => {
    const isReceiverView =
      index == 0
        ? item?.senderId == receiverData?.id
          ? true
          : false
        : messages[index - 1]?.senderId == receiverData?.id
        ? false
        : item?.senderId == receiverData?.id;
    const uId = userData?.data?.user_id || userData?.data?._id;
    const lastSince = moment(item.sentDate.toDate()).format('HH:mm');
    var a = moment(item.sentDate.toDate(), 'YYYY-MM-DD');
    var b = moment(
      messages[index == 0 ? 0 : index - 1]?.sentDate.toDate(),
      'YYYY-MM-DD'
    );
    const isDateView =
      index == 0
        ? true
        : compareTwoDate({
            startDate: item.sentDate.toDate(),
            endDate: messages[index == 0 ? 0 : index - 1]?.sentDate.toDate(),
          })
        ? false
        : true;
    return (
      <View>
        {isDateView ? (
          <View style={style.cureentDateViewStyle}>
            <Text style={style.cureentDateStyle}>
              {compareTodayDate(item.sentDate.toDate())}
            </Text>
          </View>
        ) : (
          <View />
        )}
        {isReceiverView ? (
          <ReceiverUserProfile
            window={window}
            data={receiverData}
            onUserProfile={() => {}}
          />
        ) : (
          <View />
        )}

        <ChatRow
          userId={userData?.data?.user_id || userData?.data?._id}
          index={index}
          data={item}
          window={window}
          readIndex={readLastIndex}
          receiverData={receiverData}
          viewDocumentFile={(data) => {
            Linking.openURL(data.data);
          }}
          lastSince={lastSince}
        />
      </View>
    );
  };

  const listEmptyComponent = () => {
    return (
      <View style={style.emptyView}>
        <NoDataFoundView warning_message={'No data'} />
      </View>
    );
  };

  const sendMessage = async () => {
    inputRef?.current?.blur();
    Keyboard.dismiss();
    setShowEmojiPicker(false);
    if (cureentMessage != '') {
      setMessage('');
      let data = {
        chatId: chatId,
        senderId: userData?.data?.user_id || userData?.data?._id,
        receiverId: receiverId,
        message: cureentMessage.trim(),
        maessageType: 'text',
        read: false,
      };

      const messageData = await setFirebaseUserChat({ data: data });
      setCureentMessage('');
      Keyboard.dismiss();

      updateLastMessageSender();
      updateLastMessageReceiver();
      Emitter.emit('ChatRef');
    }
  };
  const updateLastMessageSender = async () => {
    const lastMesSender = await setFirebaseUserChatLastMessage({
      userId: userData?.data?.user_id || userData?.data?._id,
      chatId: chatId,
      data: {
        message: message.trim(),
        maessageType: 'text',
        dateTime: new Date(),
      },
    });
  };

  const updateLastMessageReceiver = async () => {
    try {
      const otherUserExist = await getFirebaseChatOrderByUserList({
        id: receiverId,
        userId: userData?.data?.user_id || userData?.data?._id,
      });
      if (otherUserExist.length == 0) {
        var cureentUserData = {
          id: userData?.data?.user_id || userData?.data?._id,
          name: userData?.data?.name,
          role: userData?.data?.role,
          image: userData?.data?.image || '',
          status: '',
          chatId: chatId,
          message: message.trim(),
          maessageType: 'text',
          dateTime: new Date(),
        };
        var setOtherUserList = await setFirebaseChatList({
          id: receiverId,
          userData: cureentUserData,
          chatUserId: userData?.data?.user_id || userData?.data?._id,
        });
      } else {
        const lastMesReceiver = await setFirebaseUserChatLastMessage({
          userId: receiverId,
          chatId: chatId,
          data: {
            message: message.trim(),
            maessageType: 'text',
            dateTime: new Date(),
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'image/jpeg': ['.jpeg'],
      'image/png': ['.png'],
      'text/pdf': ['.pdf'],
    },
    onDrop: async (acceptedFiles) => {
      try {
        let response = await acceptedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        });
        var getImageURI = await imageUplaod({
          file: response[0],
          token: userData?.access_token,
        });
        let data = {
          chatId: chatId,
          senderId: userData?.data?.user_id || userData?.data?._id,
          receiverId: receiverId,
          message: getImageURI.data[0],
          maessageType: response[0].type.split('/')[0],
          messageName: response[0].name,
          read: receiverData?.status == 'online' ? true : false,
        };
        setShowEmojiPicker(false);
        dispatch(setLoading(false));
        const messageData = await setFirebaseUserChat({ data: data });
        const lastMesSender = await setFirebaseUserChatLastMessage({
          userId: userData?.data?.user_id || userData?.data?._id,
          chatId: chatId,
          data: {
            message: getImageURI.data[0],
            maessageType: response[0].type.split('/')[0],
            dateTime: new Date(),
          },
        });
        const lastMesReceiver = await setFirebaseUserChatLastMessage({
          userId: receiverId,
          chatId: chatId,
          data: {
            message: getImageURI.data[0],
            maessageType: response[0].type.split('/')[0],
            dateTime: new Date(),
          },
        });

        dispatch(setLoading(false));
      } catch (err) {
        dispatch(setLoading(false));
      }
    },
  });

  //   const heightAnimatedStyle = useAnimatedStyle(() => {
  //     return {
  //       height: height.value,
  //     };
  //   });

  const onChangeEmoji = (res) => {
    var text = `${message}${res}`;
    setMessage(text);
    setCureentMessage(text);
  };
  return (
    <View
      style={[
        {
          flex: 1,
          backgroundColor: Colors.White,
          width: width,
          paddingHorizontal: width * 0.1,
        },
      ]}
    >
      <CustomHeaderChat
        constainerStyle={{ paddingHorizontal: height * 0.02 }}
        leftButtons={
          <TouchableOpacity
            onPress={() => {
              setShowEmojiPicker(false);
              dispatch(isChatDetails(false));
              global.isChat = false;
              navigation.goBack();
            }}
          >
            <Image
              resizeMode="contain"
              style={{ height: combinSize * 0.2, width: combinSize * 0.2 }}
              source={backIcon}
            />
          </TouchableOpacity>
        }
        rightButtons={
          receiverData?.role !== Strings.PROFESSIONAL ? (
            <></>
          ) : type !== Strings.RECRUITER ? (
            <></>
          ) : (
            <TouchableOpacity
              onPress={() => {
                navigation.navigate(RouteName.INTERVIEW_DATETIME, {
                  receiverId: receiverId,
                });
              }}
            >
              <Image
                resizeMode="contain"
                style={{ height: combinSize * 0.2, width: combinSize * 0.2 }}
                source={addIcon}
              />
            </TouchableOpacity>
          )
        }
        title={receiverData?.name || ''}
        size={combinSize * 0.2}
      />

      <View style={{ height: '88%' }}>
        <FlatList
          showsVerticalScrollIndicator={false}
          data={messages || []}
          renderItem={renderItem}
          automaticallyAdjustContentInsets={false}
          ListEmptyComponent={listEmptyComponent}
          keyExtractor={(item, index) => index.toString()}
          ref={listRef}
          scrollsToTop={false}
          onContentSizeChange={() => {
            if (listRef && listRef.current) {
              listRef.current.scrollToEnd({ animated: false });
            }
          }}
          onLayout={() => {
            if (listRef && listRef.current) {
              listRef.current.scrollToEnd({ animated: false });
            }
          }}
        />
        <View style={{ padding: 15 }} />
        <View
          style={[
            {
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: Colors.White,
            },
            showEmojiPicker ? {} : { height: height * 0.08 },
          ]}
        >
          <View
            style={[
              {
                borderColor: '#898A8D',
                width: width * 0.75,
                marginLeft: width * 0.025,
                marginRight: width * 0.025,
                borderRadius: 10,
                borderWidth: 1,
                paddingVertical: 0,
                alignItems: 'center',
                flexDirection: 'row',

                alignSelf: 'center',
                backgroundColor: Colors.White,
              },
            ]}
          >
            <View
              style={[
                {
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: width * 0.57,
                },
              ]}
            >
              <TextInput
                ref={inputRef}
                multiline={true}
                style={[
                  style.msgTxtInptWeb,
                  isNative ? {} : { outlineColor: 'white' },
                ]}
                value={message}
                onChangeText={(text) => {
                  setMessage(text);
                  setCureentMessage(text);
                }}
                onFocus={() => {
                  setShowEmojiPicker(false);
                }}
                placeholder={'Type a message.....'}
                placeholderTextColor={'#898A8D'}
                underlineColorAndroid="transparent"
              />
            </View>
            <View
              style={{
                width: width * 0.18,
                paddingVertical: 5,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                {...getRootProps({
                  className: 'dropzone',
                  onClick: (event) => {},
                })}
              >
                <TouchableOpacity
                  disabled
                  style={{
                    width: width * 0.04,
                    height: width * 0.03,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Image
                    style={{
                      height: window.width * 0.015,
                      width: window.width * 0.015,
                      resizeMode: 'contain',
                    }}
                    source={AttchIcon}
                  />
                </TouchableOpacity>
              </div>
              <TouchableOpacity
                onPress={() => {
                  Keyboard.dismiss();
                  setShowEmojiPicker(!showEmojiPicker);
                }}
                style={{
                  width: width * 0.04,
                  height: width * 0.03,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Image
                  style={{
                    height: window.width * 0.015,
                    width: window.width * 0.015,
                    resizeMode: 'contain',
                  }}
                  source={EmojiIcon}
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => sendMessage()}
                style={{
                  width: width * 0.05,
                  height: width * 0.03,
                  marginRight: width * 0.025,
                  marginLeft: width * 0.01,
                  borderRadius: combinSize * 0.1,
                  backgroundColor: Colors.Blueberry,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Image
                  style={{
                    height: window.width * 0.015,
                    width: window.width * 0.015,
                    resizeMode: 'contain',
                  }}
                  source={SendIcon}
                />
              </TouchableOpacity>
            </View>
          </View>
          {showEmojiPicker && (
            <EmojiPicker
              isOpen={showEmojiPicker}
              selectedEmoji={onChangeEmoji}
            />
          )}
        </View>
      </View>
    </View>
  );
};

export default ChatWeb;
