import { View, Text, TouchableOpacity, Image } from 'react-native'
import React from 'react'
import { CONNECTION_ICON, STAR_ICON, dummyImage } from '../../Resources/assets'
import { theme } from '../../Util/constant'
import Colors from '../../Resources/Colors'
import EndPoint from '../../Redux/constants/EndPoint'
import { CustomText } from '../../Component/CustomComponent'
import { fontResize } from '../../Util/font'



const CustomConnectComponent = ({item, index, window, otherPress, fetchOtherUserProfile, onViewImage, isLastIndex, viewMoreFollowers}) => {
  const { width, height } = window;
  const combinSize =
    width > height
      ? width * 0.05 + height * 0.1
      : width == height
      ? width + height
      : height * 0.05 + width * 0.1;
  return (
    <View>
    <View
        style={{
          marginStart: window.width * 0.025,
          marginVertical: '1%',
          flexDirection: 'row',
          padding: '1%',
          borderBottomWidth: 1,
          borderBottomColor: Colors.Gray,
          width: window.width * 0.3,
          justifyContent: 'space-between',
          alignItems: 'center',
          shadowColor: Colors.Gray,
          borderRadius: combinSize * 0.04,
          shadowOffset: { width: combinSize * 0.01, height: combinSize * 0.01 },
          shadowOpacity: combinSize * 0.03,
          shadowRadius: combinSize * 0.03,
        }}
      >
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            padding: '2%',
            width: '75%',
          }}
          onPress={() => {
            otherPress()
          }}
        >
        <TouchableOpacity disabled={item?.image ? false : true} onPress={()=>{onViewImage({data: [EndPoint.baseAssestURL + item?.image], index: 0})}}>
          <Image
            style={{
              height: combinSize * 0.5,
              width: combinSize * 0.5,
              borderRadius: combinSize * 0.5,
              borderWidth: combinSize * 0.08,
              borderColor: Colors.White,
            }}
            source={
              item?.image
                ? {
                    uri: EndPoint.baseAssestURL + item?.image,
                  }
                : dummyImage
            }
          />
          </TouchableOpacity>

          <View
            style={{
              marginHorizontal: '4%',
              justifyContent: 'space-evenly',
              width: '80%',
            }}
          >
            <CustomText
              fontSize={`${combinSize * 0.12}px`}
              style={{ color: Colors.Black }}
              {...theme.fontBold}
            >
              {item?.name || ''}
              {item?.gold_member ? (
                <Image source={STAR_ICON} style={{ height: combinSize * 0.12, width: combinSize * 0.12 }} />
              ) : (
                ''
              )}
            </CustomText>

            <CustomText
              fontSize={`${combinSize * 0.11}px`}
              style={{ color: Colors.Black }}
              {...theme.fontMedium}
            >
              {item?.skill ? item?.skill : item?.role}
            </CustomText>
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => {
            fetchOtherUserProfile(item?._id);
          }}
          style={{
            width: '15%',
            justifyContent: 'center',
            alignItems: 'flex-end',
            paddingEnd: combinSize * 0.13,
          }}
        >
          <Image
            resizeMode="contain"
            style={{ height: combinSize * 0.18, width: combinSize * 0.18 }}
            source={CONNECTION_ICON}
          />
        </TouchableOpacity>
      </View>
      {isLastIndex &&  <Text onPress={()=>{
        viewMoreFollowers()
      }} style={{
          fontSize:  fontResize(combinSize * 0.1),
          ...theme.fontBold,
          color: Colors.Blueberry,
          textDecorationLine: 'underline',
          textAlign: 'right',
          marginTop: combinSize * 0.05,
        }}>See More</Text>}
      </View>
  )
}

export default CustomConnectComponent