import { TouchableOpacity, Image } from 'react-native';
import React from 'react';

const CustomCrossButton = ({
  buttonStyle,
  onPress,
  imageSrc,
  imageStyle,
}) => {
  return (
    <TouchableOpacity
      style={buttonStyle}
      onPress={() => {
        onPress();
      }}
    >
      <Image source={imageSrc} style={imageStyle} />
    </TouchableOpacity>
  );
};

export default CustomCrossButton;
