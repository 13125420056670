import {
  View,
  Text,
  Dimensions,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import React from 'react';
import Colors from '../../../Resources/Colors';
import { theme } from '../../../Util/constant';
import Emitter from '../../../Util/eventEmitter';
import Type from '../../../Constants/Type/type';
import Events from '../../Events';
import Feed from '../../../Component/Feed';

const PostViewComponent = ({
  type,
  jobs,
  index,
  viewApplyJob,
  events,
  navigation,
  userData,
  onLike,
  onComment,
  hidePost,
  onSubmitComment,
  setUsername,
  setReportSuccess,
  fetchAllData,
  userInfo, 
  setItem,
  fetchOtherUserProfile,
  setProfileModal,
  window,
  inViewPort,
  onViewImage,
  isPlayVideo,
  setIsPlayVideo,
  postAllData,
  totalPostCount,
  pageNo,
  setPageNo,
  item
}) => {
  const {width, height} = window;
  const combinSize = width > height ?  (width * 0.05) + (height * 0.1) : width  == height ? width + height  :   (height * 0.05) + (width * 0.1)
  return (
    <View>
      {type == 'Professional' &&
        index === 6 &&
        jobs?.data?.Featured_Jobs != undefined &&
        jobs?.data?.Featured_Jobs.length !== 0 && (
          <View
            style={{
              flexDirection: 'column',
              paddingVertical: 30,
              paddingHorizontal: 12,
              backgroundColor: Colors.White,
              marginTop: index === 3 ? 22 : 0,
              marginBottom: index === 8 ? 22 : 0,
              borderTopLeftRadius: index === 3 ? 22 : 0,
              borderTopRightRadius: index === 3 ? 22 : 0,
              borderBottomLeftRadius: index === 8 ? 22 : 0,
              borderBottomRightRadius: index === 8 ? 22 : 0,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: Dimensions.get('screen').height * 0.02,
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                  color: Colors.Black,
                  ...theme.fontBold,
                  alignItems: 'flex-start',
                }}
              >
                Featured Jobs
              </Text>

              <TouchableOpacity
                onPress={() => {
                  Emitter.emit(Type.EMITTER.JOBWEB);
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    color: Colors.PrimaryGray,
                    ...theme.fontMedium,
                    alignItems: 'flex-end',
                    textDecorationLine: 'underline',
                  }}
                >
                  See all
                </Text>
              </TouchableOpacity>
            </View>

            <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
              <JobWeb
                jobs={jobs}
                type={type}
                onjobPress={(data) => {
                  viewApplyJob(data);
                }}
                window={window}
                isView={false}
                viewMoreFollowers={()=>{}}
              />
            </ScrollView>

            {events?.length && events?.length > 0 && (
              <View>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: Dimensions.get('screen').height * 0.02,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 16,
                      color: Colors.Black,
                      ...theme.fontBold,
                      alignItems: 'flex-start',
                    }}
                  >
                    Featured Events
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      Emitter.emit(Type.EMITTER.JOBWEB);
                      setTimeout(() => {
                        Emitter.emit(Type.EMITTER.SEE_EVENT);
                      }, 200);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        color: Colors.PrimaryGray,
                        ...theme.fontMedium,
                        alignItems: 'flex-end',
                        textDecorationLine: 'underline',
                      }}
                    >
                      See all
                    </Text>
                  </TouchableOpacity>
                </View>
                <ScrollView
                  bounces={false}
                  showsVerticalScrollIndicator={false}
                >
                  <Events
                    selectionType={Type.EVENT_TYPE.SEE_ALL}
                    navigation={navigation}
                    events={events}
                    type={type}
                    isView={false}
                  />
                </ScrollView>
              </View>
            )}
          </View>
        )}
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingVertical: 30,
          paddingHorizontal: 12,
          backgroundColor: Colors.White,
          marginTop: index === 3 ? 22 : 0,
          marginBottom: index === 8 ? 22 : 0,
          borderTopLeftRadius: index === 3 ? 22 : 0,
          borderTopRightRadius: index === 3 ? 22 : 0,
          // borderBottomLeftRadius: index === 8 ? 22 : 0,
          // borderBottomRightRadius: index === 8 ? 22 : 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Feed
          index={index}
          userId={userData?.data?._id || '123456'}
          item={item}
          onLike={(id) => onLike(id)}
          onComment={(comment, postId) => onComment(comment, postId)}
          onHide={(type, userId, postId) => hidePost(type, userId, postId)}
          onSubmitComment={({ postId, textDescription, tagUser }) =>
            onSubmitComment({ postId, textDescription, tagUser })
          }
          setUpdate={({ type, name }) => {
            setUsername(name);
            if (!type) {
              setReportSuccess(true);
            }
            fetchAllData(1);
          }}
          userInfo={userInfo}
          navigation={navigation}
          onUserProfile={(id) => {
            setItem({ _id: id });
            fetchOtherUserProfile({ _id: id });
            setProfileModal(true);
          }}
          window={window}
          inViewPort={inViewPort}
          onViewImage={onViewImage}
          isPlayVideo={isPlayVideo}
          pressMediaIcon={(mediadata) => {
            setIsPlayVideo(mediadata);
          }}
          isSeeMore={
            postAllData.length === totalPostCount - 3
              ? false
              : postAllData.length > 12
              ? postAllData.length === index + 1
              : false
          }
          seePress={() => {
            fetchAllData(pageNo + 1);
            setPageNo((pervious) => pervious + 1);
          }}
        />
      </View>
    </View>
  );
};

export default PostViewComponent;
