import { View, Text, TouchableOpacity, Image } from 'react-native'
import React from 'react'
import Colors from '../../Resources/Colors'
import { CustomText } from '../../Component/CustomComponent'
import EndPoint from '../../Redux/constants/EndPoint'
import { theme } from '../../Util/constant'
import { STAR_ICON, dummyImage } from '../../Resources/assets'
import { fontResize } from '../../Util/font'


const FollowersComponent = ({item, index, window, followUser, otherPress, isLastIndex, viewMoreFollowers, onViewImage}) => {
  const { width, height } = window;
  const combinSize =
    width > height
      ? width * 0.05 + height * 0.1
      : width == height
      ? width + height
      : height * 0.05 + width * 0.1;
  return (
    <View>
    <View
        style={{
          marginStart: window.width * 0.025,
          marginVertical: '1%',
          flexDirection: 'row',
          padding: '1%',
          borderBottomWidth: 1,
          borderBottomColor: Colors.Gray,
          width: window.width * 0.3,
          justifyContent: 'space-between',
          alignItems: 'center',
          shadowColor: Colors.Gray,
          borderRadius: 8,
          shadowOffset: { width: 3, height: 3 },
          shadowOpacity: 6,
          shadowRadius: 6,
        }}
      >
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            padding: '2%',
            width: '75%',
          }}
          onPress={() => {
            otherPress();
          }}
        >
        <TouchableOpacity disabled={item?.image ? false : true} onPress={()=>{onViewImage({data: [EndPoint.baseAssestURL + item?.image], index: 0})}}>
          <Image
            style={{
              height: combinSize * 0.5,
              width: combinSize * 0.5,
              borderRadius: combinSize * 0.5,
              borderWidth: combinSize * 0.08,
              borderColor: Colors.White,
            }}
            source={
              item?.image
                ? {
                    uri: EndPoint.baseAssestURL + item?.image,
                  }
                : dummyImage
            }
          />
          </TouchableOpacity>

          <View
            style={{
              marginHorizontal: '4%',
              justifyContent: 'space-evenly',
              width: '53%',
            }}
          >
            <CustomText
              fontSize={`${combinSize * 0.12}px`}
              style={{ color: Colors.Black }}
              {...theme.fontBold}
            >
              {item?.name}
              {item?.gold_member ? (
                <Image source={STAR_ICON} style={{ height: combinSize * 0.12, width: combinSize * 0.12 }} />
              ) : (
                ''
              )}
            </CustomText>

            <View style={{ flexDirection: 'row' }}>
              <CustomText
                fontSize={`${combinSize * 0.11}px`}
                style={{ color: Colors.Black }}
                {...theme.fontMedium}
              >
                {item?.skill ? item?.skill : item?.role}
              </CustomText>
            </View>
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          disabled={item?.followed_by_me ? true : false}
          onPress={() => {
            followUser(item?._id, index);
          }}
          style={{
            width: '25%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CustomText
            fontSize={`${combinSize * 0.11}px`}
            style={{
              color: item?.followed_by_me
                ? Colors.lightGray
                : Colors.GreenColor,
            }}
            {...theme.fontBold}
          >
            {
              //  getFollowIndex(index)? "Following":
              item?.followed_by_me ? 'Following' : 'Follow'
            }
          </CustomText>
        </TouchableOpacity>
      </View>
      {isLastIndex &&  <Text onPress={()=>{
        viewMoreFollowers()
      }} style={{
          fontSize:  fontResize(combinSize * 0.1),
          ...theme.fontBold,
          color: Colors.Blueberry,
          textDecorationLine: 'underline',
          textAlign: 'right',
          marginTop: combinSize * 0.05,
        }}>See More</Text>}
      </View>
  )
}

export default FollowersComponent