import { View, Text, TouchableOpacity, Image } from 'react-native'
import React from 'react'
import Colors from '../../../Resources/Colors'
import { fontResize } from '../../../Util/font'
import { theme } from '../../../Util/constant'
import Strings from '../../../Resources/Strings'
import EndPoint from '../../../Redux/constants/EndPoint'
import { CustomText } from '../../../Component/CustomComponent'
import { STAR_ICON, dummyImage } from '../../../Resources/assets'

const CustomLikeUserView = ({item, window, combinSize, otherPress, onViewImage, viewMoreLike, isLastIndex}) => {
  const userImage = item?.userDetails?.image
  ? typeof item?.userDetails?.image == 'string'
    ? EndPoint.baseAssestURL + item?.userDetails?.image
      
    :
          EndPoint.baseAssestURL + item?.userDetails?.image[0]
      
  : item?.image
  ? typeof item?.image == 'string' ?
    EndPoint.baseAssestURL + item?.image
      
    :EndPoint.baseAssestURL + item?.image[0]
  : ''
  return (
    <View>
        
    <View
        style={{
          marginStart: window.width * 0.025,
          marginVertical: '1%',
          flexDirection: 'row',
          padding: '1%',
          borderBottomWidth: 1,
          borderBottomColor: Colors.Gray,
          width: window.width * 0.3,
          justifyContent: 'space-between',
          alignItems: 'center',
          shadowColor: Colors.Gray,
          borderRadius: 8,
          shadowOffset: { width: 3, height: 3 },
          shadowOpacity: 6,
          shadowRadius: 6,
        }}
      >
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            padding: '2%',
            width: '75%',
          }}
          onPress={() => {
            otherPress()
            
          }}
        >
          <TouchableOpacity
            disabled={userImage !== '' ? false : true} onPress={()=>{onViewImage({data: [userImage], index: 0})}}
          >
            <Image
              style={{
                height: combinSize * 0.5,
                width: combinSize * 0.5,
                borderRadius: combinSize * 0.5,
                borderWidth: combinSize * 0.08,
                borderColor: Colors.White,
              }}
              source={
                item?.userDetails?.image
                  ? typeof item?.userDetails?.image == 'string'
                    ? {
                        uri: EndPoint.baseAssestURL + item?.userDetails?.image,
                      }
                    : {
                        uri:
                          EndPoint.baseAssestURL + item?.userDetails?.image[0],
                      }
                  : item?.image
                  ? typeof item?.image == 'string'
                    ? {
                        uri: EndPoint.baseAssestURL + item?.image,
                      }
                    : {
                        uri: EndPoint.baseAssestURL + item?.image[0],
                      }
                  : dummyImage
              }
            />
          </TouchableOpacity>

          <View
            style={{
              marginHorizontal: '4%',
              justifyContent: 'space-evenly',
              width: '73%',
            }}
          >
            <CustomText
              fontSize={`${combinSize * 0.12}px`}
              style={{ color: Colors.Black }}
              {...theme.fontBold}
            >
              {item?.userDetails?.name || item?.name}
              {item?.userDetails?.gold_member || item?.gold_member ? (
                <Image
                  source={STAR_ICON}
                  style={{
                    height: combinSize * 0.12,
                    width: combinSize * 0.12,
                  }}
                />
              ) : (
                ''
              )}
            </CustomText>

            <View style={{ flexDirection: 'row' }}>
              <CustomText
                fontSize={`${combinSize * 0.11}px`}
                style={{ color: Colors.Black }}
                {...theme.fontMedium}
              >
                {item?.userDetails?.skill
                  ? item?.userDetails?.skill
                  : item?.skill
                  ? item?.skill
                  : item?.role
                  ? item?.role
                  : item?.userDetails?.role}
              </CustomText>
            </View>
          </View>
        </TouchableOpacity>
        
      </View>
      {isLastIndex &&  <Text onPress={()=>{
        viewMoreLike()
      }} style={{
          fontSize: fontResize(combinSize * 0.1),
          ...theme.fontBold,
          color: Colors.Blueberry,
          textDecorationLine: 'underline',
          textAlign: 'right',
          marginTop: combinSize * 0.05,
        }}>See More</Text>}
      </View>
  )
}

export default CustomLikeUserView