const Colors = {
  Blueberry: '#4D74F9',
  LightBlue: 'rgba(255, 255, 255, 0.22)',
  lightGray: '#898A8D',
  DimGray: '#6D6969',
  Silver: '#C4C4C4',
  White: '#ffffff',
  EerieBlack: '#181B1B',
  Black: '#000000',
  drakGray: '#3E4242',
  background: '#E5E5E5',
  Red: '#FF5C5C',
  PrimaryBlack: '#211F32',
  PrimaryGray: '#979797',
  PrimaryLightBlue: '#DDE5FF',
  PrimaryGray1: '#777E90',
  PrimaryGray2: '#696D6D',
  PrimaryGray3: '#E3E3E3',
  PrimaryGray4: '#979B9B',
  PrimaryLightGray: 'rgba(248, 248, 248, 1)',
  SilverLight: '#C2C5CE',
  Gray: '#D8D8D8',
  ShadowGray: '#BDBDBD',
  SecondaryColor: 'rgba(77, 116, 249, 0.08)',
  LinearColor1: '#9EC555',
  LinearColor2: '#9AC34F',
  LinearColor3: '#4DB1F9',
  UFOGreen: 'rgb(220,248,226)',
  UFOGreen1: '#4CD964',
  lightGray11: '#D9DDE8',
  lightGray12: '#303030',
  chatGreen: '#99C24D',
  LightOrangeColor: '#F6690F',
  gray2: '#DCDCDC',
  eventColor: '#E0E7FF',
  OrangeColor: '#FFA862',
  GreenColor: '#4CD964',
  Crayola: '#FFE089',
  Ochre: '#C87717',
  GoldColor: '#FF8D07',
  Marigold: '#EDA12F',
  chatGreen: '#99C24D',
  GhostWhite: '#F7F9FA',
  SpanishGray: '#9E9E9E',
  EerieBlack1: '#0A1629',
  PumpkinColor: '#F6690F',
  PaleCornflowerBlue: '#ACC5FF',
  Cultured: '#F4F4F4',
  paleYellow: '#FFE382',
  darkYellow: '#D27200',
  ORANGE: 'orange',
  yellow: {
    300: '#F69E00',
    400: '#EE8434',
    500: '#c19f30',
  },
  blue: {
    200: '#1F81BC',
    300: '#455EFF',
  },
  CodGray: '#1E1E1E',
  Brown: '#a78b6e',
  LightPink: '#FFE7E7',
  Transparent: 'transparent',
  lightBlack: 'rgba(0, 0, 0, 0.8)',
};
export default Colors;
