import React, { useEffect, useState } from 'react';
import {
  View,
  StyleSheet,
  FlatList,
  useWindowDimensions,
  ActivityIndicator,
  Modal,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import CustomHeader from '../../Component/CustomHeader';
import Strings from '../../Resources/Strings';
import { CustomText } from '../../Component/CustomComponent';
import { theme } from '../../Util/constant';
import Colors from '../../Resources/Colors';
import {
  fetchFollowListOther,
  fetchOtherProfile,
  followUserAction,
} from '../../Redux/actions/profileAction';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../Redux/actions/authAction';
import { RouteName } from '../../Navigation/routeName';
import Emitter from '../../Util/eventEmitter';
import Type from '../../Constants/Type/type';
import FollowersComponent from './FollowersComponent.web';
import ProfileDetail from '../../Component/ProfileDetail/index.web';
import CustomImageVideoDownload from '../../Component/CustomImageVideoDownload';
import { getFirebaseChatOrderByUserList, getFirebaseUserData, getNewFirebaseChatListUserData, setFirebaseChatList, setFirebaseUserData } from '../../Constants/FireStoremanager/FireStoremanager.web';

// create a component
const Followers = ({ navigation }) => {
  const [page, setPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const [followerData, setFollowerData] = useState([]);
  const [newPageLoading, setNewPageLoading] = useState(true);
  const window = useWindowDimensions();
  const checkTypeOf = typeof followerData;
  const [activeTab, setActiveTab] = useState('');
  const userData = useSelector((state) => state.auth.userData);
  const type = userData?.data?.role
    ? userData?.data?.role
    : Strings.PROFESSIONAL;
    const [isProfile, setIsProfile] = useState(false);
    const [profileImage, setProfileImage] = useState('');
    const [profileImageIndex, setProfileImageIndex] = useState(0);
    const [profileItem, setProfileItem] = useState([]);
    const [showProfileModal, setProfileModal] = useState(false);
  const dispatch = useDispatch();
  const { width, height } = window;
  const combinSize =
    width > height
      ? width * 0.05 + height * 0.1
      : width == height
      ? width + height
      : height * 0.05 + width * 0.1;

  useEffect(() => {
    fetchMyFollwers(1);
  }, []);

  const followUser = (userId, index) => {
    try {
      dispatch(setLoading(true));
      const params = {
        follower_id: userId,
      };

      dispatch(
        followUserAction({
          params,
          onSuccess: (result) => {
            dispatch(setLoading(false));
            updateFollowerData({ data: result.data, index });
          },
          onError: (error) => {
            dispatch(setLoading(false));
            console.log('error', error);
          },
        })
      );
    } catch (error) {
      dispatch(setLoading(false));
      console.log('error', error);
    }
  };

  const updateFollowerData = ({ data, index }) => {
    var oldData = followerData;
    oldData[index].followed_by_me = data;
    setFollowerData([...oldData]);
    Emitter.emit(Type.EMITTER.GET_PROFILE);
  };

  const fetchMyFollwers = (data) => {
    try {
      dispatch(setLoading(false));
      let params = `?follow=by_other&page=${data}`;
      dispatch(
        fetchFollowListOther({
          params,
          onSuccess: async (result) => {
            if(data == 1){
              setFollowerData((perviousData) => [
                ...result.data,
              ]);
            }
            else{
              setFollowerData((perviousData) => [
                ...perviousData,
                ...result.data,
              ]);
            }
            
            setPageLoading(false);
            setNewPageLoading(result?.data?.length == 10 ? true : false);
            dispatch(setLoading(false));
          },
          onError: (error) => {
            dispatch(setLoading(false));
            console.log('error', error);
          },
        })
      );
    } catch (error) {
      dispatch(setLoading(false));
      console.log(error);
    }
  };

  const fetchOtherUserProfile = ({ userId, isChat }) => {
    dispatch(
      fetchOtherProfile({
        userId,
        onSuccess: (result) => {
          if (isChat) {
            createChat(result.data);
          } else {
            setProfileModal(true);
          }
        },
        onError: (error) => {},
      })
    );
  };


  const renderItem = ({ item, index }) => {
    var isLastIndex =
    followerData.length == index + 1
        ? followerData.length % 10 == 0
          ? true
          : false
        : false;
    return (
     <FollowersComponent onViewImage={onViewImage} isLastIndex={isLastIndex} item={item} index={index} window={window} followUser={followUser} otherPress={()=>{
      fetchOtherUserProfile({ userId: item?.user_id, isChat: false });
          setProfileItem({ _id: item?.user_id });
     }}
     viewMoreFollowers={viewMoreFollowers}
     />
    );
  };


  const viewMoreFollowers = () => {
    setPage(page + 1);
        fetchMyFollwers(page + 1);
  };


  const onViewImage = (data) => {
    setIsProfile(true);
    setProfileImage(data?.data);
    setProfileImageIndex(data?.index);
  };


  const createChat = async (otherUserProfile) => {
    const otherUserId = otherUserProfile?._id;
    const userExist = await getFirebaseChatOrderByUserList({
      id: userData?.data?.user_id || userData?.data?._id,
      userId: otherUserId,
    });
    const newID = userData?.data?.user_id || userData?.data?._id;
    var chatId =
      newID > otherUserId ? `${newID}${otherUserId}` : `${otherUserId}${newID}`;
      
    var getUserChatLists = await getNewFirebaseChatListUserData({
      id: userData?.data?.user_id || userData?.data?._id,
      chatId: chatId,
    });
    var getOtherUserChatLists = await getNewFirebaseChatListUserData({
      id: otherUserId,
      chatId: chatId,
    });
    if (userExist.length == 0) {
      const usergetExist = await getFirebaseUserData({
        id: otherUserId,
      });
      if (!usergetExist) {
        const userStoreData = {
          id: otherUserId,
          email: otherUserProfile?.email || '',
          device_token: otherUserProfile?.device_token || '',
          image:
            otherUserProfile?.image || otherUserProfile?.userinfo?.image || '',
          name: otherUserProfile?.name || '',
          role: otherUserProfile?.role || '',
          phone_number: otherUserProfile?.phone_number || '',
          status: 'offline',
          dateTime: new Date(),
        };
        const storeFirbaseData = await setFirebaseUserData({
          id: otherUserId,
          userData: userStoreData,
        });
      }
      var otherUserData = {
        id: otherUserId,
        name: otherUserProfile?.name || '',
        role: otherUserProfile?.role || '',
        image:
          otherUserProfile?.image || otherUserProfile?.userinfo?.image || '',
        status: '',
        chatId: chatId,
        message: '',
        maessageType: 'text',
        dateTime: new Date(),
      };
      var cureentUserData = {
        id: userData?.data?.user_id || userData?.data?._id,
        name: userData?.data?.name || '',
        role: userData?.data?.role || '',
        image: userData?.data?.image || '',
        status: '',
        chatId: chatId,
        message: '',
        maessageType: 'text',
        dateTime: new Date(),
      };
      if (!getUserChatLists) {
        await setFirebaseChatList({
          id: userData?.data?.user_id || userData?.data?._id,
          userData: otherUserData,
          chatUserId: otherUserId,
        });
      }
      if (!getOtherUserChatLists) {
        await setFirebaseChatList({
          id: otherUserId,
          userData: cureentUserData,
        });
      }
      navigationChat({
        chatId: chatId,
        receiverId: otherUserId,
        isOther: true,
        otherUserProfile,
      });
    } else {
      navigationChat({
        chatId: chatId,
        receiverId: otherUserId,
        isOther: true,
        otherUserProfile,
      });
    }
  };

  const navigationChat = ({
    chatId,
    receiverId,
    isOther,
    otherUserProfile,
  }) => {
    global.isChat = true;
    navigation.navigate(RouteName.CHAT_USER, {
      chatId: chatId,
      receiverId: receiverId,
      isOther: isOther,
      receiverData: {
        id: receiverId,
        name: otherUserProfile?.name || '',
        role: otherUserProfile?.role || '',
        image:
          otherUserProfile?.image || otherUserProfile?.userinfo?.image || '',
        status: '',
        chatId: chatId,
        message: '',
        maessageType: 'text',
        dateTime: new Date(),
      },
    });
  };

  return (
    <SafeAreaView style={styles.container}>
      <CustomHeader
        window={window}
        activeTab={activeTab}
        isProfile={true}
        backPress={() => navigation.goBack()}
        title={Strings.FOLLOWERS}
      />

      <CustomText
        fontSize={`${combinSize * 0.12}`}
        style={{
          color: 'gray',
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderColor: Colors.gray2,
          paddingHorizontal: window.width * 0.03,
          paddingVertical: window.width * 0.01,
          marginBottom: window.width * 0.01,
          ...theme.fontMedium
        }}
      >
        {`${
          checkTypeOf == 'string' ? 0 : followerData.length
        } people following you`}
      </CustomText>
      <FlatList
        contentContainerStyle={{
          justifyContent: 'space-between',
          width: window.width,
        }}
        bounces={false}
        data={checkTypeOf == 'string' ? [] : followerData}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        numColumns={3}
      />
      <ProfileDetail
        item={profileItem}
        showProfileModal={showProfileModal}
        setProfileModal={setProfileModal}
        suggestionPress={() => {
        }}
        chatPress={(chatData) => {
          setProfileModal(false);
          createChat(chatData);
        }}
        onViewImage={onViewImage}
      />
      

{isProfile && (
        <CustomImageVideoDownload
          isVisible={isProfile}
          setVisible={setIsProfile}
          selectedFile={profileImage}
          index={profileImageIndex}
        />
      )}

      {pageLoading && (
        <Modal
          animated={true}
          animationType={'fade'}
          transparent={true}
          visible={pageLoading}
        >
          <View
            style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
          >
            <ActivityIndicator size="large" color={Colors.Blueberry} />
          </View>
        </Modal>
      )}
    </SafeAreaView>
  );
};

// define your styles
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.White,
  },
});

//make this component available to the app
export default Followers;
