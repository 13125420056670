import {StyleSheet} from 'react-native';

export const style = StyleSheet.create({
  constainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonStyleImage: {
    width: 25,
    height: 25,
  },
});
