import { View, ScrollView } from 'react-native-web';
import React, { useEffect, useState } from 'react';
import Profile from '../Profile';
import Chat from '../Chat';
import Calender from '../Calender';
import JobEventWeb from '../JobEventWeb';
import { style } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../Redux/actions/authAction';
import {
  fetchEvents,
  fetchJobs,
  setEventsData,
  setJobData,
} from '../../Redux/actions/jobAndEventAction';
import JobChat from '../Chat/JobChat';
import { useWindowDimensions } from 'react-native';

const JobEventMain = () => {
  const dispatch = useDispatch();
  const window = useWindowDimensions();
  const jobs = useSelector((state) => state.jobAndEvent.jobs);
  const events = useSelector((state) => state.jobAndEvent.events);
  const [jobPage, setJobPage] = useState(1);
  const [eventPage, setEventPage] = useState(1);

  useEffect(() => {
    fetchAllJobs(1);
    setJobPage(1);
    fetchAllEvents(1);
    setEventPage(1);
  }, []);

  const fetchAllJobs = (data) => {
    try {
      dispatch(setLoading(true));
      dispatch(
        fetchJobs({
          data,
          onSuccess: (result) => {
            if (result?.data) {
              if (data == 1) {
                dispatch(setJobData(result));
                dispatch(setLoading(false));
              } else {
                var featureJob = jobs?.Featured_Jobs || [];
                var recommendedJob = jobs?.Recommended_Jobs || [];
                var newFeatureJob = result?.data?.Featured_Jobs || [];
                var newRecommendedJob = result?.data?.Recommended_Jobs || [];
                var oldData = {
                  Featured_Jobs: [...featureJob, ...newFeatureJob],
                  Recommended_Jobs: [...recommendedJob, ...newRecommendedJob],
                };
                dispatch(setJobData({ data: oldData }));
                dispatch(setLoading(false));
              }
            }

            dispatch(setLoading(false));
          },
          onError: (error) => {
            dispatch(setLoading(false));
          },
        })
      );
    } catch (err) {
      dispatch(setLoading(false));
    }
  };

  const fetchAllEvents = (data) => {
    dispatch(
      fetchEvents({
        data,
        onSuccess: (result) => {
          if (result.data) {
            if (data == 1) {
              dispatch(setEventsData(result));
            } else {
              dispatch(setEventsData({ data: [...events, ...result.data] }));
            }
          }
          dispatch(setLoading(false));
        },
        onError: (error) => {
          dispatch(setLoading(false));
        },
      })
    );
  };
  const viewMoreJob = () => {
    setJobPage((previous) => previous + 1);
    fetchAllJobs(jobPage + 1);
  };

  const viewMoreEvents = () => {
    setEventPage((previous) => previous + 1);
    fetchAllEvents(eventPage + 1);
  };

  return (
    <View style={style.container}>
      <View style={style.subContainer}>
        {/* // Render Profile */}

        <View style={style.profileContainer}>
          <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
            <Profile />
          </ScrollView>
        </View>
        {/* Render Feeds */}
        <View style={style.jobContainer}>
          <JobEventWeb
            window={window}
            viewMoreJob={() => viewMoreJob()}
            viewMoreEvents={() => {
              viewMoreEvents;
            }}
          />
        </View>
        {/* render Chat and Calender */}
        <View style={style.profileContainer}>
          <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
            <Calender />
            {/* <JobChat /> */}
            <Chat />
          </ScrollView>
        </View>
      </View>
    </View>
  );
};

export default JobEventMain;
